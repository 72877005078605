"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectedItemsContent = exports.SelectedItem = exports.MultiSelectDropdownItem = exports.MultiSelectDropdown = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var MultiSelectDropdown = exports.MultiSelectDropdown = _styledComponents["default"].ul(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  width: 100%;\n  border: 1px solid #ccc;\n  border-radius: 5px\n  max-height: 250px;\n  overflow-y: auto;\n  background-color: #fff;\n  z-index: 1000;\n"])));
var MultiSelectDropdownItem = exports.MultiSelectDropdownItem = _styledComponents["default"].li(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  padding: 8px;\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f0f0f0;\n  }\n"])));
var SelectedItemsContent = exports.SelectedItemsContent = _styledComponents["default"].span(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  gap: 6px;\n  overflow-x: auto;\n  padding-bottom: 4px;\n"])));
var SelectedItem = exports.SelectedItem = _styledComponents["default"].span(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  padding: 4px 8px;\n  border-radius: 10px;\n  white-space: nowrap;\n  background-color: ", ";\n"])), function (p) {
  return "".concat(p.background);
});