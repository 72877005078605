"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilePreviewContainer = exports.FileName = exports.DropzoneContainer = exports.DropzoneColumn = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Text = _interopRequireDefault(require("../Text/Text"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var DropzoneColumn = exports.DropzoneColumn = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n"])));
var DropzoneContainer = exports.DropzoneContainer = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  padding: 16px;\n  width: ", ";\n  border: 1px dashed rgba(112, 112, 112, 0.3);\n  border-radius: 10px;\n"])), function (p) {
  var _p$width;
  return (_p$width = p === null || p === void 0 ? void 0 : p.width) !== null && _p$width !== void 0 ? _p$width : '330px';
});
var FileName = exports.FileName = (0, _styledComponents["default"])(_Text["default"])(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])));
var FilePreviewContainer = exports.FilePreviewContainer = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  padding: ", ";\n  justify-content: center;\n  align-items: center;\n  background: ", " 0% 0% no-repeat\n    padding-box;\n  border-radius: 10px;\n"])), function (p) {
  return p.showPreview ? '0' : '8px';
}, function (p) {
  var _p$theme;
  return p.name ? p === null || p === void 0 || (_p$theme = p.theme) === null || _p$theme === void 0 || (_p$theme = _p$theme.color) === null || _p$theme === void 0 || (_p$theme = _p$theme.primary) === null || _p$theme === void 0 ? void 0 : _p$theme.translucid : '#21212112';
});