"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PositionedS3Image = exports.PositionedImage = exports.ImageIcon = exports.CroppedContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Image = require("../Image");
var _S3PrivateImage = require("../S3PrivateImage");
var _Icon = require("../Icon");
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var ImageIcon = exports.ImageIcon = (0, _styledComponents["default"])(_Icon.Icon)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  background: rgba(0, 0, 0, 0.3);\n  padding: 4px;\n  border-radius: 8px;\n  color: white;\n  pointer-events: none;\n"])));
var CroppedContainer = exports.CroppedContainer = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  width: ", ";\n  aspect-ratio: ", ";\n  overflow: hidden;\n"])), function (p) {
  return p.width;
}, function (p) {
  return p.aspectRatio;
});
var PositionedS3Image = exports.PositionedS3Image = (0, _styledComponents["default"])(_S3PrivateImage.S3PrivateImage)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  width: 100%;\n  transform: translateY(-50%);\n  position: absolute;\n  top: 50%;\n"])));
var PositionedImage = exports.PositionedImage = (0, _styledComponents["default"])(_Image.Image)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  width: 100%;\n  top: 50%;\n  transform: translateY(-50%);\n  position: absolute;\n"])));