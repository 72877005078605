"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WEEKLY_MOVEMENT_SUCCESS = exports.WEEKLY_MOVEMENT_REQUEST = exports.WEEKLY_MOVEMENT_FAIL = exports.VISITOR_IDENTIFIER_SUCCESS = exports.VISITOR_IDENTIFIER_REQUEST = exports.VISITOR_IDENTIFIER_FAIL = exports.VISITORS_AVERAGE_SUCCESS = exports.VISITORS_AVERAGE_REQUEST = exports.VISITORS_AVERAGE_FAIL = exports.UNIT_VISITORS_SUCCESS = exports.UNIT_VISITORS_REQUEST = exports.UNIT_VISITORS_FAIL = exports.TODAY_SUMMARY_SUCCESS = exports.TODAY_SUMMARY_REQUEST = exports.TODAY_SUMMARY_FAIL = exports.RECHECKIN_EVENT_SUCCESS = exports.RECHECKIN_EVENT_REQUEST = exports.RECHECKIN_EVENT_FAIL = exports.LIST_EVENTS_SUCCESS = exports.LIST_EVENTS_REQUEST = exports.LIST_EVENTS_FAIL = exports.GET_GATE_EVENTS_SUCCESS = exports.GET_GATE_EVENTS_REQUEST = exports.GET_GATE_EVENTS_FAIL = exports.GET_EVENT_SUCCESS = exports.GET_EVENT_REQUEST = exports.GET_EVENT_FAIL = exports.GET_EVENT_BY_PASS_SUCCESS = exports.GET_EVENT_BY_PASS_REQUEST = exports.GET_EVENT_BY_PASS_FAIL = exports.GET_EVENT_BY_ID_AND_CONDOS_SUCCESS = exports.GET_EVENT_BY_ID_AND_CONDOS_REQUEST = exports.GET_EVENT_BY_ID_AND_CONDOS_FAIL = exports.EMERGENCY_SUCCESS = exports.EMERGENCY_REQUEST = exports.EMERGENCY_FAIL = exports.CHECKOUT_EVENT_SUCCESS = exports.CHECKOUT_EVENT_REQUEST = exports.CHECKOUT_EVENT_FAIL = void 0;
var LIST_EVENTS_REQUEST = exports.LIST_EVENTS_REQUEST = '@Event/LIST_EVENTS_REQUEST';
var LIST_EVENTS_SUCCESS = exports.LIST_EVENTS_SUCCESS = '@Event/LIST_EVENTS_SUCCESS';
var LIST_EVENTS_FAIL = exports.LIST_EVENTS_FAIL = '@Event/LIST_EVENTS_FAIL';
var GET_GATE_EVENTS_REQUEST = exports.GET_GATE_EVENTS_REQUEST = '@Event/GET_GATE_EVENTS_REQUEST';
var GET_GATE_EVENTS_SUCCESS = exports.GET_GATE_EVENTS_SUCCESS = '@Event/GET_GATE_EVENTS_SUCCESS';
var GET_GATE_EVENTS_FAIL = exports.GET_GATE_EVENTS_FAIL = '@Event/GET_GATE_EVENTS_FAIL';
var GET_EVENT_REQUEST = exports.GET_EVENT_REQUEST = '@Event/GET_EVENT_REQUEST';
var GET_EVENT_SUCCESS = exports.GET_EVENT_SUCCESS = '@Event/GET_EVENT_SUCCESS';
var GET_EVENT_FAIL = exports.GET_EVENT_FAIL = '@Event/GET_EVENT_FAIL';
var GET_EVENT_BY_PASS_REQUEST = exports.GET_EVENT_BY_PASS_REQUEST = '@Event/GET_EVENT_BY_PASS_REQUEST';
var GET_EVENT_BY_PASS_SUCCESS = exports.GET_EVENT_BY_PASS_SUCCESS = '@Event/GET_EVENT_BY_PASS_SUCCESS';
var GET_EVENT_BY_PASS_FAIL = exports.GET_EVENT_BY_PASS_FAIL = '@Event/GET_EVENT_BY_PASS_FAIL';
var GET_EVENT_BY_ID_AND_CONDOS_REQUEST = exports.GET_EVENT_BY_ID_AND_CONDOS_REQUEST = '@Event/GET_EVENT_BY_ID_AND_CONDOS_REQUEST';
var GET_EVENT_BY_ID_AND_CONDOS_SUCCESS = exports.GET_EVENT_BY_ID_AND_CONDOS_SUCCESS = '@Event/GET_EVENT_BY_ID_AND_CONDOS_SUCCESS';
var GET_EVENT_BY_ID_AND_CONDOS_FAIL = exports.GET_EVENT_BY_ID_AND_CONDOS_FAIL = '@Event/GET_EVENT_BY_ID_AND_CONDOS_FAIL';
var TODAY_SUMMARY_REQUEST = exports.TODAY_SUMMARY_REQUEST = '@Event/TODAY_SUMMARY_REQUEST';
var TODAY_SUMMARY_SUCCESS = exports.TODAY_SUMMARY_SUCCESS = '@Event/TODAY_SUMMARY_SUCCESS';
var TODAY_SUMMARY_FAIL = exports.TODAY_SUMMARY_FAIL = '@Event/TODAY_SUMMARY_FAIL';
var VISITORS_AVERAGE_REQUEST = exports.VISITORS_AVERAGE_REQUEST = '@Event/VISITORS_AVERAGE_REQUEST';
var VISITORS_AVERAGE_SUCCESS = exports.VISITORS_AVERAGE_SUCCESS = '@Event/VISITORS_AVERAGE_SUCCESS';
var VISITORS_AVERAGE_FAIL = exports.VISITORS_AVERAGE_FAIL = '@Event/VISITORS_AVERAGE_FAIL';
var EMERGENCY_REQUEST = exports.EMERGENCY_REQUEST = '@Event/EMERGENCY_REQUEST';
var EMERGENCY_SUCCESS = exports.EMERGENCY_SUCCESS = '@Event/EMERGENCY_SUCCESS';
var EMERGENCY_FAIL = exports.EMERGENCY_FAIL = '@Event/EMERGENCY_FAIL';
var WEEKLY_MOVEMENT_REQUEST = exports.WEEKLY_MOVEMENT_REQUEST = '@Event/WEEKLY_MOVEMENT_REQUEST';
var WEEKLY_MOVEMENT_SUCCESS = exports.WEEKLY_MOVEMENT_SUCCESS = '@Event/WEEKLY_MOVEMENT_SUCCESS';
var WEEKLY_MOVEMENT_FAIL = exports.WEEKLY_MOVEMENT_FAIL = '@Event/WEEKLY_MOVEMENT_FAIL';
var UNIT_VISITORS_REQUEST = exports.UNIT_VISITORS_REQUEST = '@Event/UNIT_VISITORS_REQUEST';
var UNIT_VISITORS_SUCCESS = exports.UNIT_VISITORS_SUCCESS = '@Event/UNIT_VISITORS_SUCCESS';
var UNIT_VISITORS_FAIL = exports.UNIT_VISITORS_FAIL = '@Event/UNIT_VISITORS_FAIL';
var RECHECKIN_EVENT_REQUEST = exports.RECHECKIN_EVENT_REQUEST = '@Event/RECHECKIN_EVENT_REQUEST';
var RECHECKIN_EVENT_SUCCESS = exports.RECHECKIN_EVENT_SUCCESS = '@Event/RECHECKIN_EVENT_SUCCESS';
var RECHECKIN_EVENT_FAIL = exports.RECHECKIN_EVENT_FAIL = '@Event/RECHECKIN_EVENT_FAIL';
var CHECKOUT_EVENT_REQUEST = exports.CHECKOUT_EVENT_REQUEST = '@Event/CHECKOUT_EVENT_REQUEST';
var CHECKOUT_EVENT_SUCCESS = exports.CHECKOUT_EVENT_SUCCESS = '@Event/CHECKOUT_EVENT_SUCCESS';
var CHECKOUT_EVENT_FAIL = exports.CHECKOUT_EVENT_FAIL = '@Event/CHECKOUT_EVENT_FAIL';
var VISITOR_IDENTIFIER_REQUEST = exports.VISITOR_IDENTIFIER_REQUEST = '@Event/VISITOR_IDENTIFIER_REQUEST';
var VISITOR_IDENTIFIER_SUCCESS = exports.VISITOR_IDENTIFIER_SUCCESS = '@Event/VISITOR_IDENTIFIER_SUCCESS';
var VISITOR_IDENTIFIER_FAIL = exports.VISITOR_IDENTIFIER_FAIL = '@Event/VISITOR_IDENTIFIER_FAIL';