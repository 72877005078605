"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  errorEditClass: false,
  loadingEditClass: false,
  errorListTag: false,
  loadingListTag: false,
  errorListCategory: false,
  loadingListCategory: false,
  errorDeleteClass: false,
  loadingDeleteClass: false,
  errorCreateClass: false,
  loadingCreateClass: false,
  categoryList: [],
  categoryListPages: 0,
  categoryListCount: 0,
  tagList: [],
  tagListPages: 0,
  tagListCount: 0
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.CREATE_CLASS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateClass: true
      });
    case _variables.CREATE_CLASS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateClass: false
      });
    case _variables.CREATE_CLASS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateClass: false,
        errorCreateClass: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.DELETE_CLASS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteClass: true
      });
    case _variables.DELETE_CLASS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteClass: false
      });
    case _variables.DELETE_CLASS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteClass: false,
        errorDeleteClass: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.EDIT_CLASS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditClass: true
      });
    case _variables.EDIT_CLASS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditClass: false
      });
    case _variables.EDIT_CLASS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditClass: false,
        errorEditClass: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {}
      });
    case _variables.LIST_CATEGORIES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListCategory: true
      });
    case _variables.LIST_CATEGORIES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListCategory: false,
        categoryList: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : [],
        categoryListCount: action.payload.headers['total-count'],
        categoryListPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_CATEGORIES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListCategory: false,
        errorListCategory: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : {}
      });
    case _variables.LIST_TAGS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListTag: true
      });
    case _variables.LIST_TAGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListTag: false,
        tagList: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : [],
        tagListCount: action.payload.headers['total-count'],
        tagListPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_TAGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListTag: false,
        errorListTag: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : {}
      });
    default:
      return state;
  }
};