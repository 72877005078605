"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestRemovePhotoAuthorizedGuest = exports.requestRemoveAuthorizedGuest = exports.requestPatchAuthorizedGuest = exports.requestListAuthorizedGuest = exports.requestCreateAuthorizedGuest = exports.requestAddPhotoAuthorizedGuest = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestListAuthorizedGuest = exports.requestListAuthorizedGuest = function requestListAuthorizedGuest(payload, meta) {
  return (0, _utils.buildAction)(_variables.AUTHORIZED_GUEST_LIST_REQUEST, payload, meta);
};
var requestCreateAuthorizedGuest = exports.requestCreateAuthorizedGuest = function requestCreateAuthorizedGuest(payload, meta) {
  return (0, _utils.buildAction)(_variables.AUTHORIZED_GUEST_CREATE_REQUEST, payload, meta);
};
var requestRemoveAuthorizedGuest = exports.requestRemoveAuthorizedGuest = function requestRemoveAuthorizedGuest(payload, meta) {
  return (0, _utils.buildAction)(_variables.AUTHORIZED_GUEST_REMOVE_REQUEST, payload, meta);
};
var requestPatchAuthorizedGuest = exports.requestPatchAuthorizedGuest = function requestPatchAuthorizedGuest(payload, meta) {
  return (0, _utils.buildAction)(_variables.AUTHORIZED_GUEST_PATCH_REQUEST, payload, meta);
};
var requestAddPhotoAuthorizedGuest = exports.requestAddPhotoAuthorizedGuest = function requestAddPhotoAuthorizedGuest(payload, meta) {
  return (0, _utils.buildAction)(_variables.AUTHORIZED_GUEST_ADD_PHOTO_REQUEST, payload, meta);
};
var requestRemovePhotoAuthorizedGuest = exports.requestRemovePhotoAuthorizedGuest = function requestRemovePhotoAuthorizedGuest(payload, meta) {
  return (0, _utils.buildAction)(_variables.AUTHORIZED_GUEST_REMOVE_PHOTO_REQUEST, payload, meta);
};