"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChipContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
var ChipContainer = exports.ChipContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 4px 8px;\n  color: ", ";\n  font-weight: bold;\n  background: ", ";\n  border-radius: 100px;\n  width: fit-content;\n"])), function (p) {
  var _p$color$default, _p$color;
  return (_p$color$default = p === null || p === void 0 || (_p$color = p.color) === null || _p$color === void 0 ? void 0 : _p$color["default"]) !== null && _p$color$default !== void 0 ? _p$color$default : '#212121';
}, function (p) {
  var _p$color$translucid, _p$color2;
  return (_p$color$translucid = p === null || p === void 0 || (_p$color2 = p.color) === null || _p$color2 === void 0 ? void 0 : _p$color2.translucid) !== null && _p$color$translucid !== void 0 ? _p$color$translucid : 'rgba(0, 0, 0, 0.07)';
});