import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store, persistor } from '@qwkin/core';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { useClearCache } from 'react-clear-cache';
import { ErrorBoundary } from 'react-error-boundary';
import './firebase';

import Routes from './routes';
import { GlobalStyle } from './styles';
import { getTokenFromFCM, onMessageListener } from './firebase';
import { isObjNullOrEmpty } from '@qwkin/helpers';
import { Notification } from '@qwkin/components';
import { extractUserAuthenticationFromState } from './utils';
import { isStringNullOrEmpty } from '@qwkin/string';
import NotificationWrapper from './Notification';
import { ErrorFallback } from './components/errorFallback';

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const [fcmToken, setFcmToken] = useState(null);
  const [errorGettingToken, setErrorGettingToken] = useState(null);

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion]);

  useEffect(() => {
    getTokenFromFCM(setFcmToken, setErrorGettingToken);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalStyle />
        <NotificationWrapper fcmToken={fcmToken} />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

export default App;
