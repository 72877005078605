"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  error: false,
  loading: false,
  changePasswordError: false,
  changePasswordData: {},
  data: {}
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data$, _action$payload9;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.RESET_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false
      });
    case _variables.SIGNIN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case _variables.SIGNIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: false,
        changePasswordError: false,
        changePasswordData: {},
        data: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.SIGNIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {},
        data: {}
      });
    case _variables.REFRESH_SESSION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case _variables.REFRESH_SESSION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: false,
        changePasswordError: false,
        changePasswordData: {},
        data: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {}
      });
    case _variables.REFRESH_SESSION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {},
        data: {}
      });
    case _variables.SIGNOUT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case _variables.SIGNOUT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: false,
        data: {}
      });
    case _variables.SIGNOUT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : {}
      });
    case _variables.CHANGE_PASSWORD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case _variables.CHANGE_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        changePasswordData: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {},
        changePasswordError: false
      });
    case _variables.CHANGE_PASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        changePasswordData: {},
        changePasswordError: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : {}
      });
    case _variables.EDIT_USER_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loadingCondo: false,
        error: false,
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          user: _objectSpread(_objectSpread(_objectSpread({}, state.data.user), (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}.user), {}, {
            profile: _objectSpread(_objectSpread({}, state.data.user.profile), (_action$payload$data$ = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 || (_action$payload9 = _action$payload9.data) === null || _action$payload9 === void 0 || (_action$payload9 = _action$payload9.profile) === null || _action$payload9 === void 0 ? void 0 : _action$payload9["public"]) !== null && _action$payload$data$ !== void 0 ? _action$payload$data$ : {}.profile["public"])
          })
        })
      });
    case _variables.EDIT_CONDO_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loadingCondo: false,
        error: false,
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          adminCondos: state.data.adminCondos.map(function (condo) {
            var _ref, _action$payload10;
            if ((_ref = condo._id === (action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 || (_action$payload10 = _action$payload10.data) === null || _action$payload10 === void 0 ? void 0 : _action$payload10._id)) !== null && _ref !== void 0 ? _ref : {}._id) {
              var _action$payload$data9, _action$payload11;
              return (_action$payload$data9 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : {};
            }
            return condo;
          })
        })
      });
    case _variables.EDIT_CONDO_CONFIGURATION_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loadingCondo: false,
        error: false,
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          adminCondos: state.data.adminCondos.map(function (condo) {
            var _ref2, _action$payload12;
            if ((_ref2 = condo._id === (action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data)) !== null && _ref2 !== void 0 ? _ref2 : {}._id) {
              var _action$payload$data10, _action$payload13;
              return _objectSpread(_objectSpread({}, condo), {}, {
                configuration: (_action$payload$data10 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}.configuration
              });
            }
            return condo;
          })
        })
      });
    default:
      return state;
  }
};