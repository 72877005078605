"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AttachmentUploader", {
  enumerable: true,
  get: function get() {
    return _AttachmentUploader["default"];
  }
});
var _AttachmentUploader = _interopRequireDefault(require("./AttachmentUploader"));