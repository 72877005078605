"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "S3PrivateImage", {
  enumerable: true,
  get: function get() {
    return _S3PrivateImage["default"];
  }
});
var _S3PrivateImage = _interopRequireDefault(require("./S3PrivateImage"));