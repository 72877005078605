"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlainHeader = exports.PlainCard = exports.HeaderContainer = exports.CardContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _functions = require("../utils/functions");
var _image = require("@qwkin/image");
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var PlainCard = exports.PlainCard = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  position: static;\n  background: white;\n  border-radius: ", ";\n  -webkit-border-radius: ", ";\n  -moz-border-radius: ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), function (p) {
  return p.title ? '0px 0px 10px 10px;' : '10px';
}, function (p) {
  return p.title ? '0px 0px 10px 10px;' : '10px';
}, function (p) {
  return p.title ? '0px 0px 10px 10px;' : '10px';
}, function (p) {
  return p.scrollable ? "overflow: scroll" : '';
}, function (p) {
  return p.padding ? "padding: ".concat(p.padding) : p.visible ? "padding: 16px" : 'padding: 8px';
}, function (p) {
  return p.title ? 'padding-top: 8px' : '';
}, function (p) {
  return p.borderLeftColor ? "border-left: 12px solid ".concat(p.borderLeftColor) : '';
});
var PlainHeader = exports.PlainHeader = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 10px 10px 0px 0px;\n  padding: ", ";\n  overflow: hidden;\n  background-color: white;\n  ", ";\n"])), function (p) {
  return p.padding ? "".concat(p.padding, " ").concat(p.padding, " 0 ").concat(p.padding) : '16px 16px 0 16px';
}, function (_ref) {
  var headerBackground = _ref.headerBackground;
  return headerBackground && "\n  min-height: 150px;\n  padding: 0 24px;\n  background-size: cover;\n  background-position: center;\n  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('".concat((0, _image.isBase64String)(headerBackground) ? "data:image/gif;base64,".concat(headerBackground) : headerBackground, "');\n  ");
});
var CardContainer = exports.CardContainer = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  box-shadow: ", ";\n  background-color: white;\n  border-radius: ", ";\n  -webkit-border-radius: ", ";\n  -moz-border-radius: ", ";\n"])), function (p) {
  return (0, _functions.getBoxShadowByElevation)(p);
}, function (p) {
  return p.title ? '0px 0px 10px 10px;' : '10px';
}, function (p) {
  return p.title ? '0px 0px 10px 10px;' : '10px';
}, function (p) {
  return p.title ? '0px 0px 10px 10px;' : '10px';
});
var HeaderContainer = exports.HeaderContainer = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: white;\n  padding: ", ";\n"])), function (p) {
  return p.padding ? "".concat(p.padding, " ").concat(p.padding, " 0 ").concat(p.padding) : '16px 16px 0 16px';
});