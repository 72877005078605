"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Chip = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = require("../utils/theme");
var _templateObject;
var Chip = exports.Chip = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  border: 1px solid ", ";\n  padding: 4px 16px;\n  text-align: center;\n  border-radius: 50px;\n  color: ", ";\n  font-weight: ", ";\n  background-color: ", ";\n  cursor: pointer;\n"])), _theme.defaultTheme.color.disabled, _theme.defaultTheme.color.primary["default"], function (p) {
  return p.isSelected ? 'bold' : 'normal';
}, function (p) {
  return p.isSelected ? _theme.defaultTheme.color.primary.translucid : _theme.defaultTheme.color.white;
});