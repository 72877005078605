"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OHRow = exports.OHColumn = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2;
var OHRow = exports.OHRow = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n"])));
var OHColumn = exports.OHColumn = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  font-family: 'Nunito', sans-serif;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  \n  .fc-timegrid-divider {\n    display: none;\n  }\n  \n  .fc-scrollgrid-section-body:first-child {\n    display: none;\n  }\n  \n  .fc-timegrid-axis {\n    border: none;\n  }\n  \n  .fc-scrollgrid {\n    border-radius: 16px;\n    border: none;\n    background: rgba(0,0,0,.05);\n  }\n  \n  .fc-col-header-cell {\n    color: ", "\n    padding: 8px;\n  }\n  \n  .fc-timegrid-slot-label {\n    padding: 4px;\n    text-transform: uppercase;\n    color: ", "\n  }\n\n  .fc-v-event {\n    padding: 8px;\n    background: ", "\n    border: 1px solid ", "\n  }\n"])), function (p) {
  return p.theme.color.primary["default"];
}, function (p) {
  return p.theme.color.primary["default"];
}, function (p) {
  return p.theme.color.primary["default"];
}, function (p) {
  return p.theme.color.primary["default"];
});