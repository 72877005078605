"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildButtonColor = exports.PlainButton = exports.ChildrenContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2;
var getBackgroundColor = function getBackgroundColor(_ref) {
  var backgroundColor = _ref.backgroundColor;
  if (backgroundColor) {
    var _backgroundColor$defa;
    return (_backgroundColor$defa = backgroundColor["default"]) !== null && _backgroundColor$defa !== void 0 ? _backgroundColor$defa : backgroundColor;
  }
  return 'transparent';
};
var getHoverColor = function getHoverColor(_ref2) {
  var backgroundColor = _ref2.backgroundColor,
    themeColor = _ref2.themeColor;
  if (backgroundColor) {
    var _backgroundColor$dark;
    return (_backgroundColor$dark = backgroundColor.darker) !== null && _backgroundColor$dark !== void 0 ? _backgroundColor$dark : backgroundColor;
  }
  return themeColor.translucid;
};
var getForegroundColor = function getForegroundColor(_ref3) {
  var foregroundColor = _ref3.foregroundColor,
    themeColor = _ref3.themeColor;
  return foregroundColor !== null && foregroundColor !== void 0 ? foregroundColor : themeColor["default"];
};
var buildButtonBackground = function buildButtonBackground(_ref4) {
  var backgroundColor = _ref4.backgroundColor,
    disabled = _ref4.disabled,
    isSelected = _ref4.isSelected,
    translucid = _ref4.translucid,
    theme = _ref4.theme,
    themeColor = _ref4.themeColor;
  if (disabled) {
    return theme.color.white;
  }
  if (translucid) {
    return isSelected ? themeColor.lighter : themeColor.translucid;
  }
  return isSelected ? getHoverColor({
    themeColor: themeColor,
    backgroundColor: backgroundColor
  }) : getBackgroundColor({
    backgroundColor: backgroundColor
  });
};
var buildButtonBorder = function buildButtonBorder(_ref5) {
  var disabled = _ref5.disabled,
    foregroundColor = _ref5.foregroundColor,
    outline = _ref5.outline,
    translucid = _ref5.translucid,
    themeColor = _ref5.themeColor;
  if (outline || translucid) {
    return 'none';
  }
  if (disabled) {
    return "3px solid ".concat(themeColor.translucid);
  }
  return "3px solid ".concat(getForegroundColor({
    foregroundColor: foregroundColor,
    themeColor: themeColor
  }));
};
var buildButtonColor = exports.buildButtonColor = function buildButtonColor(_ref6) {
  var disabled = _ref6.disabled,
    foregroundColor = _ref6.foregroundColor,
    themeColor = _ref6.themeColor;
  return disabled ? themeColor.translucid : getForegroundColor({
    foregroundColor: foregroundColor,
    themeColor: themeColor
  });
};
var ChildrenContainer = exports.ChildrenContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  width: 100%;\n  margin-left: ", ";\n  font-size: 16px;\n  font-weight: bold;\n"])), function (p) {
  return p.full ? '-20px' : '8px';
});
var PlainButton = exports.PlainButton = _styledComponents["default"].button(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  background: ", ";\n  cursor: ", ";\n  padding: ", ";\n  border-radius: 24px;\n  border: ", ";\n  color: ", ";\n  outline: none;\n  transition: all ease 0.25s;\n  font-weight: bold;\n  font-family: 'Nunito', sans-serif;\n"])), buildButtonBackground, function (p) {
  return p.disabled ? 'auto' : 'pointer';
}, function (p) {
  return p.dense ? '4px 8px' : '8px 12px';
}, buildButtonBorder, buildButtonColor);