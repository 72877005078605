"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultTheme = void 0;
var _tinycolor = _interopRequireDefault(require("tinycolor2"));
var DARKER_AMOUNT = 20;
var LIGHTER_AMOUNT = 30;
var DISABLED_AMOUNT = 0.6;
var TRANSLUCID_AMOUNT = 0.25;
var CLEAR_AMOUNT = 0.05;
var primaryHex = '#3F51B5';
var secondaryHex = '#22DAEA';
var disabledHex = '#BDBDBD';
var warningColor = '#AAAA00';
var dangerColor = '#EF5350';
var okColor = '#4CAF50';
var defaultGradientHex = "linear-gradient(140deg, ".concat((0, _tinycolor["default"])(primaryHex).toRgbString(), " 41%, ").concat((0, _tinycolor["default"])(secondaryHex).toRgbString(), " 100%);");
var defaultTheme = exports.defaultTheme = {
  color: {
    white: '#FFFFFF',
    black: '#333333',
    gray: '#999999',
    disabled: (0, _tinycolor["default"])(disabledHex).setAlpha(DISABLED_AMOUNT).toRgbString(),
    success: {
      "default": okColor,
      darker: (0, _tinycolor["default"])(okColor).darken().toRgbString(),
      lighter: (0, _tinycolor["default"])(okColor).lighten().toRgbString(),
      translucid: (0, _tinycolor["default"])(okColor).setAlpha(TRANSLUCID_AMOUNT).toRgbString(),
      clear: (0, _tinycolor["default"])(okColor).setAlpha(CLEAR_AMOUNT).toRgbString()
    },
    danger: {
      "default": dangerColor,
      darker: (0, _tinycolor["default"])(dangerColor).darken().toRgbString(),
      lighter: (0, _tinycolor["default"])(dangerColor).lighten().toRgbString(),
      translucid: (0, _tinycolor["default"])(dangerColor).setAlpha(TRANSLUCID_AMOUNT).toRgbString(),
      clear: (0, _tinycolor["default"])(dangerColor).setAlpha(CLEAR_AMOUNT).toRgbString()
    },
    warning: {
      "default": warningColor,
      darker: (0, _tinycolor["default"])(warningColor).darken().toRgbString(),
      lighter: (0, _tinycolor["default"])(warningColor).lighten().toRgbString(),
      translucid: (0, _tinycolor["default"])(warningColor).setAlpha(TRANSLUCID_AMOUNT).toRgbString(),
      clear: (0, _tinycolor["default"])(warningColor).setAlpha(CLEAR_AMOUNT).toRgbString()
    },
    gradient: defaultGradientHex,
    primary: {
      "default": primaryHex,
      darker: (0, _tinycolor["default"])(primaryHex).darken(DARKER_AMOUNT).toRgbString(),
      lighter: (0, _tinycolor["default"])(primaryHex).lighten(LIGHTER_AMOUNT).toRgbString(),
      translucid: (0, _tinycolor["default"])(primaryHex).setAlpha(TRANSLUCID_AMOUNT).toRgbString(),
      clear: (0, _tinycolor["default"])(primaryHex).setAlpha(CLEAR_AMOUNT).toRgbString()
    },
    secondary: {
      "default": secondaryHex,
      darker: (0, _tinycolor["default"])(secondaryHex).darken(DARKER_AMOUNT).toRgbString(),
      lighter: (0, _tinycolor["default"])(secondaryHex).lighten(LIGHTER_AMOUNT).toRgbString(),
      translucid: (0, _tinycolor["default"])(secondaryHex).setAlpha(TRANSLUCID_AMOUNT).toRgbString(),
      clear: (0, _tinycolor["default"])(secondaryHex).setAlpha(CLEAR_AMOUNT).toRgbString()
    }
  }
};