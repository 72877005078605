import React from 'react';
import { DefaultView, WrappedColumn } from '../../styles';

import noConnection from '../../assets/no_connection.png';
import { Button, DefaultTheme, Image, Text } from '@qwkin/components';

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <WrappedColumn
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#FFF',
      }}
    >
      <Image src={noConnection} style={{ height: '300px', marginBottom: '16px' }} />
      <Text type="title" color={DefaultTheme.color.primary.default}>
        An unexpected error occurred, please reload the page
      </Text>
      <Text type="semititle" style={{ marginBottom: '24px' }}>
        Make sure Wi-FI is on, airplane mode is off and try again, if the error persists, do log-out
        and log-in again.
      </Text>
      <Button dense icon="refresh" onClick={resetErrorBoundary}>
        Refresh Page
      </Button>
    </WrappedColumn>
  );
};
