"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_USER_BY_PHONE_SUCCESS = exports.GET_USER_BY_PHONE_REQUEST = exports.GET_USER_BY_PHONE_OR_EMAIL_SUCCESS = exports.GET_USER_BY_PHONE_OR_EMAIL_REQUEST = exports.GET_USER_BY_PHONE_OR_EMAIL_FAIL = exports.GET_USER_BY_PHONE_FAIL = exports.GET_EMPLOYEES_SUCCESS = exports.GET_EMPLOYEES_REQUEST = exports.GET_EMPLOYEES_FAIL = exports.EDIT_ADMIN_SUCCESS = exports.EDIT_ADMIN_REQUEST = exports.EDIT_ADMIN_FAIL = exports.CREATE_USER_SUCCESS = exports.CREATE_USER_REQUEST = exports.CREATE_USER_FAIL = exports.CREATE_ADMIN_SUCCESS = exports.CREATE_ADMIN_REQUEST = exports.CREATE_ADMIN_FAIL = void 0;
var CREATE_ADMIN_REQUEST = exports.CREATE_ADMIN_REQUEST = '@Unit/CREATE_ADMIN_REQUEST';
var CREATE_ADMIN_SUCCESS = exports.CREATE_ADMIN_SUCCESS = '@Unit/CREATE_ADMIN_SUCCESS';
var CREATE_ADMIN_FAIL = exports.CREATE_ADMIN_FAIL = '@Unit/CREATE_ADMIN_FAIL';
var CREATE_USER_REQUEST = exports.CREATE_USER_REQUEST = '@Unit/CREATE_USER_REQUEST';
var CREATE_USER_SUCCESS = exports.CREATE_USER_SUCCESS = '@Unit/CREATE_USER_SUCCESS';
var CREATE_USER_FAIL = exports.CREATE_USER_FAIL = '@Unit/CREATE_USER_FAIL';
var EDIT_ADMIN_REQUEST = exports.EDIT_ADMIN_REQUEST = '@Unit/EDIT_ADMIN_REQUEST';
var EDIT_ADMIN_SUCCESS = exports.EDIT_ADMIN_SUCCESS = '@Unit/EDIT_ADMIN_SUCCESS';
var EDIT_ADMIN_FAIL = exports.EDIT_ADMIN_FAIL = '@Unit/EDIT_ADMIN_FAIL';
var GET_USER_BY_PHONE_REQUEST = exports.GET_USER_BY_PHONE_REQUEST = '@Unit/GET_USER_BY_PHONE_REQUEST';
var GET_USER_BY_PHONE_SUCCESS = exports.GET_USER_BY_PHONE_SUCCESS = '@Unit/GET_USER_BY_PHONE_SUCCESS';
var GET_USER_BY_PHONE_FAIL = exports.GET_USER_BY_PHONE_FAIL = '@Unit/GET_USER_BY_PHONE_FAIL';
var GET_USER_BY_PHONE_OR_EMAIL_REQUEST = exports.GET_USER_BY_PHONE_OR_EMAIL_REQUEST = '@Unit/GET_USER_BY_PHONE_OR_EMAIL_REQUEST';
var GET_USER_BY_PHONE_OR_EMAIL_SUCCESS = exports.GET_USER_BY_PHONE_OR_EMAIL_SUCCESS = '@Unit/GET_USER_BY_PHONE_OR_EMAIL_SUCCESS';
var GET_USER_BY_PHONE_OR_EMAIL_FAIL = exports.GET_USER_BY_PHONE_OR_EMAIL_FAIL = '@Unit/GET_USER_BY_PHONE_OR_EMAIL_FAIL';
var GET_EMPLOYEES_REQUEST = exports.GET_EMPLOYEES_REQUEST = '@Unit/GET_EMPLOYEES_REQUEST';
var GET_EMPLOYEES_SUCCESS = exports.GET_EMPLOYEES_SUCCESS = '@Unit/GET_EMPLOYEES_SUCCESS';
var GET_EMPLOYEES_FAIL = exports.GET_EMPLOYEES_FAIL = '@Unit/GET_EMPLOYEES_FAIL';