"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Swatch = exports.Popover = exports.Cover = exports.Color = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var Color = exports.Color = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  background: ", ";\n"])), function (_ref) {
  var width = _ref.width;
  return "".concat(width, "px");
}, function (_ref2) {
  var height = _ref2.height;
  return "".concat(height, "px");
}, function (_ref3) {
  var color = _ref3.color;
  return color;
});
var Swatch = exports.Swatch = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  padding: 5px;\n  background: #fff;\n  border-radius: 50%;\n  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);\n  display: inline-block;\n  cursor: pointer;\n"])));
var Popover = exports.Popover = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  position: absolute;\n  z-index: 2;\n  top: 80px;\n"])));
var Cover = exports.Cover = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  position: fixed;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n"])));