"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fadeOut = exports.fadeIn = exports.bounceSmall = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = require("styled-components");
var _templateObject, _templateObject2, _templateObject3;
var fadeIn = exports.fadeIn = (0, _styledComponents.keyframes)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  from { opacity: 0; }\n  to   { opacity: 1; }\n"])));
var fadeOut = exports.fadeOut = (0, _styledComponents.keyframes)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  from { opacity: 1; }\n  to   { opacity: 0; }\n"])));
var bounceSmall = exports.bounceSmall = (0, _styledComponents.keyframes)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  0%, 75%, 100%{\n    transform: translateY(0);\n  }\n\n  25%{\n    transform: translateY(-10px);\n  }\n"])));