"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _tinycolor = _interopRequireDefault(require("tinycolor2"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
var getButtonStyleBackground = function getButtonStyleBackground(p) {
  if (p.colored) {
    return p.isSelected ? (0, _tinycolor["default"])(p.containerColor).darken().toRgbString() : p.containerColor;
  }
  return p.isSelected ? (0, _tinycolor["default"])(p.color).setAlpha(0.25).toRgbString() : 'transparent';
};
var getHoverColor = function getHoverColor(p) {
  if (p.colored) {
    return (0, _tinycolor["default"])(p.containerColor).darken().toRgbString();
  }
  return (0, _tinycolor["default"])(p.color).setAlpha(0.1).toRgbString();
};
var containerPadding = function containerPadding(_ref) {
  var padding = _ref.padding,
    dense = _ref.dense;
  if (padding) {
    return padding;
  }
  return dense ? '3px' : '12px';
};
var ButtonContainer = exports.ButtonContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  width: ", ";\n  height: ", ";\n  background: ", ";\n  padding: ", ";\n  border-radius: ", ";\n  transition: all ease 0.25s;\n  overflow: hidden;\n  white-space: nowrap;\n  ", "\n\n  &:hover {\n    ", "\n  }\n"])), function (p) {
  return "".concat(p.size, "px");
}, function (p) {
  return "".concat(p.size, "px");
}, function (p) {
  return getButtonStyleBackground(p);
}, function (p) {
  return containerPadding(p);
}, function (p) {
  return p.format === 'square' ? '5px' : '50%';
}, function (p) {
  return !p.disabled && 'cursor: pointer;';
}, function (p) {
  return !(p.isSelected || p.disabled) && "background: ".concat(getHoverColor(p), ";");
});