export const extractUserAuthenticationFromState = ({
  auth: {
    data: { isAuthenticated, token },
    loading,
    error,
  },
}) => ({ isAuthenticated, token, loading, error });

export const extractUserDataFromState = ({
  auth: {
    data: { adminCondos, user, masterCondo },
  },
}) => ({ adminCondos, user, masterCondo });

export const extractCondoInfoFromArray = (id, condos) => condos.find(c => c._id === id);

export const extractCondoConfiguration = condo => {
  const { configuration } = condo;
  return configuration;
};

export const extractCondoConfigurationByCondoId = (id, condos) => {
  const condo = extractCondoInfoFromArray(id, condos);
  return extractCondoConfiguration(condo);
};

export const extractCondosIdsFromState = arr =>
  arr.reduce((acc, curr) => {
    acc.push(curr._id);

    return acc;
  }, []);

export const extractUsersFromUnits = (units = [], showEmptyUnits = false) =>
  units.reduce((acc, curr) => {
    const { owners, renters, residents } = curr.users;

    const extractUserFromUnit = ({ profile = {}, user = {}, ...params }, type) => {
      return {
        id: params._id,
        unit: {
          number: curr.number,
          _id: curr._id,
        },
        condo: curr.condoId,
        name: profile.public && `${profile.public.firstName} ${profile.public.lastName}`,
        phone: user.phone,
        cellPhone: profile.public.otherPhones?.find(phone => phone.type === 'cell')?.number,
        homePhone: profile.public.otherPhones?.find(phone => phone.type === 'home')?.number,
        workPhone: profile.public.otherPhones?.find(phone => phone.type === 'work')?.number,
        otherPhone: profile.public.otherPhones?.find(phone => phone.type === 'other')?.number,
        contactPhone: profile?.public?.otherPhones && profile?.public?.otherPhones[0]?.number,
        email: user.email,
        type,
      };
    };

    const extractEmptyUnit = () => ({
      unit: {
        number: curr.number,
        _id: curr._id,
      },
      condo: curr.condoId,
      name: 'Empty unit',
    });

    if (owners) {
      owners.map(owner => acc.push(extractUserFromUnit(owner, 'Owner')));
    }

    if (renters) {
      renters.map(renter => acc.push(extractUserFromUnit(renter, 'Renter')));
    }

    if (residents) {
      residents.map(resident => acc.push(extractUserFromUnit(resident, 'Resident')));
    }

    if (showEmptyUnits && owners.length === 0 && renters.length === 0 && residents.length === 0) {
      acc.push(extractEmptyUnit());
    }

    return acc;
  }, []);

export const buildDefaultFuseOpts = (additionalOpts = {}, ...keys) => ({
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  minMatchCharLength: 1,
  keys,
  ...additionalOpts,
});

export const oneMegabyteInBytes = 1048576;
