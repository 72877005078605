"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logErr = exports.getErrorMsg = exports.buildAction = void 0;
var _typesafeActions = require("typesafe-actions");
var _helpers = require("@qwkin/helpers");
var buildAction = exports.buildAction = function buildAction(type, payload) {
  var meta = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return (0, _typesafeActions.action)(type, payload, Object.assign({
    callback: _helpers.identity
  }, meta));
};
var logErr = exports.logErr = function logErr(err) {
  return process.env.NODE_ENV !== 'production' && console.error(err);
};
var getErrorMsg = exports.getErrorMsg = function getErrorMsg() {
  var err = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (err === null || err === void 0 ? void 0 : err.message) || (err === null || err === void 0 ? void 0 : err.err) || 'An error has occurred, please try again';
};