"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBoxShadowByElevation = void 0;
var getBoxShadowByElevation = exports.getBoxShadowByElevation = function getBoxShadowByElevation(p) {
  switch (p.elevation) {
    case 'none':
      return "0px 0px 0px 0px";
    case 'small':
      return "".concat(p.theme.color.secondary["default"], " 4px 4px 0px");
    case 'medium':
      return "".concat(p.theme.color.secondary["default"], " 8px 8px 0px");
    case 'high':
      return "".concat(p.theme.color.secondary["default"], " 12px 12px 0px");
    default:
      return "0px 0px 0px 0px";
  }
};