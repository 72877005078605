"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestListContractorTypes = exports.requestListContractor = exports.requestGetContractor = exports.requestEnableContractorType = exports.requestEditContractorType = exports.requestEditContractor = exports.requestDisableContractorType = exports.requestDeleteContractorType = exports.requestCreateContractorType = exports.requestCreateContractor = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestCreateContractor = exports.requestCreateContractor = function requestCreateContractor(payload, meta) {
  return (0, _utils.buildAction)(_variables.CONTRACTORS_CREATE_REQUEST, payload, meta);
};
var requestCreateContractorType = exports.requestCreateContractorType = function requestCreateContractorType(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_CONTRACTOR_TYPE_REQUEST, payload, meta);
};
var requestEditContractorType = exports.requestEditContractorType = function requestEditContractorType(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_CONTRACTOR_TYPE_REQUEST, payload, meta);
};
var requestDeleteContractorType = exports.requestDeleteContractorType = function requestDeleteContractorType(payload, meta) {
  return (0, _utils.buildAction)(_variables.DELETE_CONTRACTOR_TYPE_REQUEST, payload, meta);
};
var requestListContractorTypes = exports.requestListContractorTypes = function requestListContractorTypes(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_CONTRACTOR_TYPE_REQUEST, payload, meta);
};
var requestEnableContractorType = exports.requestEnableContractorType = function requestEnableContractorType(payload, meta) {
  return (0, _utils.buildAction)(_variables.ENABLE_CONTRACTOR_TYPE_REQUEST, payload, meta);
};
var requestDisableContractorType = exports.requestDisableContractorType = function requestDisableContractorType(payload, meta) {
  return (0, _utils.buildAction)(_variables.DISABLE_CONTRACTOR_TYPE_REQUEST, payload, meta);
};
var requestEditContractor = exports.requestEditContractor = function requestEditContractor(payload, meta) {
  return (0, _utils.buildAction)(_variables.CONTRACTORS_EDIT_REQUEST, payload, meta);
};
var requestGetContractor = exports.requestGetContractor = function requestGetContractor(payload, meta) {
  return (0, _utils.buildAction)(_variables.CONTRACTORS_GET_REQUEST, payload, meta);
};
var requestListContractor = exports.requestListContractor = function requestListContractor(payload, meta) {
  return (0, _utils.buildAction)(_variables.CONTRACTORS_LIST_REQUEST, payload, meta);
};