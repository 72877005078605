"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlainFAB = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _functions = require("../utils/functions");
var _templateObject;
var PlainFAB = exports.PlainFAB = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  font-size: 32px;\n  width: 64px;\n  height: 64px;\n  min-width: 64px;\n  min-height: 64px;\n  max-width: 64px;\n  max-height: 64px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 50%;\n  cursor: pointer;\n  color: ", ";\n  transition: all ease 0.25s;\n"])), function (_ref) {
  var isSelected = _ref.isSelected,
    theme = _ref.theme;
  return isSelected ? theme.color.primary.darker : theme.color.primary["default"];
}, function (p) {
  return (0, _functions.getBoxShadowByElevation)(p);
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.color.white;
});