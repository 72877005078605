import React, { useState } from 'react';
import { Button, DefaultTheme, Icon, Modal, Text } from '@qwkin/components';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, SignoutAction } from '@qwkin/core';
import { extractUserAuthenticationFromState, extractUserDataFromState } from '../../utils';
// eslint-disable-next-line import/no-cycle
import { ButtonsRow, DropdownMenu, DropdownMenuItem } from './styles';
import UserDropdownMenu from './userDropdownMenu';
import { Divider } from '../divider';
import { push } from 'connected-react-router';

const UserCard = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(extractUserAuthenticationFromState);
  const { masterCondo } = useSelector(extractUserDataFromState);
  const [isLogOutModalVisible, setLogOutModalVisibility] = useState(false);
  const [isSearchOpened, setSearchOpened] = useState(false);

  const { requestUnregisterPush } = Actions;

  const handleLogout = () => {
    dispatch(
      requestUnregisterPush({
        token,
        condo: masterCondo,
        application: 'admin',
        platform: 'web',
      }),
    );
    localStorage.removeItem('isVisibleAnnouncement');
    dispatch({ type: SignoutAction });
  };

  const goToSettings = () => dispatch(push('/settings'));

  return (
    <div>
      <Modal title="Log Out" isOpen={isLogOutModalVisible} hasCloseButton={false}>
        <Text style={{ margin: '8px 0' }}>Are you sure you want to sign out?</Text>
        <ButtonsRow>
          <Button icon="checkmark" onClick={handleLogout} themeColor={DefaultTheme.color.success}>
            Yes, proceed
          </Button>
          <Button
            icon="close"
            outline
            themeColor={DefaultTheme.color.danger}
            className="noButton"
            onClick={() => setLogOutModalVisibility(false)}
          >
            No
          </Button>
        </ButtonsRow>
      </Modal>
      <UserDropdownMenu
        controlledOpened={isSearchOpened}
        onClick={() => setSearchOpened(true)}
        onClose={() => setSearchOpened(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <DropdownMenu>
          <DropdownMenuItem
            onClick={() => {
              setSearchOpened(false);
              goToSettings();
            }}
          >
            <Icon icon="settings" size={22} />
            <Text>Settings</Text>
          </DropdownMenuItem>
          <Divider color={DefaultTheme.color.disabled} style={{ margin: '4px' }} />
          <DropdownMenuItem
            onClick={() => {
              setSearchOpened(false);
              setLogOutModalVisibility(true);
            }}
          >
            <Icon icon="on-off" size={22} color={DefaultTheme.color.danger.default} />
            <Text color={DefaultTheme.color.danger.default}>Logout</Text>
          </DropdownMenuItem>
        </DropdownMenu>
      </UserDropdownMenu>
    </div>
  );
};

export default UserCard;
