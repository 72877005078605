"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestListDocuments = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestListDocuments = exports.requestListDocuments = function requestListDocuments(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_DOCUMENTS_REQUEST, payload, meta);
};