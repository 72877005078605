"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AmenityService", {
  enumerable: true,
  get: function get() {
    return _Amenity.AmenityService;
  }
});
Object.defineProperty(exports, "AuthService", {
  enumerable: true,
  get: function get() {
    return _Auth.AuthService;
  }
});
Object.defineProperty(exports, "AuthorizedGuestService", {
  enumerable: true,
  get: function get() {
    return _AuthorizedGuest.AuthorizedGuestService;
  }
});
Object.defineProperty(exports, "CheckinPassService", {
  enumerable: true,
  get: function get() {
    return _CheckinPass.CheckinPassService;
  }
});
Object.defineProperty(exports, "ClassService", {
  enumerable: true,
  get: function get() {
    return _Class.ClassService;
  }
});
Object.defineProperty(exports, "CommunityService", {
  enumerable: true,
  get: function get() {
    return _Community.CommunityService;
  }
});
Object.defineProperty(exports, "CondominiumService", {
  enumerable: true,
  get: function get() {
    return _Condominium.CondominiumService;
  }
});
Object.defineProperty(exports, "ContractorService", {
  enumerable: true,
  get: function get() {
    return _Contractor.ContractorService;
  }
});
Object.defineProperty(exports, "DocumentsService", {
  enumerable: true,
  get: function get() {
    return _Documents.DocumentsService;
  }
});
Object.defineProperty(exports, "EventService", {
  enumerable: true,
  get: function get() {
    return _Event.EventService;
  }
});
Object.defineProperty(exports, "GateCameraService", {
  enumerable: true,
  get: function get() {
    return _GateCamera.GateCameraService;
  }
});
Object.defineProperty(exports, "LookOutListService", {
  enumerable: true,
  get: function get() {
    return _LookOutList.LookOutListService;
  }
});
Object.defineProperty(exports, "PackagesService", {
  enumerable: true,
  get: function get() {
    return _Packages.PackagesService;
  }
});
Object.defineProperty(exports, "PetsService", {
  enumerable: true,
  get: function get() {
    return _Pets.PetsService;
  }
});
Object.defineProperty(exports, "PushNotificationService", {
  enumerable: true,
  get: function get() {
    return _PushNotification.PushNotificationService;
  }
});
Object.defineProperty(exports, "ReportService", {
  enumerable: true,
  get: function get() {
    return _Report.ReportService;
  }
});
Object.defineProperty(exports, "ResourceService", {
  enumerable: true,
  get: function get() {
    return _Resource.ResourceService;
  }
});
Object.defineProperty(exports, "SettingsService", {
  enumerable: true,
  get: function get() {
    return _Settings.SettingsService;
  }
});
Object.defineProperty(exports, "StaffNotesService", {
  enumerable: true,
  get: function get() {
    return _StaffNotes.StaffNotesService;
  }
});
Object.defineProperty(exports, "TempFilesService", {
  enumerable: true,
  get: function get() {
    return _TempFiles.TempFilesService;
  }
});
Object.defineProperty(exports, "UnitService", {
  enumerable: true,
  get: function get() {
    return _Unit.UnitService;
  }
});
Object.defineProperty(exports, "UserService", {
  enumerable: true,
  get: function get() {
    return _User.UserService;
  }
});
Object.defineProperty(exports, "ValetService", {
  enumerable: true,
  get: function get() {
    return _Valet.ValetService;
  }
});
Object.defineProperty(exports, "VehiclesService", {
  enumerable: true,
  get: function get() {
    return _Vehicles.VehiclesService;
  }
});
var _Amenity = require("./Amenity");
var _AuthorizedGuest = require("./AuthorizedGuest");
var _Auth = require("./Auth");
var _CheckinPass = require("./CheckinPass");
var _Class = require("./Class");
var _Community = require("./Community");
var _Condominium = require("./Condominium");
var _Contractor = require("./Contractor");
var _Event = require("./Event");
var _GateCamera = require("./GateCamera");
var _LookOutList = require("./LookOutList");
var _Packages = require("./Packages");
var _Pets = require("./Pets");
var _Settings = require("./Settings");
var _Report = require("./Report");
var _Resource = require("./Resource");
var _StaffNotes = require("./StaffNotes");
var _TempFiles = require("./TempFiles");
var _Unit = require("./Unit");
var _User = require("./User");
var _Valet = require("./Valet");
var _Vehicles = require("./Vehicles");
var _PushNotification = require("./PushNotification");
var _Documents = require("./Documents");