"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AUTHORIZED_GUEST_REMOVE_SUCCESS = exports.AUTHORIZED_GUEST_REMOVE_REQUEST = exports.AUTHORIZED_GUEST_REMOVE_PHOTO_SUCCESS = exports.AUTHORIZED_GUEST_REMOVE_PHOTO_REQUEST = exports.AUTHORIZED_GUEST_REMOVE_PHOTO_FAIL = exports.AUTHORIZED_GUEST_REMOVE_FAIL = exports.AUTHORIZED_GUEST_PATCH_SUCCESS = exports.AUTHORIZED_GUEST_PATCH_REQUEST = exports.AUTHORIZED_GUEST_PATCH_FAIL = exports.AUTHORIZED_GUEST_LIST_SUCCESS = exports.AUTHORIZED_GUEST_LIST_REQUEST = exports.AUTHORIZED_GUEST_LIST_FAIL = exports.AUTHORIZED_GUEST_CREATE_SUCCESS = exports.AUTHORIZED_GUEST_CREATE_REQUEST = exports.AUTHORIZED_GUEST_CREATE_FAIL = exports.AUTHORIZED_GUEST_ADD_PHOTO_SUCCESS = exports.AUTHORIZED_GUEST_ADD_PHOTO_REQUEST = exports.AUTHORIZED_GUEST_ADD_PHOTO_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var AUTHORIZED_GUEST_LIST_REQUEST = exports.AUTHORIZED_GUEST_LIST_REQUEST = '@CheckinPass/AUTHORIZED_GUEST_LIST_REQUEST';
var AUTHORIZED_GUEST_LIST_SUCCESS = exports.AUTHORIZED_GUEST_LIST_SUCCESS = '@CheckinPass/AUTHORIZED_GUEST_LIST_SUCCESS';
var AUTHORIZED_GUEST_LIST_FAIL = exports.AUTHORIZED_GUEST_LIST_FAIL = '@CheckinPass/AUTHORIZED_GUEST_LIST_FAIL';
var AUTHORIZED_GUEST_CREATE_REQUEST = exports.AUTHORIZED_GUEST_CREATE_REQUEST = '@CheckinPass/AUTHORIZED_GUEST_CREATE_REQUEST';
var AUTHORIZED_GUEST_CREATE_SUCCESS = exports.AUTHORIZED_GUEST_CREATE_SUCCESS = '@CheckinPass/AUTHORIZED_GUEST_CREATE_SUCCESS';
var AUTHORIZED_GUEST_CREATE_FAIL = exports.AUTHORIZED_GUEST_CREATE_FAIL = '@CheckinPass/AUTHORIZED_GUEST_CREATE_FAIL';
var AUTHORIZED_GUEST_REMOVE_REQUEST = exports.AUTHORIZED_GUEST_REMOVE_REQUEST = '@CheckinPass/AUTHORIZED_GUEST_REMOVE_REQUEST';
var AUTHORIZED_GUEST_REMOVE_SUCCESS = exports.AUTHORIZED_GUEST_REMOVE_SUCCESS = '@CheckinPass/AUTHORIZED_GUEST_REMOVE_SUCCESS';
var AUTHORIZED_GUEST_REMOVE_FAIL = exports.AUTHORIZED_GUEST_REMOVE_FAIL = '@CheckinPass/AUTHORIZED_GUEST_REMOVE_FAIL';
var AUTHORIZED_GUEST_PATCH_REQUEST = exports.AUTHORIZED_GUEST_PATCH_REQUEST = '@CheckinPass/AUTHORIZED_GUEST_PATCH_REQUEST';
var AUTHORIZED_GUEST_PATCH_SUCCESS = exports.AUTHORIZED_GUEST_PATCH_SUCCESS = '@CheckinPass/AUTHORIZED_GUEST_PATCH_SUCCESS';
var AUTHORIZED_GUEST_PATCH_FAIL = exports.AUTHORIZED_GUEST_PATCH_FAIL = '@CheckinPass/AUTHORIZED_GUEST_PATCH_FAIL';
var AUTHORIZED_GUEST_ADD_PHOTO_REQUEST = exports.AUTHORIZED_GUEST_ADD_PHOTO_REQUEST = '@CheckinPass/AUTHORIZED_GUEST_ADD_PHOTO_REQUEST';
var AUTHORIZED_GUEST_ADD_PHOTO_SUCCESS = exports.AUTHORIZED_GUEST_ADD_PHOTO_SUCCESS = '@CheckinPass/AUTHORIZED_GUEST_ADD_PHOTO_SUCCESS';
var AUTHORIZED_GUEST_ADD_PHOTO_FAIL = exports.AUTHORIZED_GUEST_ADD_PHOTO_FAIL = '@CheckinPass/AUTHORIZED_GUEST_ADD_PHOTO_FAIL';
var AUTHORIZED_GUEST_REMOVE_PHOTO_REQUEST = exports.AUTHORIZED_GUEST_REMOVE_PHOTO_REQUEST = '@CheckinPass/AUTHORIZED_GUEST_REMOVE_PHOTO_REQUEST';
var AUTHORIZED_GUEST_REMOVE_PHOTO_SUCCESS = exports.AUTHORIZED_GUEST_REMOVE_PHOTO_SUCCESS = '@CheckinPass/AUTHORIZED_GUEST_REMOVE_PHOTO_SUCCESS';
var AUTHORIZED_GUEST_REMOVE_PHOTO_FAIL = exports.AUTHORIZED_GUEST_REMOVE_PHOTO_FAIL = '@CheckinPass/AUTHORIZED_GUEST_REMOVE_PHOTO_FAIL';