"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconWrapper = exports.FilterChipsContainer = exports.ChipsWrapper = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var ChipsWrapper = exports.ChipsWrapper = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n"])));
var FilterChipsContainer = exports.FilterChipsContainer = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 6px 16px;\n  color: ", ";\n  font-weight: 500;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 10px;\n  width: fit-content;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n    border: 1px solid ", ";\n  }\n"])), function (_ref) {
  var _color$selected, _color$default;
  var isSelected = _ref.isSelected,
    color = _ref.color;
  return isSelected ? (_color$selected = color === null || color === void 0 ? void 0 : color.selected) !== null && _color$selected !== void 0 ? _color$selected : '#0E1D2A' : (_color$default = color === null || color === void 0 ? void 0 : color["default"]) !== null && _color$default !== void 0 ? _color$default : '#72777F';
}, function (_ref2) {
  var _color$selectedBackgr, _color$background;
  var isSelected = _ref2.isSelected,
    color = _ref2.color;
  return isSelected ? (_color$selectedBackgr = color === null || color === void 0 ? void 0 : color.selectedBackground) !== null && _color$selectedBackgr !== void 0 ? _color$selectedBackgr : '#D5E4F7' : (_color$background = color === null || color === void 0 ? void 0 : color.background) !== null && _color$background !== void 0 ? _color$background : '#FFFFFF';
}, function (_ref3) {
  var _color$selectedBorder, _color$border;
  var isSelected = _ref3.isSelected,
    color = _ref3.color;
  return isSelected ? (_color$selectedBorder = color === null || color === void 0 ? void 0 : color.selectedBorder) !== null && _color$selectedBorder !== void 0 ? _color$selectedBorder : '#D5E4F7' : (_color$border = color === null || color === void 0 ? void 0 : color.border) !== null && _color$border !== void 0 ? _color$border : '#72777F';
}, function (_ref4) {
  var _color$hoverSelectedB, _color$hoverBackgroun;
  var isSelected = _ref4.isSelected,
    color = _ref4.color;
  return isSelected ? (_color$hoverSelectedB = color === null || color === void 0 ? void 0 : color.hoverSelectedBackground) !== null && _color$hoverSelectedB !== void 0 ? _color$hoverSelectedB : '#f0f0f0' : (_color$hoverBackgroun = color === null || color === void 0 ? void 0 : color.hoverBackground) !== null && _color$hoverBackgroun !== void 0 ? _color$hoverBackgroun : '#f0f0f0';
}, function (_ref5) {
  var _color$hoverSelectedB2, _color$hoverBorder;
  var isSelected = _ref5.isSelected,
    color = _ref5.color;
  return isSelected ? (_color$hoverSelectedB2 = color === null || color === void 0 ? void 0 : color.hoverSelectedBorder) !== null && _color$hoverSelectedB2 !== void 0 ? _color$hoverSelectedB2 : '#72777F' : (_color$hoverBorder = color === null || color === void 0 ? void 0 : color.hoverBorder) !== null && _color$hoverBorder !== void 0 ? _color$hoverBorder : '#72777F';
});
var fadeIn = (0, _styledComponents.keyframes)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  from {\n    opacity: 0;\n    transform: scale(0.5);\n  }\n  to {\n    opacity: 1;\n    transform: scale(1);\n  }\n"])));
var IconWrapper = exports.IconWrapper = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  margin-right: 8px;\n  display: flex;\n  align-items: center;\n  opacity: 0;\n  transform: scale(0.5);\n  transition: opacity 0.3s ease, transform 0.3s ease;\n\n  ", "\n"])), function (_ref6) {
  var isSelected = _ref6.isSelected;
  return isSelected && (0, _styledComponents.css)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n      animation: ", " 0.3s forwards;\n    "])), fadeIn);
});