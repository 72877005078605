import React, { useMemo } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { DefaultTheme } from '@qwkin/components';
import { Divider } from '../divider';
import { AppFeatures } from '../appContainer';
import { PlainSidemenu, DividerContainer, Logo } from './styles';
import SideMenuItemBuilder from './sideMenuItem';
import logo from '../../assets/logo.png';

const Sidemenu = ({ activeAppFeature = AppFeatures.dashboard, isFullScreen = false, condo }) => {
  const dispatch = useDispatch();

  const goToPackageTracking = () => dispatch(push('/packageTracking'));
  const goToGateFeed = () => dispatch(push('/gateFeed'));
  const goToContractors = () => dispatch(push('/contractors'));
  const goToCommunity = () => dispatch(push('/community'));
  const goToUnitsList = () => dispatch(push('/units'));
  const goToDashboard = () => dispatch(push('/'));
  const goToAmenitiesV2 = () => dispatch(push('/amenities'));
  const goToReports = () => dispatch(push('/reports'));
  const goToValet = () => dispatch(push('/valet'));
  const goToWorkOrders = () => dispatch(push('/workOrders'));
  const goToDocuments = () => dispatch(push('/documents'));

  const qwkinLogoMemoized = useMemo(() => logo, [logo]);

  return (
    <>
      {!isFullScreen && (
        <PlainSidemenu>
          <Logo animate={false} src={qwkinLogoMemoized} />
          <Divider
            color={DefaultTheme.color.gray}
            marginFactor="0px"
            style={{ margin: '3px 0 8px 0' }}
          />
          <SideMenuItemBuilder
            title="Dashboard"
            icon="dashboard"
            onClick={goToDashboard}
            active={activeAppFeature === AppFeatures.dashboard}
          />
          <SideMenuItemBuilder
            title="Activity Feed"
            icon="gate"
            onClick={goToGateFeed}
            active={activeAppFeature === AppFeatures.gateFeed}
          />
          <SideMenuItemBuilder
            title="Units"
            icon="house"
            onClick={goToUnitsList}
            active={activeAppFeature === AppFeatures.units}
          />
          <SideMenuItemBuilder
            title="Contractors"
            icon="contractor"
            onClick={goToContractors}
            active={activeAppFeature === AppFeatures.contractors}
          />
          {!(
            condo?.featureToggle.includes('handoutBulletin') &&
            condo?.featureToggle.includes('handoutNotice')
          ) && (
            <SideMenuItemBuilder
              title="Community"
              icon="community"
              onClick={goToCommunity}
              active={activeAppFeature === AppFeatures.community}
            />
          )}
          <DividerContainer>
            <Divider color={DefaultTheme.color.gray} style={{ margin: '4px' }} />
          </DividerContainer>
          {!condo?.featureToggle.includes('amenity') && (
            <SideMenuItemBuilder
              title="Amenities"
              icon="amenity"
              onClick={goToAmenitiesV2}
              active={activeAppFeature === AppFeatures.amenities}
            />
          )}
          <SideMenuItemBuilder
            title="Packages"
            icon="package"
            onClick={goToPackageTracking}
            active={activeAppFeature === AppFeatures.packages}
          />
          <SideMenuItemBuilder
            title="Valet"
            icon="car"
            onClick={goToValet}
            active={activeAppFeature === AppFeatures.valet}
          />
          {!condo?.featureToggle.includes('workOrder') && (
            <DividerContainer>
              <Divider color={DefaultTheme.color.gray} style={{ margin: '4px' }} />
            </DividerContainer>
          )}
          {!condo?.featureToggle.includes('workOrder') && (
            <SideMenuItemBuilder
              title="Work Orders"
              icon="work-order"
              onClick={goToWorkOrders}
              active={activeAppFeature === AppFeatures.workOrders}
            />
          )}
          <DividerContainer>
            <Divider color={DefaultTheme.color.gray} style={{ margin: '4px' }} />
          </DividerContainer>
          {!condo?.featureToggle.includes('document') && (
            <SideMenuItemBuilder
              title="Documents"
              icon="document"
              onClick={goToDocuments}
              active={activeAppFeature === AppFeatures.documents}
            />
          )}
          <SideMenuItemBuilder
            title="Reports"
            icon="report"
            onClick={goToReports}
            active={activeAppFeature === AppFeatures.reports}
          />
        </PlainSidemenu>
      )}
    </>
  );
};

export default Sidemenu;
