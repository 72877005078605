"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContainerRow = exports.ContainerColumn = exports.BorderedContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3;
var BorderedContainer = exports.BorderedContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  border-radius: 10px;\n  border: ", " solid\n    ", ";\n  transition: border 10ms ease-out;\n  padding: ", ";\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n"])), function (p) {
  return !p.bordered ? '0px' : p.selected ? '2px' : '0.5px';
}, function (p) {
  return p.selected ? '#3F51B5' : p.disabled ? 'rgba(0, 0, 0, 0.07)' : 'rgba(0, 0, 0, 0.38)';
}, function (p) {
  return p.dense ? '4px' : '16px';
});
var ContainerRow = exports.ContainerRow = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
var ContainerColumn = exports.ContainerColumn = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n"])));