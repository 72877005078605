import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStringNullOrEmpty } from '@qwkin/string';
import { Notification } from '@qwkin/components';
import { Actions } from '@qwkin/core';

import { isObjNullOrEmpty } from '@qwkin/helpers';
import { extractUserAuthenticationFromState, extractUserDataFromState } from './utils';
import { onMessageListener } from './firebase';

const NotificationWrapper = ({ fcmToken }) => {
  const dispatch = useDispatch();
  const defaultPushNotification = Object.freeze({ isOpen: false, payload: {}, error: null });

  const [pushNotification, setPushNotification] = useState(defaultPushNotification);

  const { requestRegisterPush } = Actions;
  const { token } = useSelector(extractUserAuthenticationFromState);
  const { masterCondo } = useSelector(extractUserDataFromState);

  onMessageListener()
    .then(payload => {
      setPushNotification({ isOpen: true, payload });
    })
    .catch(err => {
      setPushNotification({ isOpen: false, error: err });
    });

  useEffect(() => {
    if (
      !isStringNullOrEmpty(token) &&
      !isStringNullOrEmpty(fcmToken) &&
      !isObjNullOrEmpty(masterCondo)
    ) {
      dispatch(
        requestRegisterPush({
          token,
          fcmToken,
          condo: masterCondo,
          platformVersion: '1.0.0',
          platform: 'web',
          application: 'admin',
        }),
      );
    }
  }, [token, fcmToken]);

  return (
    <Notification
      variant="info"
      isOpen={pushNotification.isOpen}
      onClose={() => setPushNotification(defaultPushNotification)}
      message={pushNotification?.payload?.notification?.title}
    />
  );
};

export default NotificationWrapper;
