"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  history: true,
  store: true,
  persistor: true,
  Modules: true,
  Actions: true,
  API: true
};
Object.defineProperty(exports, "API", {
  enumerable: true,
  get: function get() {
    return _Api["default"];
  }
});
exports.store = exports.persistor = exports.history = exports.Modules = exports.Actions = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _redux = require("redux");
var _connectedReactRouter = require("connected-react-router");
var _reduxSaga = _interopRequireDefault(require("redux-saga"));
var _reduxPersist = require("redux-persist");
var _modules = require("./modules");
var _history = require("history");
var _requests = require("./requests");
Object.keys(_requests).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _requests[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _requests[key];
    }
  });
});
var _Api = _interopRequireDefault(require("./services/Api"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var history = exports.history = (0, _history.createHashHistory)();
var sagaMiddleware = (0, _reduxSaga["default"])();
var routeMiddleware = (0, _connectedReactRouter.routerMiddleware)(history);
var middlewares = [sagaMiddleware, routeMiddleware];
var enhancers;
if (process.env.NODE_ENV === 'development') {
  var composeEnhancers = (typeof window === "undefined" ? "undefined" : (0, _typeof2["default"])(window)) === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : _redux.compose;
  enhancers = composeEnhancers(_redux.applyMiddleware.apply(void 0, middlewares));
} else {
  enhancers = _redux.applyMiddleware.apply(void 0, middlewares);
}
var store = exports.store = (0, _redux.createStore)((0, _modules.createRootReducer)(history), enhancers);
var persistor = exports.persistor = (0, _reduxPersist.persistStore)(store);
var Modules = exports.Modules = {
  amenity: {
    selectors: _modules.selectors
  }
};
var Actions = exports.Actions = _objectSpread({}, _modules.actions);
sagaMiddleware.run(_modules.sagas);