"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCheckbox = exports.HiddenCheckbox = exports.CheckboxText = exports.CheckboxContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var checkButtonBackground = function checkButtonBackground(_ref) {
  var checked = _ref.checked,
    disabled = _ref.disabled,
    theme = _ref.theme;
  if (checked) {
    return disabled ? theme.color.disabled : theme.color.primary["default"];
  } else {
    return 'transparent';
  }
};
var checkButtonOutline = function checkButtonOutline(_ref2) {
  var checked = _ref2.checked,
    disabled = _ref2.disabled,
    theme = _ref2.theme;
  if (checked) {
    return "3px solid ".concat(disabled ? theme.color.disabled : theme.color.primary["default"]);
  } else {
    return "1px solid ".concat(disabled ? theme.color.disabled : theme.color.black);
  }
};
var checkTextColor = function checkTextColor(_ref3) {
  var checked = _ref3.checked,
    disabled = _ref3.disabled,
    theme = _ref3.theme;
  if (disabled) {
    return theme.color.disabled;
  } else {
    return checked ? theme.color.primary["default"] : theme.color.black;
  }
};
var HiddenCheckbox = exports.HiddenCheckbox = _styledComponents["default"].input.attrs({
  type: 'checkbox'
})(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  // Hide checkbox visually but remain accessible to screen readers.\n  // Source: https://polished.js.org/docs/#hidevisually\n  border: 0;\n  clip: rect(0 0 0 0);\n  clippath: inset(50%);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n"])));
var StyledCheckbox = exports.StyledCheckbox = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  background: ", ";\n  border: 3px solid white;\n  outline: ", ";\n  border-radius: 4px;\n  transition: all ease 50ms;\n  margin-right: 12px;\n"])), function (props) {
  return checkButtonBackground(props);
}, function (props) {
  return checkButtonOutline(props);
});
var CheckboxContainer = exports.CheckboxContainer = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  display: inline-block;\n  vertical-align: middle;\n"])));
var CheckboxText = exports.CheckboxText = _styledComponents["default"].span(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-weight: ", ";\n  transition: all ease 50ms;\n"])), function (props) {
  return checkTextColor(props);
}, function (props) {
  return props.checked ? 'bold' : 'normal';
});