import { Avatar, DefaultTheme, Icon, withContextMenu } from '@qwkin/components';
import React from 'react';
import { NavbarText, UserContainer } from './styles';
import { useSelector } from 'react-redux';
import { extractUserDataFromState } from '../../utils';

const UserDropdownMenu = ({ ...props }) => {
  const {
    user: { profile },
  } = useSelector(extractUserDataFromState);

  return (
    <UserContainer {...props}>
      <Avatar
        s3Key={profile.userPhoto.thumbnail.s3Key}
        s3Bucket={profile.userPhoto.thumbnail.s3Bucket}
        size="45"
        style={{ border: `2px solid ${DefaultTheme.color.primary.default}` }}
      />
      <NavbarText className="userName" weight="bold">
        {profile.firstName}
      </NavbarText>
      <Icon
        icon="dropdown-down"
        size={20}
        color={DefaultTheme.color.primary.default}
        style={{
          marginLeft: '8px',
        }}
      />
    </UserContainer>
  );
};

export default withContextMenu(UserDropdownMenu);
