"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ChoiceChipsSelector", {
  enumerable: true,
  get: function get() {
    return _ChoiceChipsSelector["default"];
  }
});
var _ChoiceChipsSelector = _interopRequireDefault(require("./ChoiceChipsSelector"));