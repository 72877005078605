"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SAVE_PROPERTY_LINK_SUCCESS = exports.SAVE_PROPERTY_LINK_REQUEST = exports.SAVE_PROPERTY_LINK_FAIL = exports.REMOVE_ADMIN_FROM_CONDO_SUCCESS = exports.REMOVE_ADMIN_FROM_CONDO_REQUEST = exports.REMOVE_ADMIN_FROM_CONDO_FAIL = exports.GET_TENANT_CONDO_SUCCESS = exports.GET_TENANT_CONDO_REQUEST = exports.GET_TENANT_CONDO_FAIL = exports.GET_PROPERTY_LINKS_SUCCESS = exports.GET_PROPERTY_LINKS_REQUEST = exports.GET_PROPERTY_LINKS_ICONS_SUCCESS = exports.GET_PROPERTY_LINKS_ICONS_REQUEST = exports.GET_PROPERTY_LINKS_ICONS_FAIL = exports.GET_PROPERTY_LINKS_FAIL = exports.GET_CONDO_SUCCESS = exports.GET_CONDO_REQUEST = exports.GET_CONDO_FAIL = exports.EDIT_PROPERTY_LINK_SUCCESS = exports.EDIT_PROPERTY_LINK_REQUEST = exports.EDIT_PROPERTY_LINK_FAIL = exports.EDIT_DRAG_PROPERTY_LINK_SUCCESS = exports.EDIT_DRAG_PROPERTY_LINK_REQUEST = exports.EDIT_DRAG_PROPERTY_LINK_FAIL = exports.EDIT_CONDO_SUCCESS = exports.EDIT_CONDO_REQUEST = exports.EDIT_CONDO_FAIL = exports.EDIT_CONDO_CONFIGURATION_SUCCESS = exports.EDIT_CONDO_CONFIGURATION_REQUEST = exports.EDIT_CONDO_CONFIGURATION_FAIL = exports.DELETE_PROPERTY_LINK_SUCCESS = exports.DELETE_PROPERTY_LINK_REQUEST = exports.DELETE_PROPERTY_LINK_FAIL = void 0;
var EDIT_CONDO_REQUEST = exports.EDIT_CONDO_REQUEST = '@Condominium/EDIT_CONDO_REQUEST';
var EDIT_CONDO_SUCCESS = exports.EDIT_CONDO_SUCCESS = '@Condominium/EDIT_CONDO_SUCCESS';
var EDIT_CONDO_FAIL = exports.EDIT_CONDO_FAIL = '@Condominium/EDIT_CONDO_FAIL';
var EDIT_CONDO_CONFIGURATION_REQUEST = exports.EDIT_CONDO_CONFIGURATION_REQUEST = '@Condominium/EDIT_CONDO_CONFIGURATION_REQUEST';
var EDIT_CONDO_CONFIGURATION_SUCCESS = exports.EDIT_CONDO_CONFIGURATION_SUCCESS = '@Condominium/EDIT_CONDO_CONFIGURATION_SUCCESS';
var EDIT_CONDO_CONFIGURATION_FAIL = exports.EDIT_CONDO_CONFIGURATION_FAIL = '@Condominium/EDIT_CONDO_CONFIGURATION_FAIL';
var GET_CONDO_REQUEST = exports.GET_CONDO_REQUEST = '@Condominium/GET_CONDO_REQUEST';
var GET_CONDO_SUCCESS = exports.GET_CONDO_SUCCESS = '@Condominium/GET_CONDO_SUCCESS';
var GET_CONDO_FAIL = exports.GET_CONDO_FAIL = '@Condominium/GET_CONDO_FAIL';
var REMOVE_ADMIN_FROM_CONDO_REQUEST = exports.REMOVE_ADMIN_FROM_CONDO_REQUEST = '@Condominium/REMOVE_ADMIN_FROM_CONDO_REQUEST';
var REMOVE_ADMIN_FROM_CONDO_SUCCESS = exports.REMOVE_ADMIN_FROM_CONDO_SUCCESS = '@Condominium/REMOVE_ADMIN_FROM_CONDO_SUCCESS';
var REMOVE_ADMIN_FROM_CONDO_FAIL = exports.REMOVE_ADMIN_FROM_CONDO_FAIL = '@Condominium/REMOVE_ADMIN_FROM_CONDO_FAIL';
var GET_TENANT_CONDO_REQUEST = exports.GET_TENANT_CONDO_REQUEST = '@Condominium/GET_TENANT_CONDO_REQUEST';
var GET_TENANT_CONDO_SUCCESS = exports.GET_TENANT_CONDO_SUCCESS = '@Condominium/GET_TENANT_CONDO_SUCCESS';
var GET_TENANT_CONDO_FAIL = exports.GET_TENANT_CONDO_FAIL = '@Condominium/GET_TENANT_CONDO_FAIL';
var GET_PROPERTY_LINKS_ICONS_REQUEST = exports.GET_PROPERTY_LINKS_ICONS_REQUEST = '@Condominium/GET_PROPERTY_LINKS_ICONS_REQUEST';
var GET_PROPERTY_LINKS_ICONS_SUCCESS = exports.GET_PROPERTY_LINKS_ICONS_SUCCESS = '@Condominium/GET_PROPERTY_LINKS_ICONS_SUCCESS';
var GET_PROPERTY_LINKS_ICONS_FAIL = exports.GET_PROPERTY_LINKS_ICONS_FAIL = '@Condominium/GET_PROPERTY_LINKS_ICONS_FAIL';
var GET_PROPERTY_LINKS_REQUEST = exports.GET_PROPERTY_LINKS_REQUEST = '@Condominium/GET_PROPERTY_LINKS_REQUEST';
var GET_PROPERTY_LINKS_SUCCESS = exports.GET_PROPERTY_LINKS_SUCCESS = '@Condominium/GET_PROPERTY_LINKS_SUCCESS';
var GET_PROPERTY_LINKS_FAIL = exports.GET_PROPERTY_LINKS_FAIL = '@Condominium/GET_PROPERTY_LINKS_FAIL';
var SAVE_PROPERTY_LINK_REQUEST = exports.SAVE_PROPERTY_LINK_REQUEST = '@Condominium/SAVE_PROPERTY_LINK_REQUEST';
var SAVE_PROPERTY_LINK_SUCCESS = exports.SAVE_PROPERTY_LINK_SUCCESS = '@Condominium/SAVE_PROPERTY_LINK_SUCCESS';
var SAVE_PROPERTY_LINK_FAIL = exports.SAVE_PROPERTY_LINK_FAIL = '@Condominium/SAVE_PROPERTY_LINK_FAIL';
var EDIT_PROPERTY_LINK_REQUEST = exports.EDIT_PROPERTY_LINK_REQUEST = '@Condominium/EDIT_PROPERTY_LINK_REQUEST';
var EDIT_PROPERTY_LINK_SUCCESS = exports.EDIT_PROPERTY_LINK_SUCCESS = '@Condominium/EDIT_PROPERTY_LINK_SUCCESS';
var EDIT_PROPERTY_LINK_FAIL = exports.EDIT_PROPERTY_LINK_FAIL = '@Condominium/EDIT_PROPERTY_LINK_FAIL';
var EDIT_DRAG_PROPERTY_LINK_REQUEST = exports.EDIT_DRAG_PROPERTY_LINK_REQUEST = '@Condominium/EDIT_DRAG_PROPERTY_LINK_REQUEST';
var EDIT_DRAG_PROPERTY_LINK_SUCCESS = exports.EDIT_DRAG_PROPERTY_LINK_SUCCESS = '@Condominium/EDIT_DRAG_PROPERTY_LINK_SUCCESS';
var EDIT_DRAG_PROPERTY_LINK_FAIL = exports.EDIT_DRAG_PROPERTY_LINK_FAIL = '@Condominium/EDIT_DRAG_PROPERTY_LINK_FAIL';
var DELETE_PROPERTY_LINK_REQUEST = exports.DELETE_PROPERTY_LINK_REQUEST = '@Condominium/DELETE_PROPERTY_LINK_REQUEST';
var DELETE_PROPERTY_LINK_SUCCESS = exports.DELETE_PROPERTY_LINK_SUCCESS = '@Condominium/DELETE_PROPERTY_LINK_SUCCESS';
var DELETE_PROPERTY_LINK_FAIL = exports.DELETE_PROPERTY_LINK_FAIL = '@Condominium/DELETE_PROPERTY_LINK_FAIL';