"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.S3_SECRET_KEY = exports.S3_REGION = exports.S3_ACCESS_KEY = exports.PORT = exports.ORIGIN = exports.API_PATH = exports.API_HOST = void 0;
var ORIGIN = exports.ORIGIN = process.env.REACT_APP_ORIGIN;
var PORT = exports.PORT = process.env.REACT_APP_PORT;
var API_HOST = exports.API_HOST = process.env.REACT_APP_API_HOST;
var API_PATH = exports.API_PATH = process.env.REACT_APP_API_PATH;
var S3_ACCESS_KEY = exports.S3_ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
var S3_SECRET_KEY = exports.S3_SECRET_KEY = process.env.REACT_APP_S3_SECRET_KEY;
var S3_REGION = exports.S3_REGION = process.env.REACT_APP_S3_REGION;