"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Initials = exports.AvatarContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _functions = require("../utils/functions");
var _templateObject, _templateObject2;
var AvatarContainer = exports.AvatarContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n  max-height: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n  overflow: hidden;\n\n  .photoAvatar {\n    min-height: 100%;\n    max-width: 100%;\n    border-radius: ", ";\n  }\n\n  img {\n    position: absolute;\n    object-fit: cover;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    margin: auto;\n  }\n"])), function (p) {
  return "".concat(p.size, "px");
}, function (p) {
  return "".concat(p.size, "px");
}, function (p) {
  return "".concat(p.size, "px");
}, function (p) {
  return "".concat(p.size, "px");
}, function (p) {
  var _p$borderRadius;
  return "".concat((_p$borderRadius = p.borderRadius) !== null && _p$borderRadius !== void 0 ? _p$borderRadius : '50%');
}, function (p) {
  return (0, _functions.getBoxShadowByElevation)(p);
}, function (p) {
  var _p$borderRadius2;
  return "".concat((_p$borderRadius2 = p.borderRadius) !== null && _p$borderRadius2 !== void 0 ? _p$borderRadius2 : '50%');
});
var Initials = exports.Initials = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  background: ", ";\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n"])), function (p) {
  return p.theme.color.primary.translucid;
});