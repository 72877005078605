import React from 'react';
import { DefaultTheme } from '@qwkin/components';
import { identity } from '@qwkin/helpers';
import { MenuText, SideMenuItem, SidemenuIcon } from './styles';

const SideMenuItemBuilder = ({ title, icon, onClick = identity, active }) => {
  return (
    <SideMenuItem className={active ? 'active' : ''} theme={DefaultTheme} onClick={onClick}>
      <SidemenuIcon icon={icon} color={DefaultTheme.color.primary.default} />
      <MenuText type="small" color={DefaultTheme.color.primary.default}>
        {title}
      </MenuText>
    </SideMenuItem>
  );
};

export default SideMenuItemBuilder;
