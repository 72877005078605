"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildActionResponse = void 0;
var _helpers = require("@qwkin/helpers");
var buildActionResponse = exports.buildActionResponse = function buildActionResponse(type, payload) {
  var meta = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    type: type,
    payload: payload,
    meta: Object.assign({
      callback: _helpers.identity
    }, meta)
  };
};