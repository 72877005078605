"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_STAFF_NOTES_SUCCESS = exports.LIST_STAFF_NOTES_REQUEST = exports.LIST_STAFF_NOTES_FAIL = exports.GET_STAFF_NOTE_SUCCESS = exports.GET_STAFF_NOTE_REQUEST = exports.GET_STAFF_NOTE_FAIL = exports.EXPIRE_STAFF_NOTE_SUCCESS = exports.EXPIRE_STAFF_NOTE_REQUEST = exports.EXPIRE_STAFF_NOTE_FAIL = exports.EDIT_STAFF_NOTE_SUCCESS = exports.EDIT_STAFF_NOTE_REQUEST = exports.EDIT_STAFF_NOTE_FAIL = exports.DELETE_STAFF_NOTE_SUCCESS = exports.DELETE_STAFF_NOTE_REQUEST = exports.DELETE_STAFF_NOTE_FAIL = exports.CREATE_STAFF_NOTE_SUCCESS = exports.CREATE_STAFF_NOTE_REQUEST = exports.CREATE_STAFF_NOTE_FAIL = void 0;
var LIST_STAFF_NOTES_REQUEST = exports.LIST_STAFF_NOTES_REQUEST = '@StaffNote/LIST_STAFF_NOTES_REQUEST';
var LIST_STAFF_NOTES_SUCCESS = exports.LIST_STAFF_NOTES_SUCCESS = '@StaffNote/LIST_STAFF_NOTES_SUCCESS';
var LIST_STAFF_NOTES_FAIL = exports.LIST_STAFF_NOTES_FAIL = '@StaffNote/LIST_STAFF_NOTES_FAIL';
var GET_STAFF_NOTE_REQUEST = exports.GET_STAFF_NOTE_REQUEST = '@StaffNote/GET_STAFF_NOTE_REQUEST';
var GET_STAFF_NOTE_SUCCESS = exports.GET_STAFF_NOTE_SUCCESS = '@StaffNote/GET_STAFF_NOTE_SUCCESS';
var GET_STAFF_NOTE_FAIL = exports.GET_STAFF_NOTE_FAIL = '@StaffNote/GET_STAFF_NOTE_FAIL';
var DELETE_STAFF_NOTE_REQUEST = exports.DELETE_STAFF_NOTE_REQUEST = '@StaffNote/DELETE_STAFF_NOTE_REQUEST';
var DELETE_STAFF_NOTE_SUCCESS = exports.DELETE_STAFF_NOTE_SUCCESS = '@StaffNote/DELETE_STAFF_NOTE_SUCCESS';
var DELETE_STAFF_NOTE_FAIL = exports.DELETE_STAFF_NOTE_FAIL = '@StaffNote/DELETE_STAFF_NOTE_FAIL';
var EDIT_STAFF_NOTE_REQUEST = exports.EDIT_STAFF_NOTE_REQUEST = '@StaffNote/EDIT_STAFF_NOTE_REQUEST';
var EDIT_STAFF_NOTE_SUCCESS = exports.EDIT_STAFF_NOTE_SUCCESS = '@StaffNote/EDIT_STAFF_NOTE_SUCCESS';
var EDIT_STAFF_NOTE_FAIL = exports.EDIT_STAFF_NOTE_FAIL = '@StaffNote/EDIT_STAFF_NOTE_FAIL';
var EXPIRE_STAFF_NOTE_REQUEST = exports.EXPIRE_STAFF_NOTE_REQUEST = '@StaffNote/EXPIRE_STAFF_NOTE_REQUEST';
var EXPIRE_STAFF_NOTE_SUCCESS = exports.EXPIRE_STAFF_NOTE_SUCCESS = '@StaffNote/EXPIRE_STAFF_NOTE_SUCCESS';
var EXPIRE_STAFF_NOTE_FAIL = exports.EXPIRE_STAFF_NOTE_FAIL = '@StaffNote/EXPIRE_STAFF_NOTE_FAIL';
var CREATE_STAFF_NOTE_REQUEST = exports.CREATE_STAFF_NOTE_REQUEST = '@StaffNote/CREATE_STAFF_NOTE_REQUEST';
var CREATE_STAFF_NOTE_SUCCESS = exports.CREATE_STAFF_NOTE_SUCCESS = '@StaffNote/CREATE_STAFF_NOTE_SUCCESS';
var CREATE_STAFF_NOTE_FAIL = exports.CREATE_STAFF_NOTE_FAIL = '@StaffNote/CREATE_STAFF_NOTE_FAIL';