// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: All analytics features were commented. Please uncomment the following lines to use them.
// import { getAnalytics } from 'firebase/analytics';

import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { isStringNullOrEmpty } from '@qwkin/string';
import { identity } from '@qwkin/helpers';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD-5mKmkRqIAcVzk8EYrKaCAj__mcN5Fw0',
  authDomain: 'qwkin-web-admin.firebaseapp.com',
  projectId: 'qwkin-web-admin',
  storageBucket: 'qwkin-web-admin.appspot.com',
  messagingSenderId: '558812731003',
  appId: '1:558812731003:web:9d3c4f0d5b4af4fb0874de',
  measurementId: 'G-4659PWY5XT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firebaseIsSupported = async () => await isSupported();

let messaging = null;

(async () => {
  if (await firebaseIsSupported()) {
    messaging = getMessaging(app);
  }
})();

// eslint-disable-next-line no-unused-vars
// TODO: All analytics features were commented. Please uncomment the following lines to use them.
// const analytics = getAnalytics(app);

export const getTokenFromFCM = (setToken, setError = identity) => {
  return getToken(messaging, {
    vapidKey:
      'BBhNOgLM1xhiVOfvntY_geUOiR1X37UEv1qDuMDSO4pv8_pVAPzTf36-SUVjeKnfBQuP-daohW0YzkuPt8jEEto',
  })
    .then(token => {
      if (!isStringNullOrEmpty(token)) {
        return setToken(token);
      }

      return setToken(null);
    })
    .catch(error => {
      setError(error);
      setToken(null);
    });
};

export const onMessageListener = () => {
  return new Promise(resolve => {
    if (messaging) {
      onMessage(messaging, payload => {
        resolve(payload);
      });
    }
    return true;
  });
};
