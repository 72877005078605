"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_TAGS_SUCCESS = exports.LIST_TAGS_REQUEST = exports.LIST_TAGS_FAIL = exports.LIST_CATEGORIES_SUCCESS = exports.LIST_CATEGORIES_REQUEST = exports.LIST_CATEGORIES_FAIL = exports.EDIT_CLASS_SUCCESS = exports.EDIT_CLASS_REQUEST = exports.EDIT_CLASS_FAIL = exports.DELETE_CLASS_SUCCESS = exports.DELETE_CLASS_REQUEST = exports.DELETE_CLASS_FAIL = exports.CREATE_CLASS_SUCCESS = exports.CREATE_CLASS_REQUEST = exports.CREATE_CLASS_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var CREATE_CLASS_REQUEST = exports.CREATE_CLASS_REQUEST = '@Class/CREATE_CLASS_REQUEST';
var CREATE_CLASS_SUCCESS = exports.CREATE_CLASS_SUCCESS = '@Class/CREATE_CLASS_SUCCESS';
var CREATE_CLASS_FAIL = exports.CREATE_CLASS_FAIL = '@Class/CREATE_CLASS_FAIL';
var LIST_CATEGORIES_REQUEST = exports.LIST_CATEGORIES_REQUEST = '@Class/LIST_CATEGORIES_REQUEST';
var LIST_CATEGORIES_SUCCESS = exports.LIST_CATEGORIES_SUCCESS = '@Class/LIST_CATEGORIES_SUCCESS';
var LIST_CATEGORIES_FAIL = exports.LIST_CATEGORIES_FAIL = '@Class/LIST_CATEGORIES_FAIL';
var LIST_TAGS_REQUEST = exports.LIST_TAGS_REQUEST = '@Class/LIST_TAGS_REQUEST';
var LIST_TAGS_SUCCESS = exports.LIST_TAGS_SUCCESS = '@Class/LIST_TAGS_SUCCESS';
var LIST_TAGS_FAIL = exports.LIST_TAGS_FAIL = '@Class/LIST_TAGS_FAIL';
var DELETE_CLASS_REQUEST = exports.DELETE_CLASS_REQUEST = '@Class/DELETE_CLASS_REQUEST';
var DELETE_CLASS_SUCCESS = exports.DELETE_CLASS_SUCCESS = '@Class/DELETE_CLASS_SUCCESS';
var DELETE_CLASS_FAIL = exports.DELETE_CLASS_FAIL = '@Class/DELETE_CLASS_FAIL';
var EDIT_CLASS_REQUEST = exports.EDIT_CLASS_REQUEST = '@Class/EDIT_CLASS_REQUEST';
var EDIT_CLASS_SUCCESS = exports.EDIT_CLASS_SUCCESS = '@Class/EDIT_CLASS_SUCCESS';
var EDIT_CLASS_FAIL = exports.EDIT_CLASS_FAIL = '@Class/EDIT_CLASS_FAIL';