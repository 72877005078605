"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestUnregisterPush = exports.requestRegisterPush = void 0;
var _variables = require("../variables");
var _utils = require("../../../utils");
var requestRegisterPush = exports.requestRegisterPush = function requestRegisterPush(payload, meta) {
  return (0, _utils.buildAction)(_variables.REGISTER_PUSH_REQUEST, payload, meta);
};
var requestUnregisterPush = exports.requestUnregisterPush = function requestUnregisterPush(payload, meta) {
  return (0, _utils.buildAction)(_variables.UNREGISTER_PUSH_REQUEST, payload, meta);
};