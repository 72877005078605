"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNREGISTER_PUSH_SUCCESS = exports.UNREGISTER_PUSH_REQUEST = exports.UNREGISTER_PUSH_FAILURE = exports.REGISTER_PUSH_SUCCESS = exports.REGISTER_PUSH_REQUEST = exports.REGISTER_PUSH_FAILURE = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var REGISTER_PUSH_REQUEST = exports.REGISTER_PUSH_REQUEST = '@PushNotification/REGISTER_PUSH_REQUEST';
var REGISTER_PUSH_SUCCESS = exports.REGISTER_PUSH_SUCCESS = '@PushNotification/REGISTER_PUSH_SUCCESS';
var REGISTER_PUSH_FAILURE = exports.REGISTER_PUSH_FAILURE = '@PushNotification/REGISTER_PUSH_FAILURE';
var UNREGISTER_PUSH_REQUEST = exports.UNREGISTER_PUSH_REQUEST = '@PushNotification/UNREGISTER_PUSH_REQUEST';
var UNREGISTER_PUSH_SUCCESS = exports.UNREGISTER_PUSH_SUCCESS = '@PushNotification/UNREGISTER_PUSH_SUCCESS';
var UNREGISTER_PUSH_FAILURE = exports.UNREGISTER_PUSH_FAILURE = '@PushNotification/UNREGISTER_PUSH_FAILURE';