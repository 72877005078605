import React from 'react';
import { CondoImage, CondoInfoContainer } from './styles';
import logo_full from '../../assets/livvie_blue.png';

const CondoInfo = ({ condo }) => {
  return condo && condo.guestPhoto ? (
    <CondoInfoContainer>
      <CondoImage
        s3Key={condo.guestPhoto.thumbnail.s3Key}
        s3Bucket={condo.guestPhoto.thumbnail.s3Bucket}
        size="40"
        placeholder={logo_full}
      />
    </CondoInfoContainer>
  ) : null;
};

export default CondoInfo;
