"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ResponsiveImage", {
  enumerable: true,
  get: function get() {
    return _ResponsiveImage["default"];
  }
});
var _ResponsiveImage = _interopRequireDefault(require("./ResponsiveImage"));