"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagsFormFieldContainer = exports.TagsCard = exports.TagCard = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = require("../utils/theme");
var _templateObject, _templateObject2, _templateObject3;
var TagsFormFieldContainer = exports.TagsFormFieldContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n"])));
var TagsCard = exports.TagsCard = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  overflow-x: auto;\n  gap: 8px;\n"])));
var TagCard = exports.TagCard = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  background-color: ", ";\n  border-radius: 10px;\n  padding: 4px;\n  color: ", ";\n  gap: 8px;\n"])), function (_ref) {
  var disabled = _ref.disabled;
  return disabled ? '#EDEDED' : _theme.defaultTheme.color.primary.clear;
}, function (_ref2) {
  var disabled = _ref2.disabled;
  return disabled ? _theme.defaultTheme.color.gray : _theme.defaultTheme.color.primary["default"];
});