"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestSavePropertyLink = exports.requestRemoveAdminFromCondo = exports.requestGetTenantCondo = exports.requestGetPropertyLinksIcons = exports.requestGetPropertyLinks = exports.requestGetCondo = exports.requestEditPropertyLink = exports.requestEditDragPropertyLink = exports.requestEditCondoConfiguration = exports.requestEditCondo = exports.requestDeletePropertyLink = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestEditCondo = exports.requestEditCondo = function requestEditCondo(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_CONDO_REQUEST, payload, meta);
};
var requestEditCondoConfiguration = exports.requestEditCondoConfiguration = function requestEditCondoConfiguration(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_CONDO_CONFIGURATION_REQUEST, payload, meta);
};
var requestGetCondo = exports.requestGetCondo = function requestGetCondo(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_CONDO_REQUEST, payload, meta);
};
var requestRemoveAdminFromCondo = exports.requestRemoveAdminFromCondo = function requestRemoveAdminFromCondo(payload, meta) {
  return (0, _utils.buildAction)(_variables.REMOVE_ADMIN_FROM_CONDO_REQUEST, payload, meta);
};
var requestGetTenantCondo = exports.requestGetTenantCondo = function requestGetTenantCondo(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_TENANT_CONDO_REQUEST, payload, meta);
};
var requestGetPropertyLinks = exports.requestGetPropertyLinks = function requestGetPropertyLinks(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_PROPERTY_LINKS_REQUEST, payload, meta);
};
var requestGetPropertyLinksIcons = exports.requestGetPropertyLinksIcons = function requestGetPropertyLinksIcons(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_PROPERTY_LINKS_ICONS_REQUEST, payload, meta);
};
var requestSavePropertyLink = exports.requestSavePropertyLink = function requestSavePropertyLink(payload, meta) {
  return (0, _utils.buildAction)(_variables.SAVE_PROPERTY_LINK_REQUEST, payload, meta);
};
var requestEditPropertyLink = exports.requestEditPropertyLink = function requestEditPropertyLink(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_PROPERTY_LINK_REQUEST, payload, meta);
};
var requestEditDragPropertyLink = exports.requestEditDragPropertyLink = function requestEditDragPropertyLink(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_DRAG_PROPERTY_LINK_REQUEST, payload, meta);
};
var requestDeletePropertyLink = exports.requestDeletePropertyLink = function requestDeletePropertyLink(payload, meta) {
  return (0, _utils.buildAction)(_variables.DELETE_PROPERTY_LINK_REQUEST, payload, meta);
};