"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  error: false,
  errorUpcomingNotices: false,
  errorExpiredNotices: false,
  loadingCreateNotice: false,
  loadingCreateBulletin: false,
  loadingGetNotice: false,
  loadingGetBulletin: false,
  loadingListBulletins: false,
  loadingListBulletinsRequests: false,
  loadingList: false,
  loadingUpcomingNoticesList: false,
  loadingExpiredNoticesList: false,
  loadingApproveBulletin: false,
  loadingDenyBulletin: false,
  loadingCancel: false,
  notice: {},
  bulletin: {},
  listBulletinsRequests: [],
  listBulletins: [],
  listNotices: [],
  listNoticesDrafts: [],
  listUpcomingNotices: [],
  listExpiredNotices: [],
  listCount: 0,
  listPages: 0,
  listUpcomingNoticesCount: 0,
  listUpcomingNoticesPages: 0,
  listExpiredNoticesCount: 0,
  listExpiredNoticesPages: 0,
  listBulletinsCount: 0,
  listBulletinsPages: 0,
  listBulletinsRequestsCount: 0,
  listBulletinsRequestsPages: 0
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload2, _action$payload$data2, _action$payload4, _action$payload$data3, _action$payload5, _action$payload$data4, _action$payload6, _action$payload$data5, _action$payload7, _action$payload$data6, _action$payload8, _action$payload$data7, _action$payload9, _action$payload$data8, _action$payload10, _action$payload$data9, _action$payload11, _action$payload$data10, _action$payload12, _action$payload$data11, _action$payload13, _action$payload$data12, _action$payload14, _action$payload$data13, _action$payload15, _action$payload$data14, _action$payload16, _action$payload$data15, _action$payload17, _action$payload$data16, _action$payload18, _action$payload$data17, _action$payload19, _action$payload$data18, _action$payload20, _action$payload$data19, _action$payload21, _action$payload$data20, _action$payload22, _action$payload$data21, _action$payload23, _action$payload$data22, _action$payload24, _action$payload$data23, _action$payload25, _action$payload$data24, _action$payload26, _action$payload$data25, _action$payload27;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.CLEAR_NOTICE_DRAFT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        listNoticesDrafts: []
      });
    case _variables.CREATE_NOTICE_DRAFT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: false
      });
    case _variables.CREATE_NOTICE_DRAFT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: false,
        listNoticesDrafts: [].concat((0, _toConsumableArray2["default"])(state.listNoticesDrafts), [action.payload])
      });
    case _variables.CREATE_NOTICE_DRAFT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.payload
      });
    case _variables.EDIT_NOTICE_DRAFT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: false
      });
    case _variables.EDIT_NOTICE_DRAFT_SUCCESS:
      var idx = state.listNoticesDrafts.findIndex(function (d) {
        var _action$payload;
        return (d === null || d === void 0 ? void 0 : d.draftId) === (action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.draftId);
      });
      state.listNoticesDrafts[idx] = action.payload;
      return _objectSpread(_objectSpread({}, state), {}, {
        error: false,
        listNoticesDrafts: (0, _toConsumableArray2["default"])(state.listNoticesDrafts)
      });
    case _variables.EDIT_NOTICE_DRAFT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: (_action$payload$data = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.DELETE_NOTICE_DRAFT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: false
      });
    case _variables.DELETE_NOTICE_DRAFT_SUCCESS:
      var deleteIdx = state.listNoticesDrafts.findIndex(function (d) {
        var _action$payload3;
        return (d === null || d === void 0 ? void 0 : d.draftId) === (action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.draftId);
      });
      state.listNoticesDrafts.splice(deleteIdx, 1);
      return _objectSpread(_objectSpread({}, state), {}, {
        error: false,
        listNoticesDrafts: (0, _toConsumableArray2["default"])(state.listNoticesDrafts)
      });
    case _variables.DELETE_NOTICE_DRAFT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: (_action$payload$data2 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.CANCEL_HANDOUT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCancel: true,
        error: false
      });
    case _variables.CANCEL_HANDOUT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCancel: false,
        error: false
      });
    case _variables.CANCEL_HANDOUT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCancel: false,
        error: (_action$payload$data3 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {}
      });
    case _variables.DENY_BULLETIN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDenyBulletin: true,
        error: false
      });
    case _variables.DENY_BULLETIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDenyBulletin: false,
        error: false,
        bulletin: (_action$payload$data4 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.DENY_BULLETIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDenyBulletin: false,
        error: (_action$payload$data5 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : {}
      });
    case _variables.APPROVE_BULLETIN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingApproveBulletin: true,
        error: false
      });
    case _variables.APPROVE_BULLETIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingApproveBulletin: false,
        error: false,
        bulletin: (_action$payload$data6 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {}
      });
    case _variables.APPROVE_BULLETIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingApproveBulletin: false,
        error: (_action$payload$data7 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : {}
      });
    case _variables.CREATE_BULLETIN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateBulletin: true,
        error: false
      });
    case _variables.CREATE_BULLETIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateBulletin: false,
        error: false,
        bulletin: (_action$payload$data8 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}
      });
    case _variables.CREATE_BULLETIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateBulletin: false,
        error: (_action$payload$data9 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : {}
      });
    case _variables.CREATE_NOTICE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateNotice: true,
        error: false
      });
    case _variables.CREATE_NOTICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateNotice: false,
        error: false,
        notice: (_action$payload$data10 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}
      });
    case _variables.CREATE_NOTICE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateNotice: false,
        error: (_action$payload$data11 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : {}
      });
    case _variables.GET_BULLETIN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetBulletin: true,
        error: false
      });
    case _variables.GET_BULLETIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetBulletin: false,
        error: false,
        bulletin: (_action$payload$data12 = action === null || action === void 0 || (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {}
      });
    case _variables.GET_BULLETIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetBulletin: false,
        error: (_action$payload$data13 = action === null || action === void 0 || (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.data) !== null && _action$payload$data13 !== void 0 ? _action$payload$data13 : {}
      });
    case _variables.GET_NOTICE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetNotice: true,
        error: false
      });
    case _variables.GET_NOTICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetNotice: false,
        error: false,
        notice: (_action$payload$data14 = action === null || action === void 0 || (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.data) !== null && _action$payload$data14 !== void 0 ? _action$payload$data14 : {}
      });
    case _variables.GET_NOTICE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetNotice: false,
        error: (_action$payload$data15 = action === null || action === void 0 || (_action$payload17 = action.payload) === null || _action$payload17 === void 0 ? void 0 : _action$payload17.data) !== null && _action$payload$data15 !== void 0 ? _action$payload$data15 : {}
      });
    case _variables.LIST_BULLETINS_REQUESTS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListBulletinsRequests: true,
        error: false
      });
    case _variables.LIST_BULLETINS_REQUESTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListBulletinsRequests: false,
        error: false,
        listBulletinsRequests: (_action$payload$data16 = action === null || action === void 0 || (_action$payload18 = action.payload) === null || _action$payload18 === void 0 ? void 0 : _action$payload18.data) !== null && _action$payload$data16 !== void 0 ? _action$payload$data16 : [],
        listBulletinsRequestsCount: action.payload.headers['total-count'],
        listBulletinsRequestsPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_BULLETINS_REQUESTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListBulletinsRequests: false,
        error: (_action$payload$data17 = action === null || action === void 0 || (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : _action$payload19.data) !== null && _action$payload$data17 !== void 0 ? _action$payload$data17 : {}
      });
    case _variables.LIST_BULLETINS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListBulletins: true,
        error: false
      });
    case _variables.LIST_BULLETINS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListBulletins: false,
        error: false,
        listBulletins: (_action$payload$data18 = action === null || action === void 0 || (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.data) !== null && _action$payload$data18 !== void 0 ? _action$payload$data18 : [],
        listBulletinsCount: action.payload.headers['total-count'],
        listBulletinsPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_BULLETINS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListBulletins: false,
        error: (_action$payload$data19 = action === null || action === void 0 || (_action$payload21 = action.payload) === null || _action$payload21 === void 0 ? void 0 : _action$payload21.data) !== null && _action$payload$data19 !== void 0 ? _action$payload$data19 : {}
      });
    case _variables.LIST_NOTICES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: true,
        error: false
      });
    case _variables.LIST_NOTICES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: false,
        error: false,
        listNotices: (_action$payload$data20 = action === null || action === void 0 || (_action$payload22 = action.payload) === null || _action$payload22 === void 0 ? void 0 : _action$payload22.data) !== null && _action$payload$data20 !== void 0 ? _action$payload$data20 : [],
        listCount: action.payload.headers['total-count'],
        listPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_NOTICES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: false,
        error: (_action$payload$data21 = action === null || action === void 0 || (_action$payload23 = action.payload) === null || _action$payload23 === void 0 ? void 0 : _action$payload23.data) !== null && _action$payload$data21 !== void 0 ? _action$payload$data21 : {}
      });
    case _variables.LIST_EXPIRED_NOTICES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExpiredNoticesList: true,
        errorExpiredNotices: false
      });
    case _variables.LIST_EXPIRED_NOTICES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExpiredNoticesList: false,
        errorExpiredNotices: false,
        listExpiredNotices: (_action$payload$data22 = action === null || action === void 0 || (_action$payload24 = action.payload) === null || _action$payload24 === void 0 ? void 0 : _action$payload24.data) !== null && _action$payload$data22 !== void 0 ? _action$payload$data22 : [],
        listExpiredNoticesCount: action.payload.headers['total-count'],
        listExpiredNoticesPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_EXPIRED_NOTICES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExpiredNoticesList: false,
        errorExpiredNotices: (_action$payload$data23 = action === null || action === void 0 || (_action$payload25 = action.payload) === null || _action$payload25 === void 0 ? void 0 : _action$payload25.data) !== null && _action$payload$data23 !== void 0 ? _action$payload$data23 : {}
      });
    case _variables.LIST_UPCOMING_NOTICES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUpcomingNoticesList: true,
        errorUpcomingNotices: false
      });
    case _variables.LIST_UPCOMING_NOTICES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUpcomingNoticesList: false,
        errorUpcomingNotices: false,
        listUpcomingNotices: (_action$payload$data24 = action === null || action === void 0 || (_action$payload26 = action.payload) === null || _action$payload26 === void 0 ? void 0 : _action$payload26.data) !== null && _action$payload$data24 !== void 0 ? _action$payload$data24 : [],
        listUpcomingNoticesCount: action.payload.headers['total-count'],
        listUpcomingNoticesPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_UPCOMING_NOTICES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUpcomingNoticesList: false,
        errorUpcomingNotices: (_action$payload$data25 = action === null || action === void 0 || (_action$payload27 = action.payload) === null || _action$payload27 === void 0 ? void 0 : _action$payload27.data) !== null && _action$payload$data25 !== void 0 ? _action$payload$data25 : {}
      });
    default:
      return state;
  }
};