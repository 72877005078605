"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  getUserByPhoneLoading: false,
  getUserByPhoneError: null,
  getUserByPhoneOrEmailLoading: false,
  getUserByPhoneOrEmailError: null,
  createAdminLoading: false,
  createAdminError: null,
  createUserLoading: false,
  createUserError: null,
  editAdminLoading: false,
  editAdminError: null,
  employeesLoading: false,
  employeesError: null,
  user: {},
  users: [],
  employees: []
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data9, _action$payload9, _action$payload$data10, _action$payload10, _action$payload$data11, _action$payload11, _action$payload$data12, _action$payload12;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.CREATE_ADMIN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        createAdminLoading: true,
        createAdminError: null
      });
    case _variables.CREATE_ADMIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        createAdminLoading: false,
        createAdminError: null,
        user: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.CREATE_ADMIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        createAdminLoading: false,
        createAdminError: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.CREATE_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        createUserLoading: true,
        createUserError: null
      });
    case _variables.CREATE_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        createUserLoading: false,
        createUserError: null,
        user: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {}
      });
    case _variables.CREATE_USER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        createUserLoading: false,
        createUserError: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.EDIT_ADMIN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        editAdminLoading: true,
        editAdminError: null
      });
    case _variables.EDIT_ADMIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        editAdminLoading: false,
        editAdminError: null,
        user: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : {}
      });
    case _variables.EDIT_ADMIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        editAdminLoading: false,
        editAdminError: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {}
      });
    case _variables.GET_USER_BY_PHONE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        getUserByPhoneLoading: true,
        getUserByPhoneError: null
      });
    case _variables.GET_USER_BY_PHONE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        getUserByPhoneLoading: false,
        getUserByPhoneError: null,
        users: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : []
      });
    case _variables.GET_USER_BY_PHONE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        getUserByPhoneLoading: false,
        getUserByPhoneError: (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}
      });
    case _variables.GET_USER_BY_PHONE_OR_EMAIL_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        getUserByPhoneOrEmailLoading: true,
        getUserByPhoneOrEmailError: null
      });
    case _variables.GET_USER_BY_PHONE_OR_EMAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        getUserByPhoneOrEmailLoading: false,
        getUserByPhoneOrEmailError: null,
        users: (_action$payload$data9 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : []
      });
    case _variables.GET_USER_BY_PHONE_OR_EMAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        getUserByPhoneOrEmailLoading: false,
        getUserByPhoneOrEmailError: (_action$payload$data10 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}
      });
    case _variables.GET_EMPLOYEES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        getEmployeesLoading: true,
        getEmployeesError: null
      });
    case _variables.GET_EMPLOYEES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        getEmployeesLoading: false,
        getEmployeesError: null,
        employees: (_action$payload$data11 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : []
      });
    case _variables.GET_EMPLOYEES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        getEmployeesLoading: false,
        getEmployeesError: (_action$payload$data12 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {}
      });
    default:
      return state;
  }
};