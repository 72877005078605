"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_UPCOMING_NOTICES_SUCCESS = exports.LIST_UPCOMING_NOTICES_REQUEST = exports.LIST_UPCOMING_NOTICES_FAIL = exports.LIST_NOTICES_SUCCESS = exports.LIST_NOTICES_REQUEST = exports.LIST_NOTICES_FAIL = exports.LIST_EXPIRED_NOTICES_SUCCESS = exports.LIST_EXPIRED_NOTICES_REQUEST = exports.LIST_EXPIRED_NOTICES_FAIL = exports.LIST_BULLETINS_SUCCESS = exports.LIST_BULLETINS_REQUESTS_SUCCESS = exports.LIST_BULLETINS_REQUESTS_REQUEST = exports.LIST_BULLETINS_REQUESTS_FAIL = exports.LIST_BULLETINS_REQUEST = exports.LIST_BULLETINS_FAIL = exports.GET_NOTICE_SUCCESS = exports.GET_NOTICE_REQUEST = exports.GET_NOTICE_FAIL = exports.GET_BULLETIN_SUCCESS = exports.GET_BULLETIN_REQUEST = exports.GET_BULLETIN_FAIL = exports.EDIT_NOTICE_DRAFT_SUCCESS = exports.EDIT_NOTICE_DRAFT_REQUEST = exports.EDIT_NOTICE_DRAFT_FAIL = exports.DENY_BULLETIN_SUCCESS = exports.DENY_BULLETIN_REQUEST = exports.DENY_BULLETIN_FAIL = exports.DELETE_NOTICE_DRAFT_SUCCESS = exports.DELETE_NOTICE_DRAFT_REQUEST = exports.DELETE_NOTICE_DRAFT_FAIL = exports.CREATE_NOTICE_SUCCESS = exports.CREATE_NOTICE_REQUEST = exports.CREATE_NOTICE_FAIL = exports.CREATE_NOTICE_DRAFT_SUCCESS = exports.CREATE_NOTICE_DRAFT_REQUEST = exports.CREATE_NOTICE_DRAFT_FAIL = exports.CREATE_BULLETIN_SUCCESS = exports.CREATE_BULLETIN_REQUEST = exports.CREATE_BULLETIN_FAIL = exports.CLEAR_NOTICE_DRAFT_SUCCESS = exports.CANCEL_HANDOUT_SUCCESS = exports.CANCEL_HANDOUT_REQUEST = exports.CANCEL_HANDOUT_FAIL = exports.APPROVE_BULLETIN_SUCCESS = exports.APPROVE_BULLETIN_REQUEST = exports.APPROVE_BULLETIN_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var LIST_NOTICES_REQUEST = exports.LIST_NOTICES_REQUEST = '@Community/LIST_NOTICES_REQUEST';
var LIST_NOTICES_SUCCESS = exports.LIST_NOTICES_SUCCESS = '@Community/LIST_NOTICES_SUCCESS';
var LIST_NOTICES_FAIL = exports.LIST_NOTICES_FAIL = '@Community/LIST_NOTICES_FAIL';
var LIST_EXPIRED_NOTICES_REQUEST = exports.LIST_EXPIRED_NOTICES_REQUEST = '@Community/LIST_EXPIRED_NOTICES_REQUEST';
var LIST_EXPIRED_NOTICES_SUCCESS = exports.LIST_EXPIRED_NOTICES_SUCCESS = '@Community/LIST_EXPIRED_NOTICES_SUCCESS';
var LIST_EXPIRED_NOTICES_FAIL = exports.LIST_EXPIRED_NOTICES_FAIL = '@Community/LIST_EXPIRED_NOTICES_FAIL';
var LIST_UPCOMING_NOTICES_REQUEST = exports.LIST_UPCOMING_NOTICES_REQUEST = '@Community/LIST_UPCOMING_NOTICES_REQUEST';
var LIST_UPCOMING_NOTICES_SUCCESS = exports.LIST_UPCOMING_NOTICES_SUCCESS = '@Community/LIST_UPCOMING_NOTICES_SUCCESS';
var LIST_UPCOMING_NOTICES_FAIL = exports.LIST_UPCOMING_NOTICES_FAIL = '@Community/LIST_UPCOMING_NOTICES_FAIL';
var GET_NOTICE_REQUEST = exports.GET_NOTICE_REQUEST = '@Community/GET_NOTICE_REQUEST';
var GET_NOTICE_SUCCESS = exports.GET_NOTICE_SUCCESS = '@Community/GET_NOTICE_SUCCESS';
var GET_NOTICE_FAIL = exports.GET_NOTICE_FAIL = '@Community/GET_NOTICE_FAIL';
var LIST_BULLETINS_REQUEST = exports.LIST_BULLETINS_REQUEST = '@Community/LIST_BULLETINS_REQUEST';
var LIST_BULLETINS_SUCCESS = exports.LIST_BULLETINS_SUCCESS = '@Community/LIST_BULLETINS_SUCCESS';
var LIST_BULLETINS_FAIL = exports.LIST_BULLETINS_FAIL = '@Community/LIST_BULLETINS_FAIL';
var GET_BULLETIN_REQUEST = exports.GET_BULLETIN_REQUEST = '@Community/GET_BULLETIN_REQUEST';
var GET_BULLETIN_SUCCESS = exports.GET_BULLETIN_SUCCESS = '@Community/GET_BULLETIN_SUCCESS';
var GET_BULLETIN_FAIL = exports.GET_BULLETIN_FAIL = '@Community/GET_BULLETIN_FAIL';
var CREATE_NOTICE_REQUEST = exports.CREATE_NOTICE_REQUEST = '@Community/CREATE_NOTICE_REQUEST';
var CREATE_NOTICE_SUCCESS = exports.CREATE_NOTICE_SUCCESS = '@Community/CREATE_NOTICE_SUCCESS';
var CREATE_NOTICE_FAIL = exports.CREATE_NOTICE_FAIL = '@Community/CREATE_NOTICE_FAIL';
var CREATE_BULLETIN_REQUEST = exports.CREATE_BULLETIN_REQUEST = '@Community/CREATE_BULLETIN_REQUEST';
var CREATE_BULLETIN_SUCCESS = exports.CREATE_BULLETIN_SUCCESS = '@Community/CREATE_BULLETIN_SUCCESS';
var CREATE_BULLETIN_FAIL = exports.CREATE_BULLETIN_FAIL = '@Community/CREATE_BULLETIN_FAIL';
var LIST_BULLETINS_REQUESTS_REQUEST = exports.LIST_BULLETINS_REQUESTS_REQUEST = '@Community/LIST_BULLETINS_REQUESTS_REQUEST';
var LIST_BULLETINS_REQUESTS_SUCCESS = exports.LIST_BULLETINS_REQUESTS_SUCCESS = '@Community/LIST_BULLETINS_REQUESTS_SUCCESS';
var LIST_BULLETINS_REQUESTS_FAIL = exports.LIST_BULLETINS_REQUESTS_FAIL = '@Community/LIST_BULLETINS_REQUESTS_FAIL';
var APPROVE_BULLETIN_REQUEST = exports.APPROVE_BULLETIN_REQUEST = '@Community/APPROVE_BULLETIN_REQUEST';
var APPROVE_BULLETIN_SUCCESS = exports.APPROVE_BULLETIN_SUCCESS = '@Community/APPROVE_BULLETIN_SUCCESS';
var APPROVE_BULLETIN_FAIL = exports.APPROVE_BULLETIN_FAIL = '@Community/APPROVE_BULLETIN_FAIL';
var DENY_BULLETIN_REQUEST = exports.DENY_BULLETIN_REQUEST = '@Community/DENY_BULLETIN_REQUEST';
var DENY_BULLETIN_SUCCESS = exports.DENY_BULLETIN_SUCCESS = '@Community/DENY_BULLETIN_SUCCESS';
var DENY_BULLETIN_FAIL = exports.DENY_BULLETIN_FAIL = '@Community/DENY_BULLETIN_FAIL';
var CANCEL_HANDOUT_REQUEST = exports.CANCEL_HANDOUT_REQUEST = '@Community/CANCEL_HANDOUT_REQUEST';
var CANCEL_HANDOUT_SUCCESS = exports.CANCEL_HANDOUT_SUCCESS = '@Community/CANCEL_HANDOUT_SUCCESS';
var CANCEL_HANDOUT_FAIL = exports.CANCEL_HANDOUT_FAIL = '@Community/CANCEL_HANDOUT_FAIL';
var CREATE_NOTICE_DRAFT_REQUEST = exports.CREATE_NOTICE_DRAFT_REQUEST = '@Community/CREATE_NOTICE_DRAFT_REQUEST';
var CREATE_NOTICE_DRAFT_SUCCESS = exports.CREATE_NOTICE_DRAFT_SUCCESS = '@Community/CREATE_NOTICE_DRAFT_SUCCESS';
var CREATE_NOTICE_DRAFT_FAIL = exports.CREATE_NOTICE_DRAFT_FAIL = '@Community/CREATE_NOTICE_DRAFT_FAIL';
var EDIT_NOTICE_DRAFT_REQUEST = exports.EDIT_NOTICE_DRAFT_REQUEST = '@Community/EDIT_NOTICE_DRAFT_REQUEST';
var EDIT_NOTICE_DRAFT_SUCCESS = exports.EDIT_NOTICE_DRAFT_SUCCESS = '@Community/EDIT_NOTICE_DRAFT_SUCCESS';
var EDIT_NOTICE_DRAFT_FAIL = exports.EDIT_NOTICE_DRAFT_FAIL = '@Community/EDIT_NOTICE_DRAFT_FAIL';
var DELETE_NOTICE_DRAFT_REQUEST = exports.DELETE_NOTICE_DRAFT_REQUEST = '@Community/DELETE_NOTICE_DRAFT_REQUEST';
var DELETE_NOTICE_DRAFT_SUCCESS = exports.DELETE_NOTICE_DRAFT_SUCCESS = '@Community/DELETE_NOTICE_DRAFT_SUCCESS';
var DELETE_NOTICE_DRAFT_FAIL = exports.DELETE_NOTICE_DRAFT_FAIL = '@Community/DELETE_NOTICE_DRAFT_FAIL';
var CLEAR_NOTICE_DRAFT_SUCCESS = exports.CLEAR_NOTICE_DRAFT_SUCCESS = '@Community/CLEAR_NOTICE_DRAFT_SUCCESS';