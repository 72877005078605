"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _axios = _interopRequireDefault(require("axios"));
var _axiosRetry = _interopRequireDefault(require("axios-retry"));
var _config = require("../../config");
var _string = require("@qwkin/string");
var _awsSdk = _interopRequireDefault(require("aws-sdk"));
var _s = _interopRequireDefault(require("aws-sdk/clients/s3"));
var _qs = _interopRequireDefault(require("qs"));
var _helpers = require("@qwkin/helpers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var defaultParams = {
  baseURL: "".concat(_config.API_HOST).concat(_config.API_PATH),
  withCredentials: true,
  headers: {
    Application: 'admin',
    'Content-Type': 'application/json',
    Accept: '/'
  },
  paramsSerializer: function paramsSerializer(params) {
    var cleaned = (0, _helpers.pruneNullAndEmptyValues)(params);
    return _qs["default"].stringify(cleaned, {
      arrayFormat: 'brackets'
    });
  }
};
var digestParams = {
  withCredentials: true
};
var successHandler = function successHandler(response) {
  return response;
};
var authErrorHandler = function authErrorHandler(error) {
  if (error.response.status === 401) {
    var protocol = window.location.protocol;
    var host = window.location.host;
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("".concat(protocol, "//").concat(host));
  }
  return Promise.reject(error);
};
var instance = function instance(params) {
  var a = _axios["default"].create(params);
  (0, _axiosRetry["default"])(a, {
    retries: 10,
    retryDelay: _axiosRetry["default"].exponentialDelay,
    retryCondition: function retryCondition(error) {
      return !error.response;
    }
  });
  a.interceptors.response.use(function (response) {
    return successHandler(response);
  }, function (error) {
    return authErrorHandler(error);
  });
  return a;
};
var _default2 = exports["default"] = {
  fromS3: function fromS3(Bucket, Key) {
    var bucket = new _s["default"]({
      region: _config.S3_REGION,
      credentials: new _awsSdk["default"].Credentials({
        accessKeyId: _config.S3_ACCESS_KEY,
        secretAccessKey: _config.S3_SECRET_KEY
      })
    });
    return new Promise(function (resolve, reject) {
      bucket.getObject({
        Bucket: Bucket,
        Key: Key
      }, function (err, file) {
        if (err) {
          return reject(err);
        }
        return resolve(file);
      });
    });
  },
  withAuth: function withAuth(token, tenantId) {
    var customParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if ((0, _string.isStringNullOrEmpty)(token)) {
      throw new Error('Missing authorization token');
    }
    var requestParams = Object.assign(defaultParams, _objectSpread({
      headers: {
        authorization: "Bearer ".concat(token),
        'tenant-id': tenantId
      }
    }, customParams));
    return instance(requestParams);
  },
  digest: function digest() {
    var customParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return instance(Object.assign(digestParams, customParams));
  },
  "default": function _default() {
    var customParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return instance(Object.assign(defaultParams, customParams));
  }
};