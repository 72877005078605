"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  error: false,
  loading: false,
  fcmToken: null
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.REGISTER_PUSH_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: false
      });
    case _variables.REGISTER_PUSH_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: false,
        fcmToken: action.payload.fcmToken
      });
    case _variables.REGISTER_PUSH_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.UNREGISTER_PUSH_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: false
      });
    case _variables.UNREGISTER_PUSH_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: false,
        fcmToken: null
      });
    case _variables.UNREGISTER_PUSH_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    default:
      return state;
  }
};