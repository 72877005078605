"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringToURL = exports.pluralIfValueIsOne = exports.pluralIfValueBiggerThanOne = exports.millisecondsToHoursMinutsSeconds = exports.mapResidentTypeToUrl = exports.isStringNullOrEmpty = exports.hexToAlphaHex = exports.getS3Url = exports.getInitials = exports.getAddressString = exports.fixMaskedPhone = exports.dollarsToCents = exports.decapitalize = exports.centsToDollars = exports.capitalize = exports.camelCaseToSpaced = exports.alphaHexToHex = void 0;
var _urlParse = _interopRequireDefault(require("url-parse"));
var isStringNullOrEmpty = exports.isStringNullOrEmpty = function isStringNullOrEmpty(s) {
  return !s || s.length === 0;
};
var capitalize = exports.capitalize = function capitalize() {
  var _s$charAt;
  var s = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return (s === null || s === void 0 || (_s$charAt = s.charAt(0)) === null || _s$charAt === void 0 ? void 0 : _s$charAt.toUpperCase()) + (s === null || s === void 0 ? void 0 : s.slice(1));
};
var decapitalize = exports.decapitalize = function decapitalize() {
  var _s$charAt2;
  var s = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return (s === null || s === void 0 || (_s$charAt2 = s.charAt(0)) === null || _s$charAt2 === void 0 ? void 0 : _s$charAt2.toLowerCase()) + (s === null || s === void 0 ? void 0 : s.slice(1));
};
var centsToDollars = exports.centsToDollars = function centsToDollars(cents) {
  var dollars = cents / 100;
  return dollars.toFixed(2);
};
var dollarsToCents = exports.dollarsToCents = function dollarsToCents(cents) {
  return parseFloat(cents) * 100;
};
var millisecondsToHoursMinutsSeconds = exports.millisecondsToHoursMinutsSeconds = function millisecondsToHoursMinutsSeconds(parkingTime) {
  if (parkingTime !== undefined) {
    var hours = Math.floor(parkingTime / 3600000);
    var minutes = Math.floor(parkingTime % 3600000 / 60000);
    var seconds = Math.floor(parkingTime % 60000 / 1000);
    var formattedMinutes = minutes < 10 ? "0".concat(minutes) : minutes;
    var formattedSeconds = seconds < 10 ? "0".concat(seconds) : seconds;
    return "".concat(hours, ":").concat(formattedMinutes, ":").concat(formattedSeconds);
  }
  return 'N/A';
};
var mapResidentTypeToUrl = exports.mapResidentTypeToUrl = function mapResidentTypeToUrl() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (type.toLowerCase() === 'owner') return 'owners';
  if (type.toLowerCase() === 'renter') return 'renters';
  if (type.toLowerCase() === 'resident') return 'residents';
  throw new Error('Invalid resident type. Please insert correct value');
};
var hexToAlphaHex = exports.hexToAlphaHex = function hexToAlphaHex(hex) {
  var _hex$replace;
  return hex === null || hex === void 0 || (_hex$replace = hex.replace('#', '0xFF')) === null || _hex$replace === void 0 ? void 0 : _hex$replace.toUpperCase();
};
var alphaHexToHex = exports.alphaHexToHex = function alphaHexToHex(hex) {
  var _hex$replace2;
  return hex === null || hex === void 0 || (_hex$replace2 = hex.replace(/0xFF/i, '#')) === null || _hex$replace2 === void 0 ? void 0 : _hex$replace2.toUpperCase();
};
var camelCaseToSpaced = exports.camelCaseToSpaced = function camelCaseToSpaced(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
};
var fixMaskedPhone = exports.fixMaskedPhone = function fixMaskedPhone(phone) {
  return phone.replaceAll('-', '').replaceAll(/\s/g, '').replaceAll('(', '').replaceAll(')', '').replaceAll('_', '');
};
var stringToURL = exports.stringToURL = function stringToURL(str) {
  return new _urlParse["default"](str, {});
};

/**
 * @param {Number} value
 * @param {String} str
 * @param {String} pluralExtension
 * @return {string|*}
 */
var pluralIfValueBiggerThanOne = exports.pluralIfValueBiggerThanOne = function pluralIfValueBiggerThanOne(value, str) {
  var pluralExtension = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 's';
  if (Number(value) === 0 || Number(value) > 1) return "".concat(str).concat(pluralExtension);
  return str;
};

/**
 * @param {Number} value
 * @param {String} str
 * @param {String} pluralExtension
 * @return {string|*}
 */
var pluralIfValueIsOne = exports.pluralIfValueIsOne = function pluralIfValueIsOne(value, str) {
  var pluralExtension = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 's';
  if (Number(value) === 0 || Number(value) === 1) return "".concat(str).concat(pluralExtension);
  return str;
};

/// Build a human readable string from address object. Receives street, city, state and zipCode and
/// Fill it partially with the available data
/// Returns a string with the address in the format: street, city, state zipCode.
/**
 * @param {String} address.street
 * @param {String} address.city
 * @param {String} address.state
 * @param {String} address.zipCode
 * @return {string}
 */
var getAddressString = exports.getAddressString = function getAddressString(_ref) {
  var street = _ref.street,
    complement = _ref.complement,
    city = _ref.city,
    state = _ref.state,
    zipCode = _ref.zipCode;
  var address = [street, complement, city, state, zipCode].filter(Boolean).join(', ');
  return address;
};

/// Build an S3 url from an object with bucket, key and region
/**
 * @param {String} bucket
 * @param {String} key
 * @param {String} region
 * @return {string}
 */
var getS3Url = exports.getS3Url = function getS3Url(_ref2) {
  var bucket = _ref2.bucket,
    key = _ref2.key,
    _ref2$region = _ref2.region,
    region = _ref2$region === void 0 ? 'us-east-2' : _ref2$region;
  return "https://".concat(bucket, ".s3.").concat(region, ".amazonaws.com/").concat(key);
};
var getInitials = exports.getInitials = function getInitials(value) {
  if (isStringNullOrEmpty(value)) return '--';
  try {
    var splittedStr = value.split(' ');
    var firstStr = splittedStr[0];
    var secondStr = splittedStr[1];
    var firstInitial = firstStr[0] !== '' ? firstStr[0] : '';
    var secondInitial = secondStr[0] !== '' ? secondStr[0] : '';
    return "".concat(firstInitial.toUpperCase()).concat(secondInitial.toUpperCase());
  } catch (error) {
    if (value.length >= 2) {
      return "".concat(value[0]).concat(value[1]).toUpperCase();
    }
    return "".concat(value[0]).toUpperCase();
  }
};