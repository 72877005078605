import styled, { createGlobalStyle } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { LoadingAnimation, Notification, Modal, Text, DefaultTheme } from '@qwkin/components';
import { ClearNotificationAction } from '@qwkin/core';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getReasonPhrase } from 'http-status-codes';

import { isObjNullOrEmpty } from '@qwkin/helpers';
// eslint-disable-next-line import/no-cycle
import { ProfileItem } from '@qwkin/components/lib/ProfileItem';
// eslint-disable-next-line import/no-cycle
import { isStringNullOrEmpty } from '@qwkin/string';
// eslint-disable-next-line import/no-cycle
import AppContainer from '../components/appContainer';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    background: rgba(0, 0, 0, 0.07);
  }
  
  h1, h2, h3 {
    margin: 0;
    line-height: normal;
    font-family: 'Nunito', sans-serif;
  }
  
  h4, h5, h6, p {
    margin: 0;
    line-height: normal;
    font-family: 'Sarabun', sans-serif !important;
  }
  
  .html2canvas-container { width: 3000px !important; height: 3000px !important; }
  
  .MuiSnackbarContent-message {
    width: 100%;
  }
`;

// BREAKPOINTS
export const LargeDevicesBreakpoint = '1024px';
export const TabletBreakpoint = '768px';
export const SmallDevicesBreakpoint = '320px';

// VIEWS
export const FullCenteredView = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 0;
`;

export const ResponsiveRow = styled(Row)`
  @media (max-width: ${LargeDevicesBreakpoint}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 12px;

  @media (max-width: ${LargeDevicesBreakpoint}) {
    width: auto;
  }
`;

export const WrappedColumn = styled(Column)`
  padding: 0;
  ${p => (p.align === 'right' ? 'align-items: flex-end;' : '')}
`;

export const WrappedRow = styled(Row)`
  padding: 0;
`;

export const ButtonsRow = styled(WrappedRow)`
  align-items: center;
  justify-content: space-between;
`;

export const BorderedContainer = styled.div`
  padding: 16px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  height: fit-content;
`;

export const TextOverflow = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: 'black';
`;

export const TextOverflowBold = styled(TextOverflow)`
  font-weight: bold;
`;

export const CheckboxContainer = styled.div`
  margin: 8px;
`;

export const TableItem = styled(WrappedRow)`
  height: 70px;
  align-items: center;
`;

export const TableText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
`;

export const ChipNotification = styled.div`
  padding: 1px 8px;
  text-align: center;
  border-radius: 50px;
  color: ${p => p.color ?? DefaultTheme.color.white};
  background-color: ${p => p.backgroundColor ?? DefaultTheme.color.primary.default};
  font-size: 0.7em;
`;

const ErrorDetailsModal = ({ isOpen, onClose, error }) => {
  const items = [
    {
      label: 'Status',
      value: error?.data?.status
        ? `${error?.data?.status} - ${getReasonPhrase(error?.data?.status)}`
        : 'No status',
    },
    {
      label: 'Message',
      value: error?.message ?? 'No message',
    },
    {
      label: 'Request ID',
      value: error?.data?.headers['x-request-id'],
      display: !isStringNullOrEmpty(error?.data?.headers['x-request-id']),
    },
    {
      label: 'Additional',
      value: error?.data?.errors?.join(', '),
      display: error?.data?.errors !== null && error?.data?.errors?.length > 0,
    },
  ];

  return (
    <Modal title="Error Details" isOpen={isOpen} onClose={onClose}>
      <div style={{ width: '400px', margin: '8px 0' }}>
        <ProfileItem items={items} />
      </div>
      {/* <WrappedRow style={{ justifyContent: 'flex-end' }}> */}
      {/*  <Button */}
      {/*    dense */}
      {/*    icon="request" */}
      {/*    disabled */}
      {/*  > */}
      {/*    Send Report */}
      {/*  </Button> */}
      {/* </WrappedRow> */}
    </Modal>
  );
};

export const DefaultView = ({ children, useGoBackNavigation, activeAppFeature, isFullScreen }) => {
  const dispatch = useDispatch();

  const [isOpen, setOpened] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [isErrorDetailsVisible, setErrorDetailsVisibility] = useState(false);

  const { data } = useSelector(({ notification }) => notification);

  useEffect(() => {
    if (!isObjNullOrEmpty(data)) {
      setOpened(true);
    }
  }, [data]);

  const handleClose = () => {
    dispatch({ type: ClearNotificationAction });
    setOpened(false);
  };

  return (
    <AppContainer
      useGoBackNavigation={useGoBackNavigation}
      activeAppFeature={activeAppFeature}
      isFullScreen={isFullScreen}
    >
      <ErrorDetailsModal
        error={errorData}
        isOpen={isErrorDetailsVisible}
        onClose={() => setErrorDetailsVisibility(false)}
      />
      {!isObjNullOrEmpty(data) && (
        <Notification
          variant={data.variant}
          isOpen={isOpen}
          onClose={handleClose}
          message={data.message}
          needAttention={data.needAttention}
          onCheckDetailsClicked={
            data.variant === 'error'
              ? () => {
                  setErrorData(data);
                  setErrorDetailsVisibility(true);
                }
              : null
          }
        />
      )}
      {children}
    </AppContainer>
  );
};

export const LoadingView = ({ isLoading, error, timedOut }) => {
  const dispatch = useDispatch();

  if (isLoading) {
    return (
      <FullCenteredView>
        <LoadingAnimation />
      </FullCenteredView>
    );
  }

  if (error || timedOut) {
    dispatch(push('/error'));
  }

  return null;
};
