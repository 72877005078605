"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_GATE_CAMERA_SUCCESS = exports.LIST_GATE_CAMERA_REQUEST = exports.LIST_GATE_CAMERA_FAIL = exports.FETCH_PREVIEW_GATE_CAMERA_SUCCESS = exports.FETCH_PREVIEW_GATE_CAMERA_REQUEST = exports.FETCH_PREVIEW_GATE_CAMERA_FAIL = exports.EDIT_GATE_CAMERA_SUCCESS = exports.EDIT_GATE_CAMERA_REQUEST = exports.EDIT_GATE_CAMERA_FAIL = exports.DELETE_GATE_CAMERA_SUCCESS = exports.DELETE_GATE_CAMERA_REQUEST = exports.DELETE_GATE_CAMERA_FAIL = exports.CREATE_GATE_CAMERA_SUCCESS = exports.CREATE_GATE_CAMERA_REQUEST = exports.CREATE_GATE_CAMERA_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var CREATE_GATE_CAMERA_REQUEST = exports.CREATE_GATE_CAMERA_REQUEST = '@GateCamera/CREATE_GATE_CAMERA_REQUEST';
var CREATE_GATE_CAMERA_SUCCESS = exports.CREATE_GATE_CAMERA_SUCCESS = '@GateCamera/CREATE_GATE_CAMERA_SUCCESS';
var CREATE_GATE_CAMERA_FAIL = exports.CREATE_GATE_CAMERA_FAIL = '@GateCamera/CREATE_GATE_CAMERA_FAIL';
var LIST_GATE_CAMERA_REQUEST = exports.LIST_GATE_CAMERA_REQUEST = '@GateCamera/LIST_GATE_CAMERA_REQUEST';
var LIST_GATE_CAMERA_SUCCESS = exports.LIST_GATE_CAMERA_SUCCESS = '@GateCamera/LIST_GATE_CAMERA_SUCCESS';
var LIST_GATE_CAMERA_FAIL = exports.LIST_GATE_CAMERA_FAIL = '@GateCamera/LIST_GATE_CAMERA_FAIL';
var EDIT_GATE_CAMERA_REQUEST = exports.EDIT_GATE_CAMERA_REQUEST = '@GateCamera/EDIT_GATE_CAMERA_REQUEST';
var EDIT_GATE_CAMERA_SUCCESS = exports.EDIT_GATE_CAMERA_SUCCESS = '@GateCamera/EDIT_GATE_CAMERA_SUCCESS';
var EDIT_GATE_CAMERA_FAIL = exports.EDIT_GATE_CAMERA_FAIL = '@GateCamera/EDIT_GATE_CAMERA_FAIL';
var DELETE_GATE_CAMERA_REQUEST = exports.DELETE_GATE_CAMERA_REQUEST = '@GateCamera/DELETE_GATE_CAMERA_REQUEST';
var DELETE_GATE_CAMERA_SUCCESS = exports.DELETE_GATE_CAMERA_SUCCESS = '@GateCamera/DELETE_GATE_CAMERA_SUCCESS';
var DELETE_GATE_CAMERA_FAIL = exports.DELETE_GATE_CAMERA_FAIL = '@GateCamera/DELETE_GATE_CAMERA_FAIL';
var FETCH_PREVIEW_GATE_CAMERA_REQUEST = exports.FETCH_PREVIEW_GATE_CAMERA_REQUEST = '@GateCamera/FETCH_PREVIEW_GATE_CAMERA_REQUEST';
var FETCH_PREVIEW_GATE_CAMERA_SUCCESS = exports.FETCH_PREVIEW_GATE_CAMERA_SUCCESS = '@GateCamera/FETCH_PREVIEW_GATE_CAMERA_SUCCESS';
var FETCH_PREVIEW_GATE_CAMERA_FAIL = exports.FETCH_PREVIEW_GATE_CAMERA_FAIL = '@GateCamera/FETCH_PREVIEW_GATE_CAMERA_FAIL';