import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { extractUserAuthenticationFromState } from '../../utils';

export const PrivateRoute = ({ component: Component, ...props }) => {
  const { isAuthenticated } = useSelector(extractUserAuthenticationFromState);

  return (
    <Route
      {...props}
      render={p =>
        isAuthenticated ? (
          <Component {...p} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: p.location } }} />
        )
      }
    />
  );
};
