"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestWeeklyMovement = exports.requestVisitorsAverage = exports.requestVisitorIdentifier = exports.requestUnitVisitors = exports.requestTodaySummary = exports.requestListEvents = exports.requestGetGateEvents = exports.requestEmergency = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestEmergency = exports.requestEmergency = function requestEmergency(payload, meta) {
  return (0, _utils.buildAction)(_variables.EMERGENCY_REQUEST, payload, meta);
};
var requestListEvents = exports.requestListEvents = function requestListEvents(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_EVENTS_REQUEST, payload, meta);
};
var requestTodaySummary = exports.requestTodaySummary = function requestTodaySummary(payload, meta) {
  return (0, _utils.buildAction)(_variables.TODAY_SUMMARY_REQUEST, payload, meta);
};
var requestUnitVisitors = exports.requestUnitVisitors = function requestUnitVisitors(payload, meta) {
  return (0, _utils.buildAction)(_variables.UNIT_VISITORS_REQUEST, payload, meta);
};
var requestVisitorsAverage = exports.requestVisitorsAverage = function requestVisitorsAverage(payload, meta) {
  return (0, _utils.buildAction)(_variables.VISITORS_AVERAGE_REQUEST, payload, meta);
};
var requestWeeklyMovement = exports.requestWeeklyMovement = function requestWeeklyMovement(payload, meta) {
  return (0, _utils.buildAction)(_variables.WEEKLY_MOVEMENT_REQUEST, payload, meta);
};
var requestVisitorIdentifier = exports.requestVisitorIdentifier = function requestVisitorIdentifier(payload, meta) {
  return (0, _utils.buildAction)(_variables.VISITOR_IDENTIFIER_REQUEST, payload, meta);
};
var requestGetGateEvents = exports.requestGetGateEvents = function requestGetGateEvents(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_GATE_EVENTS_REQUEST, payload, meta);
};