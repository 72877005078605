"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlainIcon = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
var PlainIcon = exports.PlainIcon = _styledComponents["default"].i(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  font-size: ", ";\n"])), function (p) {
  return p.color ? "color: ".concat(p.color, ";") : '';
}, function (p) {
  return "".concat(p.size, "px");
});