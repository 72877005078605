"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_CONTRACTOR_TYPE_SUCCESS = exports.LIST_CONTRACTOR_TYPE_REQUEST = exports.LIST_CONTRACTOR_TYPE_FAIL = exports.ENABLE_CONTRACTOR_TYPE_SUCCESS = exports.ENABLE_CONTRACTOR_TYPE_REQUEST = exports.ENABLE_CONTRACTOR_TYPE_FAIL = exports.EDIT_CONTRACTOR_TYPE_SUCCESS = exports.EDIT_CONTRACTOR_TYPE_REQUEST = exports.EDIT_CONTRACTOR_TYPE_FAIL = exports.DISABLE_CONTRACTOR_TYPE_SUCCESS = exports.DISABLE_CONTRACTOR_TYPE_REQUEST = exports.DISABLE_CONTRACTOR_TYPE_FAIL = exports.DELETE_CONTRACTOR_TYPE_SUCCESS = exports.DELETE_CONTRACTOR_TYPE_REQUEST = exports.DELETE_CONTRACTOR_TYPE_FAIL = exports.CREATE_CONTRACTOR_TYPE_SUCCESS = exports.CREATE_CONTRACTOR_TYPE_REQUEST = exports.CREATE_CONTRACTOR_TYPE_FAIL = exports.CONTRACTORS_LIST_SUCCESS = exports.CONTRACTORS_LIST_REQUEST = exports.CONTRACTORS_LIST_FAIL = exports.CONTRACTORS_GET_SUCCESS = exports.CONTRACTORS_GET_REQUEST = exports.CONTRACTORS_GET_FAIL = exports.CONTRACTORS_EDIT_SUCCESS = exports.CONTRACTORS_EDIT_REQUEST = exports.CONTRACTORS_EDIT_FILES_REQUEST = exports.CONTRACTORS_EDIT_FAIL = exports.CONTRACTORS_DELETE_SUCCESS = exports.CONTRACTORS_DELETE_REQUEST = exports.CONTRACTORS_DELETE_FAIL = exports.CONTRACTORS_CREATE_SUCCESS = exports.CONTRACTORS_CREATE_REQUEST = exports.CONTRACTORS_CREATE_FAIL = void 0;
var CONTRACTORS_LIST_REQUEST = exports.CONTRACTORS_LIST_REQUEST = '@Contractors/CONTRACTORS_LIST_REQUEST';
var CONTRACTORS_LIST_SUCCESS = exports.CONTRACTORS_LIST_SUCCESS = '@Contractors/CONTRACTORS_LIST_SUCCESS';
var CONTRACTORS_LIST_FAIL = exports.CONTRACTORS_LIST_FAIL = '@Contractors/CONTRACTORS_LIST_FAIL';
var CONTRACTORS_CREATE_REQUEST = exports.CONTRACTORS_CREATE_REQUEST = '@Contractors/CONTRACTORS_CREATE_REQUEST';
var CONTRACTORS_CREATE_SUCCESS = exports.CONTRACTORS_CREATE_SUCCESS = '@Contractors/CONTRACTORS_CREATE_SUCCESS';
var CONTRACTORS_CREATE_FAIL = exports.CONTRACTORS_CREATE_FAIL = '@Contractors/CONTRACTORS_CREATE_FAIL';
var CONTRACTORS_EDIT_REQUEST = exports.CONTRACTORS_EDIT_REQUEST = '@Contractors/CONTRACTORS_EDIT_REQUEST';
var CONTRACTORS_EDIT_SUCCESS = exports.CONTRACTORS_EDIT_SUCCESS = '@Contractors/CONTRACTORS_EDIT_SUCCESS';
var CONTRACTORS_EDIT_FAIL = exports.CONTRACTORS_EDIT_FAIL = '@Contractors/CONTRACTORS_EDIT_FAIL';
var CONTRACTORS_EDIT_FILES_REQUEST = exports.CONTRACTORS_EDIT_FILES_REQUEST = '@Contractors/CONTRACTORS_EDIT_FILES_REQUEST';
var CONTRACTORS_GET_REQUEST = exports.CONTRACTORS_GET_REQUEST = '@Contractors/CONTRACTORS_GET_REQUEST';
var CONTRACTORS_GET_SUCCESS = exports.CONTRACTORS_GET_SUCCESS = '@Contractors/CONTRACTORS_GET_SUCCESS';
var CONTRACTORS_GET_FAIL = exports.CONTRACTORS_GET_FAIL = '@Contractors/CONTRACTORS_GET_FAIL';
var CONTRACTORS_DELETE_REQUEST = exports.CONTRACTORS_DELETE_REQUEST = '@Contractors/CONTRACTORS_DELETE_REQUEST';
var CONTRACTORS_DELETE_SUCCESS = exports.CONTRACTORS_DELETE_SUCCESS = '@Contractors/CONTRACTORS_DELETE_SUCCESS';
var CONTRACTORS_DELETE_FAIL = exports.CONTRACTORS_DELETE_FAIL = '@Contractors/CONTRACTORS_DELETE_FAIL';
var CREATE_CONTRACTOR_TYPE_REQUEST = exports.CREATE_CONTRACTOR_TYPE_REQUEST = '@Contractors/ADD_CONTRACTOR_TYPE_REQUEST';
var CREATE_CONTRACTOR_TYPE_SUCCESS = exports.CREATE_CONTRACTOR_TYPE_SUCCESS = '@Contractors/ADD_CONTRACTOR_TYPE_SUCCESS';
var CREATE_CONTRACTOR_TYPE_FAIL = exports.CREATE_CONTRACTOR_TYPE_FAIL = '@Contractors/ADD_CONTRACTOR_TYPE_FAIL';
var EDIT_CONTRACTOR_TYPE_REQUEST = exports.EDIT_CONTRACTOR_TYPE_REQUEST = '@Contractors/EDIT_CONTRACTOR_TYPE_REQUEST';
var EDIT_CONTRACTOR_TYPE_SUCCESS = exports.EDIT_CONTRACTOR_TYPE_SUCCESS = '@Contractors/EDIT_CONTRACTOR_TYPE_SUCCESS';
var EDIT_CONTRACTOR_TYPE_FAIL = exports.EDIT_CONTRACTOR_TYPE_FAIL = '@Contractors/EDIT_CONTRACTOR_TYPE_FAIL';
var DELETE_CONTRACTOR_TYPE_REQUEST = exports.DELETE_CONTRACTOR_TYPE_REQUEST = '@Contractors/DELETE_CONTRACTOR_TYPE_REQUEST';
var DELETE_CONTRACTOR_TYPE_SUCCESS = exports.DELETE_CONTRACTOR_TYPE_SUCCESS = '@Contractors/DELETE_CONTRACTOR_TYPE_SUCCESS';
var DELETE_CONTRACTOR_TYPE_FAIL = exports.DELETE_CONTRACTOR_TYPE_FAIL = '@Contractors/DELETE_CONTRACTOR_TYPE_FAIL';
var LIST_CONTRACTOR_TYPE_REQUEST = exports.LIST_CONTRACTOR_TYPE_REQUEST = '@Contractors/LIST_CONTRACTOR_TYPE_REQUEST';
var LIST_CONTRACTOR_TYPE_SUCCESS = exports.LIST_CONTRACTOR_TYPE_SUCCESS = '@Contractors/LIST_CONTRACTOR_TYPE_SUCCESS';
var LIST_CONTRACTOR_TYPE_FAIL = exports.LIST_CONTRACTOR_TYPE_FAIL = '@Contractors/LIST_CONTRACTOR_TYPE_FAIL';
var ENABLE_CONTRACTOR_TYPE_REQUEST = exports.ENABLE_CONTRACTOR_TYPE_REQUEST = '@Contractors/ENABLE_CONTRACTOR_TYPE_REQUEST';
var ENABLE_CONTRACTOR_TYPE_SUCCESS = exports.ENABLE_CONTRACTOR_TYPE_SUCCESS = '@Contractors/ENABLE_CONTRACTOR_TYPE_SUCCESS';
var ENABLE_CONTRACTOR_TYPE_FAIL = exports.ENABLE_CONTRACTOR_TYPE_FAIL = '@Contractors/ENABLE_CONTRACTOR_TYPE_FAIL';
var DISABLE_CONTRACTOR_TYPE_REQUEST = exports.DISABLE_CONTRACTOR_TYPE_REQUEST = '@Contractors/DISABLE_CONTRACTOR_TYPE_REQUEST';
var DISABLE_CONTRACTOR_TYPE_SUCCESS = exports.DISABLE_CONTRACTOR_TYPE_SUCCESS = '@Contractors/DISABLE_CONTRACTOR_TYPE_SUCCESS';
var DISABLE_CONTRACTOR_TYPE_FAIL = exports.DISABLE_CONTRACTOR_TYPE_FAIL = '@Contractors/DISABLE_CONTRACTOR_TYPE_FAIL';