"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setNavigationData = exports.pruneNullValues = exports.pruneNullAndEmptyValues = exports.openPDFPreview = exports.moduleNumber = exports.isSameElement = exports.isObjNullOrEmpty = exports.identity = exports.groupBy = exports.getNavigationData = exports.getKeyByValue = exports.generatePin = exports.downloadCSVfromBase64 = exports.cloneObj = exports.chunkArray = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var identity = exports.identity = function identity(t) {
  return t;
};
var isObjNullOrEmpty = exports.isObjNullOrEmpty = function isObjNullOrEmpty(obj) {
  return !obj || Object.entries(obj).length === 0;
};
var pruneNullValues = exports.pruneNullValues = function pruneNullValues(obj) {
  return Object.entries(obj).filter(function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      _ = _ref2[0],
      v = _ref2[1];
    return v != null && v != undefined;
  }).reduce(function (acc, _ref3) {
    var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
      k = _ref4[0],
      v = _ref4[1];
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, k, v === Object(v) && !Array.isArray(v) && !v instanceof Date ? pruneNullValues(v) : v));
  }, {});
};
var pruneNullAndEmptyValues = exports.pruneNullAndEmptyValues = function pruneNullAndEmptyValues(obj) {
  return Object.entries(obj).filter(function (_ref5) {
    var _ref6 = (0, _slicedToArray2["default"])(_ref5, 2),
      _ = _ref6[0],
      v = _ref6[1];
    return v != null && v != '' && v != undefined;
  }).reduce(function (acc, _ref7) {
    var _ref8 = (0, _slicedToArray2["default"])(_ref7, 2),
      k = _ref8[0],
      v = _ref8[1];
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, k, v === Object(v) && !Array.isArray(v) && !v instanceof Date ? pruneNullAndEmptyValues(v) : v));
  }, {});
};
var cloneObj = exports.cloneObj = function cloneObj() {
  var src = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultObj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var pruneNull = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return pruneNull ? pruneNullValues(Object.assign(defaultObj, JSON.parse(JSON.stringify(src)))) : Object.assign(defaultObj, JSON.parse(JSON.stringify(src)));
};
var moduleNumber = exports.moduleNumber = function moduleNumber(n) {
  var number = Number(n);
  return number < 0 ? number * -1 : number;
};
var setNavigationData = exports.setNavigationData = function setNavigationData(data) {
  return localStorage.setItem('navigationData', JSON.stringify(data));
};
var getNavigationData = exports.getNavigationData = function getNavigationData() {
  var debug = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  try {
    var data = cloneObj(JSON.parse(localStorage.getItem('navigationData')));
    if (!debug) {
      localStorage.removeItem('navigationData');
    }
    return data;
  } catch (error) {
    console.error('Invalid JSON:', error.message);
    console.error('Error Stacktrace:', error.stack);
    return null;
  }
};
var chunkArray = exports.chunkArray = function chunkArray(array, chunk_size) {
  return Array(Math.ceil(array.length / chunk_size)).fill().map(function (_, index) {
    return index * chunk_size;
  }).map(function (begin) {
    return array.slice(begin, begin + chunk_size);
  });
};
var generatePin = exports.generatePin = function generatePin() {
  return "".concat(~~(Math.random() * 10)).concat(~~(Math.random() * 10)).concat(~~(Math.random() * 10)).concat(~~(Math.random() * 10));
};
var getKeyByValue = exports.getKeyByValue = function getKeyByValue(o, v) {
  return Object.keys(o).find(function (key) {
    return o[key] === v;
  });
};
var groupBy = exports.groupBy = function groupBy(arr) {
  var fncKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : identity;
  var map = new Map();
  arr.forEach(function (i) {
    var key = fncKey(i);
    var collection = map.get(key);
    if (!collection) {
      map.set(key, [i]);
    } else {
      collection.push(i);
    }
  });
  return map;
};
var isSameElement = exports.isSameElement = function isSameElement(elementOne, elementTwo) {
  return JSON.stringify(elementOne) !== JSON.stringify(elementTwo);
};
var downloadCSVfromBase64 = exports.downloadCSVfromBase64 = function downloadCSVfromBase64(base64) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'file';
  var arrayBuffer = new Uint8Array((0, _toConsumableArray2["default"])(window.atob(base64)).map(function (_char) {
    return _char.charCodeAt(0);
  }));
  var fileLink = document.createElement('a');
  fileLink.href = window.URL.createObjectURL(new Blob([arrayBuffer]));
  fileLink.setAttribute('download', "".concat(fileName, ".csv"));
  document.body.appendChild(fileLink);
  fileLink.click();
};
var openPDFPreview = exports.openPDFPreview = function openPDFPreview(_ref9) {
  var base64 = _ref9.base64;
  var byteCharacters = atob(base64);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var blob = new Blob([byteArray], {
    type: 'application/pdf'
  });
  var url = URL.createObjectURL(blob);
  window.open(url);
};