"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SIGNOUT_SUCCESS = exports.SIGNOUT_REQUEST = exports.SIGNOUT_FAIL = exports.SIGNIN_SUCCESS = exports.SIGNIN_REQUEST = exports.SIGNIN_FAIL = exports.RESET_LOADING = exports.REFRESH_SESSION_SUCCESS = exports.REFRESH_SESSION_REQUEST = exports.REFRESH_SESSION_FAIL = exports.EDIT_VALET_CONFIGURATION_DATA = exports.EDIT_USER_DATA = exports.EDIT_CONDO_DATA = exports.EDIT_CONDO_CONFIGURATION_DATA = exports.CHANGE_PASSWORD_SUCCESS = exports.CHANGE_PASSWORD_REQUEST = exports.CHANGE_PASSWORD_FAIL = void 0;
var SIGNIN_REQUEST = exports.SIGNIN_REQUEST = '@Auth/SIGNIN_REQUEST';
var SIGNIN_SUCCESS = exports.SIGNIN_SUCCESS = '@Auth/SIGNIN_SUCCESS';
var SIGNIN_FAIL = exports.SIGNIN_FAIL = '@Auth/SIGNIN_FAIL';
var SIGNOUT_REQUEST = exports.SIGNOUT_REQUEST = '@Auth/SIGNOUT_REQUEST';
var SIGNOUT_SUCCESS = exports.SIGNOUT_SUCCESS = '@Auth/SIGNOUT_SUCCESS';
var SIGNOUT_FAIL = exports.SIGNOUT_FAIL = '@Auth/SIGNOUT_FAIL';
var EDIT_USER_DATA = exports.EDIT_USER_DATA = '@Auth/EDIT_USER_DATA';
var EDIT_CONDO_DATA = exports.EDIT_CONDO_DATA = '@Auth/EDIT_CONDO_DATA';
var EDIT_CONDO_CONFIGURATION_DATA = exports.EDIT_CONDO_CONFIGURATION_DATA = '@Auth/EDIT_CONDO_CONFIGURATION_DATA';
var EDIT_VALET_CONFIGURATION_DATA = exports.EDIT_VALET_CONFIGURATION_DATA = '@Auth/EDIT_VALET_CONFIGURATION_DATA';
var CHANGE_PASSWORD_REQUEST = exports.CHANGE_PASSWORD_REQUEST = '@Auth/CHANGE_PASSWORD_REQUEST';
var CHANGE_PASSWORD_SUCCESS = exports.CHANGE_PASSWORD_SUCCESS = '@Auth/CHANGE_PASSWORD_SUCCESS';
var CHANGE_PASSWORD_FAIL = exports.CHANGE_PASSWORD_FAIL = '@Auth/CHANGE_PASSWORD_FAIL';
var RESET_LOADING = exports.RESET_LOADING = '@Auth/RESET_LOADING';
var REFRESH_SESSION_REQUEST = exports.REFRESH_SESSION_REQUEST = '@Auth/REFRESH_SESSION_REQUEST';
var REFRESH_SESSION_SUCCESS = exports.REFRESH_SESSION_SUCCESS = '@Auth/REFRESH_SESSION_SUCCESS';
var REFRESH_SESSION_FAIL = exports.REFRESH_SESSION_FAIL = '@Auth/REFRESH_SESSION_FAIL';