"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Text", {
  enumerable: true,
  get: function get() {
    return _Text["default"];
  }
});
var _Text = _interopRequireDefault(require("./Text"));