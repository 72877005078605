import Loadable from 'react-loadable';

import { LoadingView } from '../../styles';

export const asyncLoad = loader => {
  return Loadable({
    loader,
    loading: LoadingView,
    delay: 300,
    timeout: 10000,
  });
};
