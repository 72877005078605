"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  error: false,
  loadingList: false,
  loadingListTypes: false,
  loadingCreate: false,
  loadingEditType: false,
  loadingEdit: false,
  loadingCreateType: false,
  loadingDelete: false,
  loadingDeleteType: false,
  data: {},
  list: [],
  listType: [],
  listCount: 0,
  listPages: 0,
  listTypeCount: 0,
  listTypePages: 0
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data9, _action$payload9, _action$payload$data10, _action$payload10, _action$payload$data11, _action$payload11, _action$payload$data12, _action$payload12, _action$payload$data13, _action$payload13, _action$payload$data14, _action$payload14, _action$payload$data15, _action$payload15, _action$payload$data16, _action$payload16;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.EDIT_PET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEdit: true,
        error: false
      });
    case _variables.EDIT_PET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEdit: false,
        error: false,
        data: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.EDIT_PET_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEdit: false,
        error: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.EDIT_PET_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditType: true,
        error: false
      });
    case _variables.EDIT_PET_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditType: false,
        error: false,
        data: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {}
      });
    case _variables.EDIT_PET_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditType: false,
        error: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.CREATE_PET_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateType: true,
        error: false
      });
    case _variables.CREATE_PET_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateType: false,
        error: false,
        data: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : {}
      });
    case _variables.CREATE_PET_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateType: false,
        error: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {}
      });
    case _variables.DELETE_PET_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteType: true,
        error: false
      });
    case _variables.DELETE_PET_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteType: false,
        error: false,
        data: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : {}
      });
    case _variables.DELETE_PET_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteType: false,
        error: (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}
      });
    case _variables.DELETE_PET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDelete: true,
        error: false
      });
    case _variables.DELETE_PET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDelete: false,
        error: false,
        data: (_action$payload$data9 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : {}
      });
    case _variables.DELETE_PET_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDelete: false,
        error: (_action$payload$data10 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}
      });
    case _variables.CREATE_PET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreate: true,
        error: false
      });
    case _variables.CREATE_PET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreate: false,
        error: false,
        data: (_action$payload$data11 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : {}
      });
    case _variables.CREATE_PET_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreate: false,
        error: (_action$payload$data12 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {}
      });
    case _variables.LIST_PETS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: true,
        error: false
      });
    case _variables.LIST_PETS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: false,
        error: false,
        list: (_action$payload$data13 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data13 !== void 0 ? _action$payload$data13 : [],
        listCount: action.payload.headers['total-count'],
        listPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_PETS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: false,
        error: (_action$payload$data14 = action === null || action === void 0 || (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data) !== null && _action$payload$data14 !== void 0 ? _action$payload$data14 : {}
      });
    case _variables.LIST_PET_TYPES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListTypes: true,
        error: false
      });
    case _variables.LIST_PET_TYPES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListTypes: false,
        error: false,
        listType: (_action$payload$data15 = action === null || action === void 0 || (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.data) !== null && _action$payload$data15 !== void 0 ? _action$payload$data15 : [],
        listTypeCount: action.payload.headers['total-count'],
        listTypePages: action.payload.headers['total-pages']
      });
    case _variables.LIST_PET_TYPES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListTypes: false,
        error: (_action$payload$data16 = action === null || action === void 0 || (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.data) !== null && _action$payload$data16 !== void 0 ? _action$payload$data16 : {}
      });
    default:
      return state;
  }
};