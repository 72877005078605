"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_PET_TYPES_SUCCESS = exports.LIST_PET_TYPES_REQUEST = exports.LIST_PET_TYPES_FAIL = exports.LIST_PETS_SUCCESS = exports.LIST_PETS_REQUEST = exports.LIST_PETS_FAIL = exports.EDIT_PET_TYPE_SUCCESS = exports.EDIT_PET_TYPE_REQUEST = exports.EDIT_PET_TYPE_FAIL = exports.EDIT_PET_SUCCESS = exports.EDIT_PET_REQUEST = exports.EDIT_PET_FAIL = exports.DELETE_PET_TYPE_SUCCESS = exports.DELETE_PET_TYPE_REQUEST = exports.DELETE_PET_TYPE_FAIL = exports.DELETE_PET_SUCCESS = exports.DELETE_PET_REQUEST = exports.DELETE_PET_FAIL = exports.CREATE_PET_TYPE_SUCCESS = exports.CREATE_PET_TYPE_REQUEST = exports.CREATE_PET_TYPE_FAIL = exports.CREATE_PET_SUCCESS = exports.CREATE_PET_REQUEST = exports.CREATE_PET_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var LIST_PETS_REQUEST = exports.LIST_PETS_REQUEST = '@Pet/LIST_PETS_REQUEST';
var LIST_PETS_SUCCESS = exports.LIST_PETS_SUCCESS = '@Pet/LIST_PETS_SUCCESS';
var LIST_PETS_FAIL = exports.LIST_PETS_FAIL = '@Pet/LIST_PETS_FAIL';
var LIST_PET_TYPES_REQUEST = exports.LIST_PET_TYPES_REQUEST = '@Pet/LIST_PET_TYPES_REQUEST';
var LIST_PET_TYPES_SUCCESS = exports.LIST_PET_TYPES_SUCCESS = '@Pet/LIST_PET_TYPES_SUCCESS';
var LIST_PET_TYPES_FAIL = exports.LIST_PET_TYPES_FAIL = '@Pet/LIST_PET_TYPES_FAIL';
var CREATE_PET_REQUEST = exports.CREATE_PET_REQUEST = '@Pet/CREATE_PET_REQUEST';
var CREATE_PET_SUCCESS = exports.CREATE_PET_SUCCESS = '@Pet/CREATE_PET_SUCCESS';
var CREATE_PET_FAIL = exports.CREATE_PET_FAIL = '@Pet/CREATE_PET_FAIL';
var CREATE_PET_TYPE_REQUEST = exports.CREATE_PET_TYPE_REQUEST = '@Pet/CREATE_PET_TYPE_REQUEST';
var CREATE_PET_TYPE_SUCCESS = exports.CREATE_PET_TYPE_SUCCESS = '@Pet/CREATE_PET_TYPE_SUCCESS';
var CREATE_PET_TYPE_FAIL = exports.CREATE_PET_TYPE_FAIL = '@Pet/CREATE_PET_TYPE_FAIL';
var EDIT_PET_TYPE_REQUEST = exports.EDIT_PET_TYPE_REQUEST = '@Pet/EDIT_PET_TYPE_REQUEST';
var EDIT_PET_TYPE_SUCCESS = exports.EDIT_PET_TYPE_SUCCESS = '@Pet/EDIT_PET_TYPE_SUCCESS';
var EDIT_PET_TYPE_FAIL = exports.EDIT_PET_TYPE_FAIL = '@Pet/EDIT_PET_TYPE_FAIL';
var DELETE_PET_REQUEST = exports.DELETE_PET_REQUEST = '@Pet/DELETE_PET_REQUEST';
var DELETE_PET_SUCCESS = exports.DELETE_PET_SUCCESS = '@Pet/DELETE_PET_SUCCESS';
var DELETE_PET_FAIL = exports.DELETE_PET_FAIL = '@Pet/DELETE_PET_FAIL';
var DELETE_PET_TYPE_REQUEST = exports.DELETE_PET_TYPE_REQUEST = '@Pet/DELETE_PET_TYPE_REQUEST';
var DELETE_PET_TYPE_SUCCESS = exports.DELETE_PET_TYPE_SUCCESS = '@Pet/DELETE_PET_TYPE_SUCCESS';
var DELETE_PET_TYPE_FAIL = exports.DELETE_PET_TYPE_FAIL = '@Pet/DELETE_PET_TYPE_FAIL';
var EDIT_PET_REQUEST = exports.EDIT_PET_REQUEST = '@Pet/EDIT_PET_REQUEST';
var EDIT_PET_SUCCESS = exports.EDIT_PET_SUCCESS = '@Pet/EDIT_PET_SUCCESS';
var EDIT_PET_FAIL = exports.EDIT_PET_FAIL = '@Pet/EDIT_PET_FAIL';