"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  getEventError: false,
  loadingGetEvent: false,
  eventData: {
    data: {}
  },
  error: false,
  loading: false,
  loadingRecheckin: false,
  errorRecheckin: false,
  recheckinData: [],
  checkoutData: {},
  loadingCheckout: false,
  data: [],
  emergency: {
    data: {}
  },
  weeklyMovement: {
    data: {}
  },
  unitVisitors: {
    data: {}
  },
  visitorsAverage: {
    data: {}
  },
  todaySummary: {
    data: {}
  },
  event: {},
  eventsByPass: [],
  loadingEventsByPass: false,
  loadingEvent: false,
  loadingVisitorIdentifier: false,
  errorVisitorIdentifier: false,
  visitorIdentifier: {},
  loadingGateEvents: false,
  errorGateEvents: false,
  gateEvents: []
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data9, _action$payload9, _action$payload$data10, _action$payload10, _action$payload$data11, _action$payload11, _action$payload$data12, _action$payload12, _action$payload$data13, _action$payload13, _action$payload$data14, _action$payload14, _action$payload$data15, _action$payload15, _action$payload$data16, _action$payload16, _action$payload$data17, _action$payload17, _action$payload$data18, _action$payload18, _action$payload$data19, _action$payload19, _action$payload$data20, _action$payload20, _action$payload$data21, _action$payload21, _action$payload$data22, _action$payload22, _action$payload$data23, _action$payload23, _action$payload$data24, _action$payload24, _action$payload$data25, _action$payload25, _action$payload$data26, _action$payload26;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.CHECKOUT_EVENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCheckout: true
      });
    case _variables.CHECKOUT_EVENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCheckout: false,
        checkoutData: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.CHECKOUT_EVENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCheckout: false,
        checkoutData: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.RECHECKIN_EVENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingRecheckin: true
      });
    case _variables.RECHECKIN_EVENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingRecheckin: false,
        recheckinData: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : []
      });
    case _variables.RECHECKIN_EVENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingRecheckin: false,
        errorRecheckin: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.LIST_EVENTS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        loadingVisitorIdentifier: false
      });
    case _variables.LIST_EVENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loadingVisitorIdentifier: false,
        error: false,
        data: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : []
      });
    case _variables.LIST_EVENTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {},
        data: []
      });
    case _variables.GET_EVENT_BY_ID_AND_CONDOS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEvent: true
      });
    case _variables.GET_EVENT_BY_ID_AND_CONDOS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEvent: false,
        getEventError: false,
        event: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : {}
      });
    case _variables.GET_EVENT_BY_ID_AND_CONDOS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEvent: false,
        getEventError: (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {},
        event: {}
      });
    case _variables.GET_EVENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetEvent: true
      });
    case _variables.GET_EVENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetEvent: false,
        getEventError: false,
        eventData: (_action$payload$data9 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : {}
      });
    case _variables.GET_EVENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetEvent: false,
        getEventError: (_action$payload$data10 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {},
        eventData: {}
      });
    case _variables.GET_GATE_EVENTS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGateEvents: true
      });
    case _variables.GET_GATE_EVENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGateEvents: false,
        errorGateEvents: false,
        gateEvents: (_action$payload$data11 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : []
      });
    case _variables.GET_GATE_EVENTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGateEvents: false,
        errorGateEvents: (_action$payload$data12 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {},
        gateEvents: {}
      });
    case _variables.GET_EVENT_BY_PASS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEventsByPass: true
      });
    case _variables.GET_EVENT_BY_PASS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEventsByPass: false,
        error: false,
        eventsByPass: (_action$payload$data13 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data13 !== void 0 ? _action$payload$data13 : []
      });
    case _variables.GET_EVENT_BY_PASS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEventsByPass: false,
        error: (_action$payload$data14 = action === null || action === void 0 || (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data) !== null && _action$payload$data14 !== void 0 ? _action$payload$data14 : {},
        eventsByPass: []
      });
    case _variables.EMERGENCY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        emergency: _objectSpread(_objectSpread({}, state.emergency), {}, {
          loading: true,
          error: false
        })
      });
    case _variables.EMERGENCY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        emergency: _objectSpread(_objectSpread({}, state.emergency), {}, {
          loading: false,
          error: false,
          data: (_action$payload$data15 = action === null || action === void 0 || (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.data) !== null && _action$payload$data15 !== void 0 ? _action$payload$data15 : []
        })
      });
    case _variables.EMERGENCY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        emergency: _objectSpread(_objectSpread({}, state.emergency), {}, {
          loading: false,
          error: (_action$payload$data16 = action === null || action === void 0 || (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.data) !== null && _action$payload$data16 !== void 0 ? _action$payload$data16 : {}
        })
      });
    case _variables.WEEKLY_MOVEMENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        weeklyMovement: _objectSpread(_objectSpread({}, state.weeklyMovement), {}, {
          loading: true,
          error: false
        })
      });
    case _variables.WEEKLY_MOVEMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        weeklyMovement: _objectSpread(_objectSpread({}, state.weeklyMovement), {}, {
          loading: false,
          error: false,
          data: (_action$payload$data17 = action === null || action === void 0 || (_action$payload17 = action.payload) === null || _action$payload17 === void 0 ? void 0 : _action$payload17.data) !== null && _action$payload$data17 !== void 0 ? _action$payload$data17 : []
        })
      });
    case _variables.WEEKLY_MOVEMENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        weeklyMovement: _objectSpread(_objectSpread({}, state.weeklyMovement), {}, {
          loading: false,
          error: (_action$payload$data18 = action === null || action === void 0 || (_action$payload18 = action.payload) === null || _action$payload18 === void 0 ? void 0 : _action$payload18.data) !== null && _action$payload$data18 !== void 0 ? _action$payload$data18 : {}
        })
      });
    case _variables.UNIT_VISITORS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        unitVisitors: _objectSpread(_objectSpread({}, state.unitVisitors), {}, {
          loading: true,
          error: false
        })
      });
    case _variables.UNIT_VISITORS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        unitVisitors: _objectSpread(_objectSpread({}, state.unitVisitors), {}, {
          loading: false,
          error: false,
          data: (_action$payload$data19 = action === null || action === void 0 || (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : _action$payload19.data) !== null && _action$payload$data19 !== void 0 ? _action$payload$data19 : []
        })
      });
    case _variables.UNIT_VISITORS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        unitVisitors: _objectSpread(_objectSpread({}, state.unitVisitors), {}, {
          loading: false,
          error: (_action$payload$data20 = action === null || action === void 0 || (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.data) !== null && _action$payload$data20 !== void 0 ? _action$payload$data20 : {}
        })
      });
    case _variables.VISITORS_AVERAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        visitorsAverage: _objectSpread(_objectSpread({}, state.visitorsAverage), {}, {
          loading: true,
          error: false
        })
      });
    case _variables.VISITORS_AVERAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        visitorsAverage: _objectSpread(_objectSpread({}, state.visitorsAverage), {}, {
          loading: false,
          error: false,
          data: (_action$payload$data21 = action === null || action === void 0 || (_action$payload21 = action.payload) === null || _action$payload21 === void 0 ? void 0 : _action$payload21.data) !== null && _action$payload$data21 !== void 0 ? _action$payload$data21 : []
        })
      });
    case _variables.VISITORS_AVERAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        visitorsAverage: _objectSpread(_objectSpread({}, state.visitorsAverage), {}, {
          loading: false,
          error: (_action$payload$data22 = action === null || action === void 0 || (_action$payload22 = action.payload) === null || _action$payload22 === void 0 ? void 0 : _action$payload22.data) !== null && _action$payload$data22 !== void 0 ? _action$payload$data22 : {}
        })
      });
    case _variables.TODAY_SUMMARY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        todaySummary: _objectSpread(_objectSpread({}, state.todaySummary), {}, {
          loading: true,
          error: false
        })
      });
    case _variables.TODAY_SUMMARY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        todaySummary: _objectSpread(_objectSpread({}, state.todaySummary), {}, {
          loading: false,
          error: false,
          data: (_action$payload$data23 = action === null || action === void 0 || (_action$payload23 = action.payload) === null || _action$payload23 === void 0 ? void 0 : _action$payload23.data) !== null && _action$payload$data23 !== void 0 ? _action$payload$data23 : []
        })
      });
    case _variables.TODAY_SUMMARY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        todaySummary: _objectSpread(_objectSpread({}, state.todaySummary), {}, {
          loading: false,
          error: (_action$payload$data24 = action === null || action === void 0 || (_action$payload24 = action.payload) === null || _action$payload24 === void 0 ? void 0 : _action$payload24.data) !== null && _action$payload$data24 !== void 0 ? _action$payload$data24 : {}
        })
      });
    case _variables.VISITOR_IDENTIFIER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingVisitorIdentifier: true
      });
    case _variables.VISITOR_IDENTIFIER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingVisitorIdentifier: false,
        errorVisitorIdentifier: false,
        visitorIdentifier: (_action$payload$data25 = action === null || action === void 0 || (_action$payload25 = action.payload) === null || _action$payload25 === void 0 ? void 0 : _action$payload25.data) !== null && _action$payload$data25 !== void 0 ? _action$payload$data25 : {}
      });
    case _variables.VISITOR_IDENTIFIER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingVisitorIdentifier: false,
        errorVisitorIdentifier: (_action$payload$data26 = action === null || action === void 0 || (_action$payload26 = action.payload) === null || _action$payload26 === void 0 ? void 0 : _action$payload26.data) !== null && _action$payload$data26 !== void 0 ? _action$payload$data26 : {},
        visitorIdentifier: {}
      });
    default:
      return state;
  }
};