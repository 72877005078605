"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileValueRow = exports.PlainProfileValue = exports.PlainProfileLabel = exports.PlainProfileItem = exports.PlainProfileContent = exports.PlainProfileBackground = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var ProfileValueRow = exports.ProfileValueRow = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var PlainProfileBackground = exports.PlainProfileBackground = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  border-radius: 8px;\n  background: ", ";\n  width: 100%;\n"])), function (p) {
  return p.even ? p.theme.color.primary.clear : 'transparent';
});
var PlainProfileContent = exports.PlainProfileContent = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  padding: ", ";\n"])), function (p) {
  return p.paddingRule;
});
var PlainProfileItem = exports.PlainProfileItem = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: ", ";\n  justify-content: ", ";\n  align-items: ", ";\n  width: 100%;\n"])), function (p) {
  var _p$direction;
  return (_p$direction = p === null || p === void 0 ? void 0 : p.direction) !== null && _p$direction !== void 0 ? _p$direction : 'row';
}, function (p) {
  return p.direction === 'row' ? 'space-between' : 'flex-start';
}, function (p) {
  return p.direction === 'row' ? 'center' : 'flex-start';
});
var PlainProfileLabel = exports.PlainProfileLabel = _styledComponents["default"].div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  display: flex;\n  white-space: pre-line;\n  justify-content: flex-start;\n  overflow-wrap: anywhere;\n  word-break: break-word;\n  max-width: 40%;\n  font-weight: ", ";\n  padding: ", ";\n"])), function (p) {
  return p.boldTitle ? 'bold' : 'normal';
}, function (p) {
  return p.direction === 'row' ? p.paddingRule : '8px 12px 2px 12px';
});
var PlainProfileValue = exports.PlainProfileValue = _styledComponents["default"].div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n  font-family: 'Nunito', sans-serif;\n  position: relative;\n  display: flex;\n  justify-content: ", ";\n  text-align: ", ";\n  white-space: pre-line;\n  overflow-wrap: anywhere;\n  word-break: break-word;\n  max-width: 60%;\n  padding: ", ";\n  font-size: 1em;\n  font-weight: ", ";\n  color: ", ";\n  cursor: ", ";\n"])), function (p) {
  return p.direction === 'row' ? 'flex-end' : 'flex-start';
}, function (p) {
  return p.direction === 'row' ? 'right' : 'left';
}, function (p) {
  return p.direction === 'row' ? p.paddingRule : '2px 12px 8px 12px';
}, function (p) {
  return p.boldTitle ? 'normal' : 'bold';
}, function (p) {
  return p.action ? p.theme.color.primary["default"] : 'black';
}, function (p) {
  return p.action ? 'pointer' : 'default';
});