"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PostItHeaderDetail = exports.PostItHeader = exports.PostItContainer = exports.PostItChildrenContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Card = require("../Card");
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var PostItContainer = exports.PostItContainer = (0, _styledComponents["default"])(_Card.Card)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  padding: 0;\n  width: ", "\n  height: ", "\n  min-width: ", "\n  min-height: ", "\n  max-width: ", "\n  max-height ", "\n"])), function (p) {
  return "".concat(p.size, "px !important");
}, function (p) {
  return "".concat(p.size, "px !important");
}, function (p) {
  return "".concat(p.size, "px !important");
}, function (p) {
  return "".concat(p.size, "px !important");
}, function (p) {
  return "".concat(p.size, "px !important");
}, function (p) {
  return "".concat(p.size, "px !important");
});
var PostItChildrenContainer = exports.PostItChildrenContainer = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  padding: 8px;\n"])));
var PostItHeader = exports.PostItHeader = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  justify-content: space-between;\n  padding: 8px;\n  border-radius: 5px 5px 0px 0px;\n  background: ", ";\n"])), function (p) {
  return p.theme.color.primary["default"];
});
var PostItHeaderDetail = exports.PostItHeaderDetail = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  color: #ffffff;\n  font-weight: bold;\n"])));