"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTextField = exports.FormFieldWrapper = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));
var _templateObject;
var FormFieldWrapper = exports.FormFieldWrapper = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  width: 100%;\n  position: relative;\n\n  .MuiOutlinedInput-root {\n    border-radius: 12px !important;\n  }\n\n  .MuiOutlinedInput-adornedStart {\n    padding-left: 8px !important;\n  }\n"])));
var StyledTextField = exports.StyledTextField = (0, _styledComponents["default"])(_TextField["default"])(function (_ref) {
  var focusedColor = _ref.focusedColor;
  return {
    '& label.Mui-focused': {
      color: focusedColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: focusedColor
      }
    }
  };
});