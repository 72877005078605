import styled from 'styled-components';

// TODO: Move to components package
export const Divider = styled.div`
  width: calc(100% - ${p => p?.marginFactor ?? '48px'});
  height: 1px;
  background: ${p => p.color || 'white'};
  border-radius: 1px;
  opacity: 0.3;
  margin: 12px 0;
`;

export const DividerVertical = styled.div`
  height: calc(100% - 12px);
  width: 1px;
  background: ${p => p.color || 'white'};
  border-radius: 1px;
  opacity: 0.3;
`;
