"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatedData = exports.shouldUpdateData = exports.requestSendAmenityCardLink = exports.requestRemoveResidentFromUnit = exports.requestListUnits = exports.requestListAmenityCards = exports.requestGetUnit = exports.requestExpireAmenityCard = exports.requestEditResidentFromUnit = exports.requestEditAmenityCards = exports.requestDeleteAmenityCard = exports.requestCreateUnit = exports.requestCreateAmenityCards = exports.requestAddResidentToUnit = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestAddResidentToUnit = exports.requestAddResidentToUnit = function requestAddResidentToUnit(payload, meta) {
  return (0, _utils.buildAction)(_variables.ADD_RESIDENT_TO_UNIT_REQUEST, payload, meta);
};
var requestCreateAmenityCards = exports.requestCreateAmenityCards = function requestCreateAmenityCards(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_AMENITY_CARDS_REQUEST, payload, meta);
};
var requestCreateUnit = exports.requestCreateUnit = function requestCreateUnit(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_UNIT_REQUEST, payload, meta);
};
var requestDeleteAmenityCard = exports.requestDeleteAmenityCard = function requestDeleteAmenityCard(payload, meta) {
  return (0, _utils.buildAction)(_variables.DELETE_AMENITY_CARD_REQUEST, payload, meta);
};
var requestExpireAmenityCard = exports.requestExpireAmenityCard = function requestExpireAmenityCard(payload, meta) {
  return (0, _utils.buildAction)(_variables.EXPIRE_AMENITY_CARD_REQUEST, payload, meta);
};
var requestEditAmenityCards = exports.requestEditAmenityCards = function requestEditAmenityCards(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_AMENITY_CARDS_REQUEST, payload, meta);
};
var requestEditResidentFromUnit = exports.requestEditResidentFromUnit = function requestEditResidentFromUnit(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_RESIDENT_FROM_UNIT_REQUEST, payload, meta);
};
var requestGetUnit = exports.requestGetUnit = function requestGetUnit(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_UNIT_REQUEST, payload, meta);
};
var requestListAmenityCards = exports.requestListAmenityCards = function requestListAmenityCards(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_AMENITY_CARDS_REQUEST, payload, meta);
};
var requestListUnits = exports.requestListUnits = function requestListUnits(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_UNITS_REQUEST, payload, meta);
};
var requestRemoveResidentFromUnit = exports.requestRemoveResidentFromUnit = function requestRemoveResidentFromUnit(payload, meta) {
  return (0, _utils.buildAction)(_variables.REMOVE_RESIDENT_FROM_UNIT_REQUEST, payload, meta);
};
var requestSendAmenityCardLink = exports.requestSendAmenityCardLink = function requestSendAmenityCardLink(payload, meta) {
  return (0, _utils.buildAction)(_variables.SEND_AMENITY_CARD_LINK_REQUEST, payload, meta);
};
var updatedData = exports.updatedData = function updatedData() {
  return (0, _utils.buildAction)(_variables.UPDATED_DATA, false);
};
var shouldUpdateData = exports.shouldUpdateData = function shouldUpdateData() {
  return (0, _utils.buildAction)(_variables.SHOULD_UPDATE_DATA, true);
};