"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlainImage = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _animations = require("@qwkin/animations");
var _templateObject;
var PlainImage = exports.PlainImage = _styledComponents["default"].img(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  animation: ", " 0.5s;\n  object-fit: cover;\n"])), _animations.fadeIn);