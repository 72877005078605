"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitleHeader = exports.PreviewContent = exports.ImageContainer = exports.ExpansiveCardTitle = exports.ExpansiveCardHeader = exports.ExpansiveCardDescriptionTrimmed = exports.ExpansiveCardContainer = exports.ExpansiveCardChildren = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Text = require("../Text");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var ExpansiveCardContainer = exports.ExpansiveCardContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  border: 1px solid ", ";\n  border-radius: 10px;\n"])), function (props) {
  return props.theme.color.primary.translucid;
});
var ExpansiveCardChildren = exports.ExpansiveCardChildren = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  overflow: scroll;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  .collapsible {\n    transition: max-height 0.2s;\n    max-height: 0;\n  }\n  .collapsed {\n    transition: max-height 0.7s;\n    max-height: 300px;\n  }\n"])));
var ExpansiveCardTitle = exports.ExpansiveCardTitle = (0, _styledComponents["default"])(_Text.Text)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"])));
var ExpansiveCardDescriptionTrimmed = exports.ExpansiveCardDescriptionTrimmed = (0, _styledComponents["default"])(_Text.Text)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding-bottom: 8px;\n  margin-bottom: 8px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.07);\n"])));
var ExpansiveCardHeader = exports.ExpansiveCardHeader = _styledComponents["default"].div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  padding: 0;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n"])));
var TitleHeader = exports.TitleHeader = _styledComponents["default"].div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  padding: 0;\n  justify-content: space-between;\n  align-items: center;\n  overflow: hidden;\n"])));
var PreviewContent = exports.PreviewContent = _styledComponents["default"].div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2["default"])(["\n  width: 100%;\n  max-height: 220px;\n  border-radius: 10px 10px 0px 0px;\n"])));
var ImageContainer = exports.ImageContainer = _styledComponents["default"].div(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  width: 100%;\n  height: 220px;\n\n  .imageContainer {\n    height: 100%;\n    width: 100%;\n    border-radius: 10px 10px 0px 0px;\n    object-fit: cover;\n    object-position: center;\n  }\n\n  img {\n    position: absolute;\n  }\n"])));