"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RETRY_REPORT_SUCCESS = exports.RETRY_REPORT_REQUEST = exports.RETRY_REPORT_FAIL = exports.LIST_REPORTS_SUCCESS = exports.LIST_REPORTS_REQUEST = exports.LIST_REPORTS_FAIL = exports.DELETE_REPORT_SUCCESS = exports.DELETE_REPORT_REQUEST = exports.DELETE_REPORT_FAIL = exports.CREATE_REPORT_SUCCESS = exports.CREATE_REPORT_REQUEST = exports.CREATE_REPORT_FAIL = void 0;
var CREATE_REPORT_REQUEST = exports.CREATE_REPORT_REQUEST = '@Report/CREATE_REPORT_REQUEST';
var CREATE_REPORT_SUCCESS = exports.CREATE_REPORT_SUCCESS = '@Report/CREATE_REPORT_SUCCESS';
var CREATE_REPORT_FAIL = exports.CREATE_REPORT_FAIL = '@Report/CREATE_REPORT_FAIL';
var DELETE_REPORT_REQUEST = exports.DELETE_REPORT_REQUEST = '@Report/DELETE_REPORT_REQUEST';
var DELETE_REPORT_SUCCESS = exports.DELETE_REPORT_SUCCESS = '@Report/DELETE_REPORT_SUCCESS';
var DELETE_REPORT_FAIL = exports.DELETE_REPORT_FAIL = '@Report/DELETE_REPORT_FAIL';
var LIST_REPORTS_REQUEST = exports.LIST_REPORTS_REQUEST = '@Report/LIST_REPORTS_REQUEST';
var LIST_REPORTS_SUCCESS = exports.LIST_REPORTS_SUCCESS = '@Report/LIST_REPORTS_SUCCESS';
var LIST_REPORTS_FAIL = exports.LIST_REPORTS_FAIL = '@Report/LIST_REPORTS_FAIL';
var RETRY_REPORT_REQUEST = exports.RETRY_REPORT_REQUEST = '@Report/RETRY_REPORT_REQUEST';
var RETRY_REPORT_SUCCESS = exports.RETRY_REPORT_SUCCESS = '@Report/RETRY_REPORT_SUCCESS';
var RETRY_REPORT_FAIL = exports.RETRY_REPORT_FAIL = '@Report/RETRY_REPORT_FAIL';