"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATED_DATA = exports.SHOULD_UPDATE_DATA = exports.SEND_AMENITY_CARD_LINK_SUCCESS = exports.SEND_AMENITY_CARD_LINK_REQUEST = exports.SEND_AMENITY_CARD_LINK_FAIL = exports.SEARCH_USER_BY_PHONE_SUCCESS = exports.SEARCH_USER_BY_PHONE_REQUEST = exports.SEARCH_USER_BY_PHONE_FAIL = exports.REMOVE_RESIDENT_FROM_UNIT_SUCCESS = exports.REMOVE_RESIDENT_FROM_UNIT_REQUEST = exports.REMOVE_RESIDENT_FROM_UNIT_FAIL = exports.PATCH_RESIDENT_FROM_UNIT_SUCCESS = exports.PATCH_RESIDENT_FROM_UNIT_REQUEST = exports.PATCH_RESIDENT_FROM_UNIT_FAIL = exports.LIST_UNIT_LINES_SUCCESS = exports.LIST_UNIT_LINES_REQUEST = exports.LIST_UNIT_LINES_FAIL = exports.LIST_UNIT_FLOORS_SUCCESS = exports.LIST_UNIT_FLOORS_REQUEST = exports.LIST_UNIT_FLOORS_FAIL = exports.LIST_UNITS_SUCCESS = exports.LIST_UNITS_REQUEST = exports.LIST_UNITS_FAIL = exports.LIST_AMENITY_CARDS_SUCCESS = exports.LIST_AMENITY_CARDS_REQUEST = exports.LIST_AMENITY_CARDS_FAIL = exports.GET_UNIT_SUCCESS = exports.GET_UNIT_REQUEST = exports.GET_UNIT_FAIL = exports.EXPIRE_AMENITY_CARD_SUCCESS = exports.EXPIRE_AMENITY_CARD_REQUEST = exports.EXPIRE_AMENITY_CARD_FAIL = exports.EDIT_RESIDENT_FROM_UNIT_SUCCESS = exports.EDIT_RESIDENT_FROM_UNIT_REQUEST = exports.EDIT_RESIDENT_FROM_UNIT_FAIL = exports.EDIT_AMENITY_CARDS_SUCCESS = exports.EDIT_AMENITY_CARDS_REQUEST = exports.EDIT_AMENITY_CARDS_FAIL = exports.DELETE_UNIT_SUCCESS = exports.DELETE_UNIT_REQUEST = exports.DELETE_UNIT_FAIL = exports.DELETE_AMENITY_CARD_SUCCESS = exports.DELETE_AMENITY_CARD_REQUEST = exports.DELETE_AMENITY_CARD_FAIL = exports.CREATE_UNIT_SUCCESS = exports.CREATE_UNIT_REQUEST = exports.CREATE_UNIT_FAIL = exports.CREATE_AMENITY_CARDS_SUCCESS = exports.CREATE_AMENITY_CARDS_REQUEST = exports.CREATE_AMENITY_CARDS_FAIL = exports.ADD_RESIDENT_TO_UNIT_SUCCESS = exports.ADD_RESIDENT_TO_UNIT_REQUEST = exports.ADD_RESIDENT_TO_UNIT_FAIL = void 0;
var CREATE_UNIT_REQUEST = exports.CREATE_UNIT_REQUEST = '@Unit/CREATE_UNIT_REQUEST';
var CREATE_UNIT_SUCCESS = exports.CREATE_UNIT_SUCCESS = '@Unit/CREATE_UNIT_SUCCESS';
var CREATE_UNIT_FAIL = exports.CREATE_UNIT_FAIL = '@Unit/CREATE_UNIT_FAIL';
var LIST_UNITS_REQUEST = exports.LIST_UNITS_REQUEST = '@Unit/LIST_UNITS_REQUEST';
var LIST_UNITS_SUCCESS = exports.LIST_UNITS_SUCCESS = '@Unit/LIST_UNITS_SUCCESS';
var LIST_UNITS_FAIL = exports.LIST_UNITS_FAIL = '@Unit/LIST_UNITS_FAIL';
var GET_UNIT_REQUEST = exports.GET_UNIT_REQUEST = '@Unit/GET_UNIT_REQUEST';
var GET_UNIT_SUCCESS = exports.GET_UNIT_SUCCESS = '@Unit/GET_UNIT_SUCCESS';
var GET_UNIT_FAIL = exports.GET_UNIT_FAIL = '@Unit/GET_UNIT_FAIL';
var DELETE_UNIT_REQUEST = exports.DELETE_UNIT_REQUEST = '@Unit/DELETE_UNIT_REQUEST';
var DELETE_UNIT_SUCCESS = exports.DELETE_UNIT_SUCCESS = '@Unit/DELETE_UNIT_SUCCESS';
var DELETE_UNIT_FAIL = exports.DELETE_UNIT_FAIL = '@Unit/DELETE_UNIT_FAIL';
var REMOVE_RESIDENT_FROM_UNIT_REQUEST = exports.REMOVE_RESIDENT_FROM_UNIT_REQUEST = '@Unit/REMOVE_RESIDENT_FROM_UNIT_REQUEST';
var REMOVE_RESIDENT_FROM_UNIT_SUCCESS = exports.REMOVE_RESIDENT_FROM_UNIT_SUCCESS = '@Unit/REMOVE_RESIDENT_FROM_UNIT_SUCCESS';
var REMOVE_RESIDENT_FROM_UNIT_FAIL = exports.REMOVE_RESIDENT_FROM_UNIT_FAIL = '@Unit/REMOVE_RESIDENT_FROM_UNIT_FAIL';
var EDIT_RESIDENT_FROM_UNIT_REQUEST = exports.EDIT_RESIDENT_FROM_UNIT_REQUEST = '@Unit/EDIT_RESIDENT_FROM_UNIT_REQUEST';
var EDIT_RESIDENT_FROM_UNIT_SUCCESS = exports.EDIT_RESIDENT_FROM_UNIT_SUCCESS = '@Unit/EDIT_RESIDENT_FROM_UNIT_SUCCESS';
var EDIT_RESIDENT_FROM_UNIT_FAIL = exports.EDIT_RESIDENT_FROM_UNIT_FAIL = '@Unit/EDIT_RESIDENT_FROM_UNIT_FAIL';
var PATCH_RESIDENT_FROM_UNIT_REQUEST = exports.PATCH_RESIDENT_FROM_UNIT_REQUEST = '@Unit/PATCH_RESIDENT_FROM_UNIT_REQUEST';
var PATCH_RESIDENT_FROM_UNIT_SUCCESS = exports.PATCH_RESIDENT_FROM_UNIT_SUCCESS = '@Unit/PATCH_RESIDENT_FROM_UNIT_SUCCESS';
var PATCH_RESIDENT_FROM_UNIT_FAIL = exports.PATCH_RESIDENT_FROM_UNIT_FAIL = '@Unit/PATCH_RESIDENT_FROM_UNIT_FAIL';
var ADD_RESIDENT_TO_UNIT_REQUEST = exports.ADD_RESIDENT_TO_UNIT_REQUEST = '@Unit/ADD_RESIDENT_TO_UNIT_REQUEST';
var ADD_RESIDENT_TO_UNIT_SUCCESS = exports.ADD_RESIDENT_TO_UNIT_SUCCESS = '@Unit/ADD_RESIDENT_TO_UNIT_SUCCESS';
var ADD_RESIDENT_TO_UNIT_FAIL = exports.ADD_RESIDENT_TO_UNIT_FAIL = '@Unit/ADD_RESIDENT_TO_UNIT_FAIL';
var LIST_AMENITY_CARDS_REQUEST = exports.LIST_AMENITY_CARDS_REQUEST = '@Unit/LIST_AMENITY_CARDS_REQUEST';
var LIST_AMENITY_CARDS_SUCCESS = exports.LIST_AMENITY_CARDS_SUCCESS = '@Unit/LIST_AMENITY_CARDS_SUCCESS';
var LIST_AMENITY_CARDS_FAIL = exports.LIST_AMENITY_CARDS_FAIL = '@Unit/LIST_AMENITY_CARDS_FAIL';
var CREATE_AMENITY_CARDS_REQUEST = exports.CREATE_AMENITY_CARDS_REQUEST = '@Unit/CREATE_AMENITY_CARDS_REQUEST';
var CREATE_AMENITY_CARDS_SUCCESS = exports.CREATE_AMENITY_CARDS_SUCCESS = '@Unit/CREATE_AMENITY_CARDS_SUCCESS';
var CREATE_AMENITY_CARDS_FAIL = exports.CREATE_AMENITY_CARDS_FAIL = '@Unit/CREATE_AMENITY_CARDS_FAIL';
var EDIT_AMENITY_CARDS_REQUEST = exports.EDIT_AMENITY_CARDS_REQUEST = '@Unit/EDIT_AMENITY_CARDS_REQUEST';
var EDIT_AMENITY_CARDS_SUCCESS = exports.EDIT_AMENITY_CARDS_SUCCESS = '@Unit/EDIT_AMENITY_CARDS_SUCCESS';
var EDIT_AMENITY_CARDS_FAIL = exports.EDIT_AMENITY_CARDS_FAIL = '@Unit/EDIT_AMENITY_CARDS_FAIL';
var DELETE_AMENITY_CARD_REQUEST = exports.DELETE_AMENITY_CARD_REQUEST = '@Unit/DELETE_AMENITY_CARD_REQUEST';
var DELETE_AMENITY_CARD_SUCCESS = exports.DELETE_AMENITY_CARD_SUCCESS = '@Unit/DELETE_AMENITY_CARD_SUCCESS';
var DELETE_AMENITY_CARD_FAIL = exports.DELETE_AMENITY_CARD_FAIL = '@Unit/DELETE_AMENITY_CARD_FAIL';
var EXPIRE_AMENITY_CARD_REQUEST = exports.EXPIRE_AMENITY_CARD_REQUEST = '@Unit/EXPIRE_AMENITY_CARD_REQUEST';
var EXPIRE_AMENITY_CARD_SUCCESS = exports.EXPIRE_AMENITY_CARD_SUCCESS = '@Unit/EXPIRE_AMENITY_CARD_SUCCESS';
var EXPIRE_AMENITY_CARD_FAIL = exports.EXPIRE_AMENITY_CARD_FAIL = '@Unit/EXPIRE_AMENITY_CARD_FAIL';
var SEND_AMENITY_CARD_LINK_REQUEST = exports.SEND_AMENITY_CARD_LINK_REQUEST = '@Unit/SEND_AMENITY_CARD_LINK_REQUEST';
var SEND_AMENITY_CARD_LINK_SUCCESS = exports.SEND_AMENITY_CARD_LINK_SUCCESS = '@Unit/SEND_AMENITY_CARD_LINK_SUCCESS';
var SEND_AMENITY_CARD_LINK_FAIL = exports.SEND_AMENITY_CARD_LINK_FAIL = '@Unit/SEND_AMENITY_CARD_LINK_FAIL';
var SEARCH_USER_BY_PHONE_REQUEST = exports.SEARCH_USER_BY_PHONE_REQUEST = '@Unit/SEARCH_USER_BY_PHONE_REQUEST';
var SEARCH_USER_BY_PHONE_SUCCESS = exports.SEARCH_USER_BY_PHONE_SUCCESS = '@Unit/SEARCH_USER_BY_PHONE_SUCCESS';
var SEARCH_USER_BY_PHONE_FAIL = exports.SEARCH_USER_BY_PHONE_FAIL = '@Unit/SEARCH_USER_BY_PHONE_FAIL';
var SHOULD_UPDATE_DATA = exports.SHOULD_UPDATE_DATA = '@Unit/UPDATE_DATA';
var UPDATED_DATA = exports.UPDATED_DATA = '@Unit/UPDATED_DATA';
var LIST_UNIT_FLOORS_REQUEST = exports.LIST_UNIT_FLOORS_REQUEST = '@Unit/LIST_UNIT_FLOORS_REQUEST';
var LIST_UNIT_FLOORS_SUCCESS = exports.LIST_UNIT_FLOORS_SUCCESS = '@Unit/LIST_UNIT_FLOORS_SUCCESS';
var LIST_UNIT_FLOORS_FAIL = exports.LIST_UNIT_FLOORS_FAIL = '@Unit/LIST_UNIT_FLOORS_FAIL';
var LIST_UNIT_LINES_REQUEST = exports.LIST_UNIT_LINES_REQUEST = '@Unit/LIST_UNIT_LINES_REQUEST';
var LIST_UNIT_LINES_SUCCESS = exports.LIST_UNIT_LINES_SUCCESS = '@Unit/LIST_UNIT_LINES_SUCCESS';
var LIST_UNIT_LINES_FAIL = exports.LIST_UNIT_LINES_FAIL = '@Unit/LIST_UNIT_LINES_FAIL';