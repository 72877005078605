"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.highartsColorScheme = exports["default"] = void 0;
var _highcharts = _interopRequireDefault(require("highcharts"));
var _exporting = _interopRequireDefault(require("highcharts/modules/exporting"));
var _exportData = _interopRequireDefault(require("highcharts/modules/export-data"));
var highartsColorScheme = exports.highartsColorScheme = ['#8BC34A', '#00B0FF', '#FFC107', '#673AB7', '#009688', '#FF5252', '#2D7731', '#2196F3', '#E91E63', '#1976D2', '#D32F2F', '#607D8B', '#C1D21D', '#FF9800'];
var theme = {
  colors: highartsColorScheme,
  title: {
    style: {
      color: '#333333',
      font: 'bold 16px "Nunito", sans-serif'
    }
  },
  subtitle: {
    style: {
      color: '#666666',
      font: 'bold 12px "Nunito", sans-serif'
    }
  },
  legend: {
    itemStyle: {
      font: 'Nunito, sans-serif',
      color: 'black'
    },
    itemHoverStyle: {
      color: 'gray'
    }
  }
};
var defaultGlobal = {
  lang: {
    noData: 'No data to be displayed'
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030'
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: true,
    fallbackToExportServer: false,
    chartOptions: {
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true
          }
        }
      }
    },
    buttons: {
      contextButton: {
        menuItems: [{
          textKey: 'downloadPDF',
          text: 'Download as PDF',
          onclick: function onclick() {
            this.exportChart({
              type: 'application/pdf',
              allowHTML: true,
              filename: "".concat(this.title.textStr || 'Exported chart')
            });
          }
        }]
      }
    }
  },
  title: {
    text: ''
  }
};

// For testing purposes. setOptions has an error with SSR
if (_highcharts["default"].setOptions) {
  (0, _exportData["default"])(_highcharts["default"]);
  (0, _exporting["default"])(_highcharts["default"]);
  _highcharts["default"].setOptions(defaultGlobal);
  _highcharts["default"].setOptions(theme);
}
var _default = exports["default"] = _highcharts["default"];