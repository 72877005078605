"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  loadingListStaffNotes: false,
  loadingCreateStaffNote: false,
  loadingDeleteStaffNote: false,
  loadingEditStaffNote: false,
  loadingExpireStaffNote: false,
  loadingGetStaffNote: false,
  listStaffNotes: [],
  listStaffNotesPages: 0,
  listStaffNotesCount: 0,
  staffNote: {}
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.CREATE_STAFF_NOTE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateStaffNote: true
      });
    case _variables.CREATE_STAFF_NOTE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateStaffNote: false
      });
    case _variables.CREATE_STAFF_NOTE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateStaffNote: false,
        staffNote: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {}
      });
    case _variables.GET_STAFF_NOTE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetStaffNote: true
      });
    case _variables.GET_STAFF_NOTE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetStaffNote: false
      });
    case _variables.GET_STAFF_NOTE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetStaffNote: false,
        staffNote: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.DELETE_STAFF_NOTE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteStaffNote: true
      });
    case _variables.DELETE_STAFF_NOTE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteStaffNote: false
      });
    case _variables.DELETE_STAFF_NOTE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteStaffNote: false
      });
    case _variables.EDIT_STAFF_NOTE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditStaffNote: true
      });
    case _variables.EDIT_STAFF_NOTE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditStaffNote: false
      });
    case _variables.EDIT_STAFF_NOTE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditStaffNote: false,
        staffNote: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {}
      });
    case _variables.EXPIRE_STAFF_NOTE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExpireStaffNote: true
      });
    case _variables.EXPIRE_STAFF_NOTE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExpireStaffNote: false
      });
    case _variables.EXPIRE_STAFF_NOTE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExpireStaffNote: false,
        staffNote: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.LIST_STAFF_NOTES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListStaffNotes: true
      });
    case _variables.LIST_STAFF_NOTES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListStaffNotes: false
      });
    case _variables.LIST_STAFF_NOTES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListStaffNotes: false,
        listStaffNotes: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : [],
        listStaffNotesCount: action.payload.headers['total-count'],
        listStaffNotesPages: action.payload.headers['total-pages']
      });
    default:
      return state;
  }
};