import styled from 'styled-components';
import { DefaultTheme, S3PrivateImage, Text } from '@qwkin/components';

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  .noButton {
    margin-left: 12px;
  }
`;

export const NavbarContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 110px;
`;

export const PlainNavbar = styled.div`
  position: sticky;
  z-index: 99;
  top: 0;
  padding: 8px 16px;
  width: 100%;
  max-width: calc(100% - 32px);
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: hidden;
`;

export const AppContent = styled.div`
  padding: 8px;
  position: relative;
  width: 100%;
  margin-left: ${p => (p.isFullScreen ? '0px' : '110px')};

  @media screen and (max-width: 1024px) {
    margin-left: 70px;
  }
`;

export const CondoInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  pointer-events: none;
`;

export const CondoImage = styled(S3PrivateImage)`
  max-height: 40px;
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${DefaultTheme.color.disabled};
  padding-left: 8px;
  cursor: pointer;

  .userName {
    margin-left: 12px;
  }
`;

export const DividerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const NavbarText = styled(Text)`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const DropdownMenu = styled.div`
  width: 180px;
  padding: 8px;
  background-color: #f9f9f9;
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  padding: 8px;
  gap: 4px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${DefaultTheme.color.primary.clear};
  }
`;
