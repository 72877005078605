"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_LOL_PERSONS_SUCCESS = exports.LIST_LOL_PERSONS_REQUEST = exports.LIST_LOL_PERSONS_FAIL = exports.GET_LOL_PERSONS_BY_EVENT_SUCCESS = exports.GET_LOL_PERSONS_BY_EVENT_REQUEST = exports.GET_LOL_PERSONS_BY_EVENT_FAIL = exports.EDIT_LOL_PERSON_SUCCESS = exports.EDIT_LOL_PERSON_REQUEST = exports.EDIT_LOL_PERSON_PHOTO_SUCCESS = exports.EDIT_LOL_PERSON_PHOTO_REQUEST = exports.EDIT_LOL_PERSON_PHOTO_FAIL = exports.EDIT_LOL_PERSON_FAIL = exports.DELETE_LOL_PERSON_SUCCESS = exports.DELETE_LOL_PERSON_REQUEST = exports.DELETE_LOL_PERSON_FAIL = exports.CREATE_LOL_PERSON_SUCCESS = exports.CREATE_LOL_PERSON_REQUEST = exports.CREATE_LOL_PERSON_FAIL = exports.CREATE_LOL_PERSON_BY_EVENT_SUCCESS = exports.CREATE_LOL_PERSON_BY_EVENT_REQUEST = exports.CREATE_LOL_PERSON_BY_EVENT_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var LIST_LOL_PERSONS_REQUEST = exports.LIST_LOL_PERSONS_REQUEST = '@LOL/LIST_LOL_PERSONS_REQUEST';
var LIST_LOL_PERSONS_SUCCESS = exports.LIST_LOL_PERSONS_SUCCESS = '@LOL/LIST_LOL_PERSONS_SUCCESS';
var LIST_LOL_PERSONS_FAIL = exports.LIST_LOL_PERSONS_FAIL = '@LOL/LIST_LOL_PERSONS_FAIL';
var GET_LOL_PERSONS_BY_EVENT_REQUEST = exports.GET_LOL_PERSONS_BY_EVENT_REQUEST = '@LOL/GET_LOL_PERSONS_BY_EVENT_REQUEST';
var GET_LOL_PERSONS_BY_EVENT_SUCCESS = exports.GET_LOL_PERSONS_BY_EVENT_SUCCESS = '@LOL/GET_LOL_PERSONS_BY_EVENT_SUCCESS';
var GET_LOL_PERSONS_BY_EVENT_FAIL = exports.GET_LOL_PERSONS_BY_EVENT_FAIL = '@LOL/GET_LOL_PERSONS_BY_EVENT_FAIL';
var CREATE_LOL_PERSON_REQUEST = exports.CREATE_LOL_PERSON_REQUEST = '@LOL/CREATE_LOL_PERSON_REQUEST';
var CREATE_LOL_PERSON_SUCCESS = exports.CREATE_LOL_PERSON_SUCCESS = '@LOL/CREATE_LOL_PERSON_SUCCESS';
var CREATE_LOL_PERSON_FAIL = exports.CREATE_LOL_PERSON_FAIL = '@LOL/CREATE_LOL_PERSON_FAIL';
var CREATE_LOL_PERSON_BY_EVENT_REQUEST = exports.CREATE_LOL_PERSON_BY_EVENT_REQUEST = '@LOL/CREATE_LOL_PERSON_BY_EVENT_REQUEST';
var CREATE_LOL_PERSON_BY_EVENT_SUCCESS = exports.CREATE_LOL_PERSON_BY_EVENT_SUCCESS = '@LOL/CREATE_LOL_PERSON_BY_EVENT_SUCCESS';
var CREATE_LOL_PERSON_BY_EVENT_FAIL = exports.CREATE_LOL_PERSON_BY_EVENT_FAIL = '@LOL/CREATE_LOL_PERSON_BY_EVENT_FAIL';
var DELETE_LOL_PERSON_REQUEST = exports.DELETE_LOL_PERSON_REQUEST = '@LOL/DELETE_LOL_PERSON_REQUEST';
var DELETE_LOL_PERSON_SUCCESS = exports.DELETE_LOL_PERSON_SUCCESS = '@LOL/DELETE_LOL_PERSON_SUCCESS';
var DELETE_LOL_PERSON_FAIL = exports.DELETE_LOL_PERSON_FAIL = '@LOL/DELETE_LOL_PERSON_FAIL';
var EDIT_LOL_PERSON_REQUEST = exports.EDIT_LOL_PERSON_REQUEST = '@LOL/EDIT_LOL_PERSON_REQUEST';
var EDIT_LOL_PERSON_SUCCESS = exports.EDIT_LOL_PERSON_SUCCESS = '@LOL/EDIT_LOL_PERSON_SUCCESS';
var EDIT_LOL_PERSON_FAIL = exports.EDIT_LOL_PERSON_FAIL = '@LOL/EDIT_LOL_PERSON_FAIL';
var EDIT_LOL_PERSON_PHOTO_REQUEST = exports.EDIT_LOL_PERSON_PHOTO_REQUEST = '@LOL/EDIT_LOL_PERSON_PHOTO_REQUEST';
var EDIT_LOL_PERSON_PHOTO_SUCCESS = exports.EDIT_LOL_PERSON_PHOTO_SUCCESS = '@LOL/EDIT_LOL_PERSON_PHOTO_SUCCESS';
var EDIT_LOL_PERSON_PHOTO_FAIL = exports.EDIT_LOL_PERSON_PHOTO_FAIL = '@LOL/EDIT_LOL_PERSON_PHOTO_FAIL';