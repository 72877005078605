"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "OpeningHoursSelector", {
  enumerable: true,
  get: function get() {
    return _OpeningHoursSelector["default"];
  }
});
var _OpeningHoursSelector = _interopRequireDefault(require("./OpeningHoursSelector"));