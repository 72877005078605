"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
var _variables2 = require("../../Pets/variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  errorCreateContractorType: false,
  loadingCreateContractorType: false,
  errorEditContractorType: false,
  loadingEditContractorType: false,
  errorDeleteContractorType: false,
  loadingDeleteContractorType: false,
  errorEnableContractorType: false,
  loadingEnableContractorType: false,
  errorDisableContractorType: false,
  loadingDisableContractorType: false,
  listError: false,
  loadingList: false,
  createError: false,
  loadingCreate: false,
  loadingDelete: false,
  getError: false,
  loadingGet: false,
  editError: false,
  loadingEdit: false,
  list: [],
  listContractorsTypes: [],
  listContractorsTypesCount: 0,
  listContractorsTypesPages: 0,
  listContractorsCount: 0,
  listContractorsPages: 0,
  data: {}
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data9, _action$payload9, _action$payload$data10, _action$payload10, _action$payload$data11, _action$payload11, _action$payload$data12, _action$payload12, _action$payload$data13, _action$payload13, _action$payload$data14, _action$payload14, _action$payload$data15, _action$payload15, _action$payload$data16, _action$payload16, _action$payload$data17, _action$payload17, _action$payload$data18, _action$payload18, _action$payload$data19, _action$payload19, _action$payload$data20, _action$payload20;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.LIST_CONTRACTOR_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListContractorTypes: true,
        errorListContractorTypes: false
      });
    case _variables.LIST_CONTRACTOR_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListContractorTypes: false,
        errorListContractorTypes: false,
        listContractorsTypes: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : [],
        listContractorsTypesCount: action.payload.headers['total-count'],
        listContractorsTypesPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_CONTRACTOR_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListContractorTypes: false,
        errorListContractorTypes: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.DISABLE_CONTRACTOR_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDisableContractorType: true,
        errorDisableContractorType: null
      });
    case _variables.DISABLE_CONTRACTOR_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDisableContractorType: false,
        errorDisableContractorType: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {}
      });
    case _variables.DISABLE_CONTRACTOR_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDisableContractorType: false,
        errorDisableContractorType: null,
        data: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.ENABLE_CONTRACTOR_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEnableContractorType: true,
        errorEnableContractorType: null
      });
    case _variables.ENABLE_CONTRACTOR_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEnableContractorType: false,
        errorEnableContractorType: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : {}
      });
    case _variables.ENABLE_CONTRACTOR_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEnableContractorType: false,
        errorEnableContractorType: null,
        data: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {}
      });
    case _variables.DELETE_CONTRACTOR_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteContractorType: true,
        errorDeleteContractorType: null
      });
    case _variables.DELETE_CONTRACTOR_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteContractorType: false,
        errorDeleteContractorType: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : {}
      });
    case _variables.DELETE_CONTRACTOR_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteContractorType: false,
        errorDeleteContractorType: null,
        data: (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}
      });
    case _variables.EDIT_CONTRACTOR_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditContractorType: true,
        errorEditContractorType: null
      });
    case _variables.EDIT_CONTRACTOR_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditContractorType: false,
        errorEditContractorType: (_action$payload$data9 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : {}
      });
    case _variables.EDIT_CONTRACTOR_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditContractorType: false,
        errorEditContractorType: null,
        data: (_action$payload$data10 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}
      });
    case _variables.CREATE_CONTRACTOR_TYPE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateContractorType: true,
        errorCreateContractorType: null
      });
    case _variables.CREATE_CONTRACTOR_TYPE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateContractorType: false,
        errorCreateContractorType: (_action$payload$data11 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : {}
      });
    case _variables.CREATE_CONTRACTOR_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateContractorType: false,
        errorCreateContractorType: null,
        data: (_action$payload$data12 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {}
      });
    case _variables.CONTRACTORS_DELETE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDelete: true
      });
    case _variables.CONTRACTORS_DELETE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDelete: false
      });
    case _variables.CONTRACTORS_DELETE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDelete: false
      });
    case _variables.CONTRACTORS_CREATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreate: true,
        createError: null
      });
    case _variables.CONTRACTORS_CREATE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreate: false,
        createError: (_action$payload$data13 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data13 !== void 0 ? _action$payload$data13 : {}
      });
    case _variables.CONTRACTORS_CREATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreate: false,
        createError: null,
        data: (_action$payload$data14 = action === null || action === void 0 || (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data) !== null && _action$payload$data14 !== void 0 ? _action$payload$data14 : {}
      });
    case _variables.CONTRACTORS_EDIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEdit: true,
        editError: null
      });
    case _variables.CONTRACTORS_EDIT_FILES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEdit: true,
        editError: null
      });
    case _variables.CONTRACTORS_EDIT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEdit: false,
        editError: (_action$payload$data15 = action === null || action === void 0 || (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.data) !== null && _action$payload$data15 !== void 0 ? _action$payload$data15 : {}
      });
    case _variables.CONTRACTORS_EDIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEdit: false,
        editError: null,
        data: (_action$payload$data16 = action === null || action === void 0 || (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.data) !== null && _action$payload$data16 !== void 0 ? _action$payload$data16 : {}
      });
    case _variables.CONTRACTORS_GET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGet: true,
        getError: null,
        editError: null
      });
    case _variables.CONTRACTORS_GET_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGet: false,
        getError: (_action$payload$data17 = action === null || action === void 0 || (_action$payload17 = action.payload) === null || _action$payload17 === void 0 ? void 0 : _action$payload17.data) !== null && _action$payload$data17 !== void 0 ? _action$payload$data17 : {}
      });
    case _variables.CONTRACTORS_GET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGet: false,
        getError: null,
        data: (_action$payload$data18 = action === null || action === void 0 || (_action$payload18 = action.payload) === null || _action$payload18 === void 0 ? void 0 : _action$payload18.data) !== null && _action$payload$data18 !== void 0 ? _action$payload$data18 : {}
      });
    case _variables.CONTRACTORS_LIST_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: true,
        listError: null
      });
    case _variables.CONTRACTORS_LIST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: false,
        listError: (_action$payload$data19 = action === null || action === void 0 || (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : _action$payload19.data) !== null && _action$payload$data19 !== void 0 ? _action$payload$data19 : {}
      });
    case _variables.CONTRACTORS_LIST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingList: false,
        listError: null,
        list: (_action$payload$data20 = action === null || action === void 0 || (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.data) !== null && _action$payload$data20 !== void 0 ? _action$payload$data20 : [],
        listContractorsCount: action.payload.headers['total-count'],
        listContractorsPages: action.payload.headers['total-pages']
      });
    default:
      return state;
  }
};