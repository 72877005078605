"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ImageCropper", {
  enumerable: true,
  get: function get() {
    return _ImageCropper["default"];
  }
});
var _ImageCropper = _interopRequireDefault(require("./ImageCropper"));