"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestGetUserByPhoneOrEmail = exports.requestGetUserByPhone = exports.requestGetEmployees = exports.requestEditAdmin = exports.requestCreateUser = exports.requestCreateAdmin = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestCreateUser = exports.requestCreateUser = function requestCreateUser(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_USER_REQUEST, payload, meta);
};
var requestCreateAdmin = exports.requestCreateAdmin = function requestCreateAdmin(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_ADMIN_REQUEST, payload, meta);
};
var requestEditAdmin = exports.requestEditAdmin = function requestEditAdmin(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_ADMIN_REQUEST, payload, meta);
};
var requestGetUserByPhone = exports.requestGetUserByPhone = function requestGetUserByPhone(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_USER_BY_PHONE_REQUEST, payload, meta);
};
var requestGetUserByPhoneOrEmail = exports.requestGetUserByPhoneOrEmail = function requestGetUserByPhoneOrEmail(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_USER_BY_PHONE_OR_EMAIL_REQUEST, payload, meta);
};
var requestGetEmployees = exports.requestGetEmployees = function requestGetEmployees(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_EMPLOYEES_REQUEST, payload, meta);
};