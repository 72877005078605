"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlainText = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
var getFontSize = function getFontSize(_ref) {
  var type = _ref.type;
  switch (type) {
    case 'large-title':
      return '2.441em';
    case 'title':
      return '1.653em';
    case 'subtitle':
      return '1.25em';
    case 'semititle':
      return '1.1em';
    case 'regular':
      return '1em';
    case 'small':
      return '0.8em';
    default:
      return '1em';
  }
};
var getFontWeight = function getFontWeight(_ref2) {
  var weight = _ref2.weight;
  switch (weight) {
    case 'bold':
      return 700;
    case 'regular':
      return 500;
    case 'light':
      return 300;
    default:
      return 500;
  }
};
var PlainText = exports.PlainText = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"])), function (_ref3) {
  var color = _ref3.color;
  return color || '#333333';
}, getFontSize, getFontWeight);