"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestRetryReport = exports.requestListReports = exports.requestDeleteReport = exports.requestCreateReport = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestCreateReport = exports.requestCreateReport = function requestCreateReport(payload, meta) {
  return (0, _utils.buildAction)(_variables.CREATE_REPORT_REQUEST, payload, meta);
};
var requestDeleteReport = exports.requestDeleteReport = function requestDeleteReport(payload, meta) {
  return (0, _utils.buildAction)(_variables.DELETE_REPORT_REQUEST, payload, meta);
};
var requestListReports = exports.requestListReports = function requestListReports(payload, meta) {
  return (0, _utils.buildAction)(_variables.LIST_REPORTS_REQUEST, payload, meta);
};
var requestRetryReport = exports.requestRetryReport = function requestRetryReport(payload, meta) {
  return (0, _utils.buildAction)(_variables.RETRY_REPORT_REQUEST, payload, meta);
};