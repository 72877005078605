"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoPhotoSelectedContainer = exports.ImageUploaderVerticalDivider = exports.ImageUploaderDivider = exports.ImageUploaderContent = exports.ImageUploaderContainer = exports.ImageUploaderButtonsRow = exports.ChangePictureModalRow = exports.ChangePictureModalCropColumn = exports.ChangePictureModalColumn = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
var ImageUploaderContainer = exports.ImageUploaderContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 16px;\n  max-width: 100%;\n  border-radius: 10px;\n  border: 1px solid rgba(0, 0, 0, 0.07);\n"])));
var ImageUploaderContent = exports.ImageUploaderContent = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  width: 100%;\n"])));
var ImageUploaderDivider = exports.ImageUploaderDivider = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  border: 0.5px solid rgba(0, 0, 0, 0.07);\n  width: 100%;\n  height: 0px;\n  margin: 8px 0;\n"])));
var ImageUploaderVerticalDivider = exports.ImageUploaderVerticalDivider = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  border: 0.5px solid rgba(0, 0, 0, 0.07);\n  width: 0px;\n  height: 100%;\n  margin: 0 16px;\n"])));
var ImageUploaderButtonsRow = exports.ImageUploaderButtonsRow = _styledComponents["default"].div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n"])));
var NoPhotoSelectedContainer = exports.NoPhotoSelectedContainer = _styledComponents["default"].div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n  background: ", ";\n  text-align: center;\n  max-width: 100%;\n  border-radius: 10px;\n  padding: 16px;\n"])), function (p) {
  return p.imageOversize ? p.theme.color.danger.translucid : '#eeeeee';
});
var ChangePictureModalRow = exports.ChangePictureModalRow = _styledComponents["default"].div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  margin: 8px 0;\n"])));
var ChangePictureModalColumn = exports.ChangePictureModalColumn = _styledComponents["default"].div(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  margin: 0 8px;\n"])));
var ChangePictureModalCropColumn = exports.ChangePictureModalCropColumn = _styledComponents["default"].div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  margin: 0 8px;\n  border-radius: 10px;\n  padding: 8px 16px;\n  border: 1px solid #eeeeee;\n"])));