"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  errorCreateReport: false,
  loadingCreateReport: false,
  errorDeleteReport: false,
  loadingDeleteReport: false,
  report: {},
  errorListReports: false,
  loadingListReports: false,
  errorRetryReport: false,
  loadingRetryReport: false,
  listReports: [],
  listReportsCount: 0,
  listReportsPages: 0
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.CREATE_REPORT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorCreateReport: null,
        loadingCreateReport: true
      });
    case _variables.CREATE_REPORT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorCreateReport: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : {},
        loadingCreateReport: false
      });
    case _variables.CREATE_REPORT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorCreateReport: null,
        loadingCreateReport: false,
        report: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.DELETE_REPORT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorDeleteReport: null,
        loadingDeleteReport: true
      });
    case _variables.DELETE_REPORT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorDeleteReport: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : {},
        loadingDeleteReport: false
      });
    case _variables.DELETE_REPORT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorDeleteReport: null,
        loadingDeleteReport: false
      });
    case _variables.LIST_REPORTS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorListReports: null,
        loadingListReports: true
      });
    case _variables.LIST_REPORTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorListReports: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {},
        loadingListReports: false
      });
    case _variables.LIST_REPORTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorListReports: null,
        loadingListReports: false,
        listReports: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : [],
        listReportsCount: action.payload.headers['total-count'],
        listReportsPages: action.payload.headers['total-pages']
      });
    case _variables.RETRY_REPORT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorRetryReport: null,
        loadingRetryReport: true
      });
    case _variables.RETRY_REPORT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorRetryReport: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {},
        loadingRetryReport: false
      });
    case _variables.RETRY_REPORT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorRetryReport: null,
        loadingRetryReport: false,
        report: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : {}
      });
    default:
      return state;
  }
};